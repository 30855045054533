import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
export type Maybe<T> = T | null
export type InputMaybe<T> = T | null
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
const defaultOptions = {} as const
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  /** An email string */
  EmailScalar: string
  /** An ISO 8601-encoded date */
  ISO8601Date: string
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: string
  /** Money value represented as BigDecimal, transported as string */
  MoneyScalar: string
  /** A normal string, stripped before proceeding to domain code */
  StrippedStringScalar: string
}

/** Error type of AssignCadastralPlots */
export type AssignCadastralPlotsError = {
  __typename: 'AssignCadastralPlotsError'
  cadastralPlotsErrors: Maybe<Array<CadastralPlotsErrors>>
  message: Maybe<BaseErrorMessage>
}

/** Autogenerated input type of AssignCadastralPlots */
export type AssignCadastralPlotsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  externalIds: Array<Scalars['ID']>
}

/** Autogenerated return type of AssignCadastralPlots */
export type AssignCadastralPlotsPayload = {
  __typename: 'AssignCadastralPlotsPayload'
  cadastralPlots: Maybe<Array<CadastralPlot>>
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>
  error: Maybe<AssignCadastralPlotsError>
}

/** Auction */
export type Auction = {
  __typename: 'Auction'
  address: Scalars['String']
  auctionTaxRate: Scalars['Float']
  bidCount: Scalars['Int']
  bidIncrement: MoneyObject
  bids: BidConnection
  cadastralPlots: Array<CadastralPlot>
  currentUserAutoBid: Maybe<AutoBid>
  description: Scalars['String']
  endTime: Scalars['ISO8601DateTime']
  followed: Scalars['Boolean']
  followerCount: Scalars['Int']
  forestInspected: Scalars['Boolean']
  forestType: Maybe<ForestType>
  highestBidObject: Maybe<Bid>
  humanId: Scalars['ID']
  id: Scalars['ID']
  manager: Maybe<Representative>
  maturity: Maybe<TaxonomicPlotMaturityCondition>
  minTax: MoneyObject
  nextMinimumAutoBidPrice: MoneyObject
  nextMinimumBidPrice: MoneyObject
  owner: Maybe<User>
  reservePrice: MoneyObject
  salePurpose: Scalars['String']
  startTime: Scalars['ISO8601DateTime']
  startingBid: MoneyObject
  tax: MoneyObject
  timesViewed: Scalars['Int']
  title: Scalars['String']
  winner: Maybe<User>
}

/** Auction */
export type AuctionBidsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

/** The connection type for Auction. */
export type AuctionConnection = {
  __typename: 'AuctionConnection'
  /** A list of edges. */
  edges: Array<AuctionEdge>
  /** A list of nodes. */
  nodes: Array<Auction>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export enum AuctionCountryCode {
  /** Lithuania */
  Lt = 'LT',
  /** Latvia */
  Lv = 'LV',
}

/** An edge in a connection. */
export type AuctionEdge = {
  __typename: 'AuctionEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']
  /** The item at the end of the edge. */
  node: Maybe<Auction>
}

export type AuctionOrder = {
  currentBidPrice?: InputMaybe<OrderDirection>
  endTime?: InputMaybe<OrderDirection>
  relevance?: InputMaybe<OrderDirection>
  startTime?: InputMaybe<OrderDirection>
  timesViewed?: InputMaybe<OrderDirection>
}

export enum AuctionPermissionIntent {
  Bid = 'bid',
  Start = 'start',
}

export enum AuctionSalePurpose {
  AgriculturalLand = 'agricultural_land',
  ForestForHarvesting = 'forest_for_harvesting',
  ForestWithLand = 'forest_with_land',
  LandPlot = 'land_plot',
  Timber = 'timber',
}

/** An Auction instance referred to by an ID */
export type AuctionType = {
  id: Scalars['ID']
}

/** AuthenticationProvider */
export type AuthenticationProvider = {
  __typename: 'AuthenticationProvider'
  id: Scalars['ID']
  identityMethod: AuthenticationProviderIdentityMethod
}

export enum AuthenticationProviderIdentityMethod {
  Password = 'password',
  SmartId = 'smart_id',
}

/** AutoBid */
export type AutoBid = {
  __typename: 'AutoBid'
  active: Scalars['Boolean']
  auction: Auction
  id: Scalars['ID']
  price: MoneyObject
}

/** An AutoBid instance referred to by an ID */
export type AutoBidType = {
  id: Scalars['ID']
}

export enum BaseErrorMessage {
  GeneralError = 'general_error',
  GenericValidationError = 'generic_validation_error',
  NotFound = 'not_found',
}

/** Bid */
export type Bid = {
  __typename: 'Bid'
  auction: Auction
  autoBid: Maybe<AutoBid>
  belongsToCurrentUser: Scalars['Boolean']
  /** @deprecated Should not be used */
  bidder: User
  bidderIndex: Scalars['String']
  createdAt: Scalars['ISO8601DateTime']
  id: Scalars['ID']
  price: MoneyObject
}

/** The connection type for Bid. */
export type BidConnection = {
  __typename: 'BidConnection'
  /** A list of edges. */
  edges: Array<BidEdge>
  /** A list of nodes. */
  nodes: Array<Bid>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

/** An edge in a connection. */
export type BidEdge = {
  __typename: 'BidEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']
  /** The item at the end of the edge. */
  node: Maybe<Bid>
}

/** CadastralPlot */
export type CadastralPlot = {
  __typename: 'CadastralPlot'
  address: Scalars['String']
  area: Maybe<Scalars['Float']>
  auctions: Array<Auction>
  boundary: Maybe<Scalars['String']>
  cadastralNumber: Scalars['String']
  carbonAbsorptionPerYear: Scalars['Float']
  files: Array<FileObject>
  forestArea: Maybe<Scalars['Float']>
  forestGroups: Array<ForestGroupTitle>
  forestType: Maybe<ForestType>
  forestUtilizationPurpose: Maybe<ForestUtilizationPurpose>
  humanId: Scalars['ID']
  id: Scalars['ID']
  images: Array<ImageObject>
  inspected: Scalars['Boolean']
  latitude: Maybe<Scalars['Float']>
  longitude: Maybe<Scalars['Float']>
  managementPlan: CadastralPlotManagementPlan
  manager: Maybe<Representative>
  marketValue: Maybe<MoneyObject>
  maturity: Maybe<TaxonomicPlotMaturityCondition>
  oldestMainLayerAge: Maybe<Scalars['Int']>
  owners: Array<User>
  services: ServiceConnection
  shouldWaitForUpdates: Maybe<Scalars['Boolean']>
  status: CadastralPlotStatus
  taxonomicPlots: Array<TaxonomicPlot>
  title: Scalars['String']
  totalValue: Maybe<MoneyObject>
  volume: Scalars['Float']
}

/** CadastralPlot */
export type CadastralPlotServicesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

/** The connection type for CadastralPlot. */
export type CadastralPlotConnection = {
  __typename: 'CadastralPlotConnection'
  /** A list of edges. */
  edges: Array<CadastralPlotEdge>
  /** A list of nodes. */
  nodes: Array<CadastralPlot>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  sums: CadastralPlotSums
}

/** An edge in a connection. */
export type CadastralPlotEdge = {
  __typename: 'CadastralPlotEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']
  /** The item at the end of the edge. */
  node: Maybe<CadastralPlot>
}

export enum CadastralPlotErrorEnum {
  MustBePresent = 'must_be_present',
}

export enum CadastralPlotManagementPlan {
  Expired = 'expired',
  None = 'none',
  UnderPreparation = 'under_preparation',
  Valid = 'valid',
}

export enum CadastralPlotStatus {
  Active = 'active',
  Archived = 'archived',
  Draft = 'draft',
}

/** CadastralPlotSums */
export type CadastralPlotSums = {
  __typename: 'CadastralPlotSums'
  area: Scalars['Float']
  marketValue: MoneyObject
  totalValue: MoneyObject
  volume: Scalars['Int']
}

/** Indexed error of CadastralPlot */
export type CadastralPlotsErrors = {
  __typename: 'CadastralPlotsErrors'
  index: Scalars['Int']
  messages: Array<CadastralPlotErrorEnum>
}

/** Company */
export type Company = Person & {
  __typename: 'Company'
  companyName: Scalars['String']
  countryCode: CountryCode
  email: Maybe<Scalars['String']>
  firstName: Scalars['String']
  id: Scalars['ID']
  lastName: Scalars['String']
  legalCode: Scalars['String']
  phoneNumber: Maybe<Scalars['String']>
  photoUrl: Maybe<Scalars['String']>
  streetAddress: Maybe<Scalars['String']>
  vatCode: Maybe<Scalars['String']>
}

/** ContentPage */
export type ContentPage = {
  __typename: 'ContentPage'
  content: Scalars['String']
  createdAt: Scalars['String']
  description: Scalars['String']
  id: Scalars['ID']
  image: Maybe<ImageObject>
  languageCode: Scalars['String']
  link: Scalars['String']
  metaDescription: Scalars['String']
  metaKeywords: Scalars['String']
  metaTitle: Scalars['String']
  styleUrl: Scalars['String']
  title: Scalars['String']
}

/** The connection type for ContentPage. */
export type ContentPageConnection = {
  __typename: 'ContentPageConnection'
  /** A list of edges. */
  edges: Array<ContentPageEdge>
  /** A list of nodes. */
  nodes: Array<ContentPage>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

/** An edge in a connection. */
export type ContentPageEdge = {
  __typename: 'ContentPageEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']
  /** The item at the end of the edge. */
  node: Maybe<ContentPage>
}

/** Country */
export type Country = {
  __typename: 'Country'
  isoCode: Scalars['String']
  name: Scalars['String']
}

export enum CountryCode {
  /** Albania */
  Al = 'AL',
  /** Austria */
  At = 'AT',
  /** Belgium */
  Be = 'BE',
  /** Bulgaria */
  Bg = 'BG',
  /** Canada */
  Ca = 'CA',
  /** Switzerland */
  Ch = 'CH',
  /** Cyprus */
  Cy = 'CY',
  /** Czechia */
  Cz = 'CZ',
  /** Germany */
  De = 'DE',
  /** Denmark */
  Dk = 'DK',
  /** Estonia */
  Ee = 'EE',
  /** Spain */
  Es = 'ES',
  /** Finland */
  Fi = 'FI',
  /** France */
  Fr = 'FR',
  /** United Kingdom of Great Britain and Northern Ireland */
  Gb = 'GB',
  /** Greece */
  Gr = 'GR',
  /** Croatia */
  Hr = 'HR',
  /** Hungary */
  Hu = 'HU',
  /** Ireland */
  Ie = 'IE',
  /** Iceland */
  Is = 'IS',
  /** Italy */
  It = 'IT',
  /** Liechtenstein */
  Li = 'LI',
  /** Lithuania */
  Lt = 'LT',
  /** Luxembourg */
  Lu = 'LU',
  /** Latvia */
  Lv = 'LV',
  /** Montenegro */
  Me = 'ME',
  /** North Macedonia */
  Mk = 'MK',
  /** Malta */
  Mt = 'MT',
  /** Netherlands */
  Nl = 'NL',
  /** Norway */
  No = 'NO',
  /** Poland */
  Pl = 'PL',
  /** Portugal */
  Pt = 'PT',
  /** Romania */
  Ro = 'RO',
  /** Sweden */
  Se = 'SE',
  /** Slovenia */
  Si = 'SI',
  /** Slovakia */
  Sk = 'SK',
  /** Turkey */
  Tr = 'TR',
  /** United States of America */
  Us = 'US',
}

/** CountryRepresentatives */
export type CountryRepresentatives = {
  __typename: 'CountryRepresentatives'
  countryCode: Scalars['ID']
  flagEmoji: Scalars['String']
  representatives: Array<Representative>
}

export enum CreateAuctionCadastralPlotsError {
  CadastralPlotsAlreadyAuctioned = 'cadastral_plots_already_auctioned',
  MustAuctionAtLeastOneCadastralPlot = 'must_auction_at_least_one_cadastral_plot',
  MustOwnCadastralPlotsToAuction = 'must_own_cadastral_plots_to_auction',
}

/** Error type of CreateAuction */
export type CreateAuctionError = {
  __typename: 'CreateAuctionError'
  cadastralPlots: Maybe<Array<CreateAuctionCadastralPlotsError>>
  message: Maybe<BaseErrorMessage>
}

/** Autogenerated input type of CreateAuction */
export type CreateAuctionInput = {
  cadastralPlotIds: Array<Scalars['ID']>
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
}

/** Autogenerated return type of CreateAuction */
export type CreateAuctionPayload = {
  __typename: 'CreateAuctionPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>
  error: Maybe<CreateAuctionError>
}

export enum CreateAutoBidAuctionError {
  AlreadyClosed = 'already_closed',
}

export enum CreateAutoBidBidderError {
  NotAllowedToBid = 'not_allowed_to_bid',
  NotAllowedToBidOwnedAuction = 'not_allowed_to_bid_owned_auction',
}

/** Error type of CreateAutoBid */
export type CreateAutoBidError = {
  __typename: 'CreateAutoBidError'
  auction: Maybe<Array<CreateAutoBidAuctionError>>
  bidder: Maybe<Array<CreateAutoBidBidderError>>
  message: Maybe<BaseErrorMessage>
  price: Maybe<Array<CreateAutoBidPriceError>>
}

/** Autogenerated input type of CreateAutoBid */
export type CreateAutoBidInput = {
  auction: AuctionType
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  price: Scalars['MoneyScalar']
}

/** Autogenerated return type of CreateAutoBid */
export type CreateAutoBidPayload = {
  __typename: 'CreateAutoBidPayload'
  auction: Maybe<Auction>
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>
  error: Maybe<CreateAutoBidError>
}

export enum CreateAutoBidPriceError {
  AutoBidTooLow = 'auto_bid_too_low',
}

export enum CreateBidAuctionError {
  AlreadyClosed = 'already_closed',
}

export enum CreateBidBidderError {
  NotAllowedToBid = 'not_allowed_to_bid',
  NotAllowedToBidOwnedAuction = 'not_allowed_to_bid_owned_auction',
}

/** Error type of CreateBid */
export type CreateBidError = {
  __typename: 'CreateBidError'
  auction: Maybe<Array<CreateBidAuctionError>>
  bidder: Maybe<Array<CreateBidBidderError>>
  message: Maybe<BaseErrorMessage>
  price: Maybe<Array<CreateBidPriceError>>
}

/** Autogenerated input type of CreateBid */
export type CreateBidInput = {
  auction: AuctionType
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  price: Scalars['MoneyScalar']
}

/** Autogenerated return type of CreateBid */
export type CreateBidPayload = {
  __typename: 'CreateBidPayload'
  bid: Maybe<Bid>
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>
  error: Maybe<CreateBidError>
}

export enum CreateBidPriceError {
  BidTooLow = 'bid_too_low',
}

/** Error type of CreateCompany */
export type CreateCompanyError = {
  __typename: 'CreateCompanyError'
  message: Maybe<BaseErrorMessage>
}

/** Autogenerated input type of CreateCompany */
export type CreateCompanyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  companyName: Scalars['StrippedStringScalar']
  countryCode?: InputMaybe<Scalars['StrippedStringScalar']>
  firstName?: InputMaybe<Scalars['StrippedStringScalar']>
  lastName?: InputMaybe<Scalars['StrippedStringScalar']>
  legalCode: Scalars['StrippedStringScalar']
  streetAddress?: InputMaybe<Scalars['StrippedStringScalar']>
  vatCode?: InputMaybe<Scalars['StrippedStringScalar']>
}

/** Autogenerated return type of CreateCompany */
export type CreateCompanyPayload = {
  __typename: 'CreateCompanyPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>
  error: Maybe<CreateCompanyError>
  user: Maybe<User>
}

export enum CreateLeadUserEmailError {
  EmailInvalid = 'email_invalid',
  FormatInvalid = 'format_invalid',
  Taken = 'taken',
}

/** Error type of CreateLeadUser */
export type CreateLeadUserError = {
  __typename: 'CreateLeadUserError'
  email: Maybe<Array<CreateLeadUserEmailError>>
  message: Maybe<BaseErrorMessage>
  termsAndConditionsAccepted: Maybe<Array<CreateLeadUserTermsAndConditionsAcceptedError>>
}

/** Autogenerated input type of CreateLeadUser */
export type CreateLeadUserInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  email: Scalars['EmailScalar']
  marketingConditionsAccepted: Scalars['Boolean']
  recaptchaToken?: InputMaybe<Scalars['String']>
  termsAndConditionsAccepted: Scalars['Boolean']
}

/** Autogenerated return type of CreateLeadUser */
export type CreateLeadUserPayload = {
  __typename: 'CreateLeadUserPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>
  error: Maybe<CreateLeadUserError>
  leadUser: Maybe<User>
}

export enum CreateLeadUserTermsAndConditionsAcceptedError {
  MustBeTrue = 'must_be_true',
}

/** Error type of CreateOpenIDConnectUser */
export type CreateOpenIdConnectUserError = {
  __typename: 'CreateOpenIDConnectUserError'
  leadUser: Maybe<Array<CreateOpenIdConnectUserLeadUserError>>
  message: Maybe<BaseErrorMessage>
}

/** Autogenerated input type of CreateOpenIDConnectUser */
export type CreateOpenIdConnectUserInput = {
  authorizationCode: Scalars['String']
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  leadUserId: Scalars['ID']
}

export enum CreateOpenIdConnectUserLeadUserError {
  DoesNotExist = 'does_not_exist',
}

/** Autogenerated return type of CreateOpenIDConnectUser */
export type CreateOpenIdConnectUserPayload = {
  __typename: 'CreateOpenIDConnectUserPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>
  error: Maybe<CreateOpenIdConnectUserError>
  token: Maybe<Scalars['String']>
  user: Maybe<User>
}

/** Error type of CreateRoughPriceInquiry */
export type CreateRoughPriceInquiryError = {
  __typename: 'CreateRoughPriceInquiryError'
  intents: Maybe<Array<CreateRoughPriceInquiryIntentsError>>
  message: Maybe<BaseErrorMessage>
}

/** Autogenerated input type of CreateRoughPriceInquiry */
export type CreateRoughPriceInquiryInput = {
  area: Scalars['Float']
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  intents: Array<RoughPriceCalculationIntent>
  leafType: ForestType
  maturity: TaxonomicPlotMaturityCondition
}

export enum CreateRoughPriceInquiryIntentsError {
  AtLeastOneMustBeSelected = 'at_least_one_must_be_selected',
}

/** Autogenerated return type of CreateRoughPriceInquiry */
export type CreateRoughPriceInquiryPayload = {
  __typename: 'CreateRoughPriceInquiryPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>
  error: Maybe<CreateRoughPriceInquiryError>
  roughPriceInquiry: Maybe<RoughPriceInquiry>
}

/** Error type of CreateUser */
export type CreateUserError = {
  __typename: 'CreateUserError'
  leadUser: Maybe<Array<CreateUserLeadUserError>>
  message: Maybe<BaseErrorMessage>
  password: Maybe<Array<CreateUserPasswordError>>
  passwordConfirmation: Maybe<Array<CreateUserPasswordConfirmationError>>
  phoneNumber: Maybe<Array<CreateUserPhoneNumberError>>
}

/** Autogenerated input type of CreateUser */
export type CreateUserInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  leadUserId: Scalars['ID']
  password: Scalars['String']
  passwordConfirmation?: InputMaybe<Scalars['String']>
  phoneNumber?: InputMaybe<Scalars['StrippedStringScalar']>
}

export enum CreateUserLeadUserError {
  DoesNotExist = 'does_not_exist',
}

export enum CreateUserPasswordConfirmationError {
  DoesNotMatch = 'does_not_match',
}

export enum CreateUserPasswordError {
  LengthInvalid = 'length_invalid',
}

/** Autogenerated return type of CreateUser */
export type CreateUserPayload = {
  __typename: 'CreateUserPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>
  error: Maybe<CreateUserError>
  token: Maybe<Scalars['String']>
  user: Maybe<User>
}

export enum CreateUserPhoneNumberError {
  FormatInvalid = 'format_invalid',
}

/** Currency */
export type Currency = {
  __typename: 'Currency'
  isoCode: CurrencyCode
  name: Scalars['String']
  symbol: Scalars['String']
}

export enum CurrencyCode {
  /** United Arab Emirates Dirham */
  Aed = 'AED',
  /** Afghan Afghani */
  Afn = 'AFN',
  /** Albanian Lek */
  All = 'ALL',
  /** Armenian Dram */
  Amd = 'AMD',
  /** Netherlands Antillean Gulden */
  Ang = 'ANG',
  /** Angolan Kwanza */
  Aoa = 'AOA',
  /** Argentine Peso */
  Ars = 'ARS',
  /** Australian Dollar */
  Aud = 'AUD',
  /** Aruban Florin */
  Awg = 'AWG',
  /** Azerbaijani Manat */
  Azn = 'AZN',
  /** Bosnia and Herzegovina Convertible Mark */
  Bam = 'BAM',
  /** Barbadian Dollar */
  Bbd = 'BBD',
  /** Bitcoin Cash */
  Bch = 'BCH',
  /** Bangladeshi Taka */
  Bdt = 'BDT',
  /** Bulgarian Lev */
  Bgn = 'BGN',
  /** Bahraini Dinar */
  Bhd = 'BHD',
  /** Burundian Franc */
  Bif = 'BIF',
  /** Bermudian Dollar */
  Bmd = 'BMD',
  /** Brunei Dollar */
  Bnd = 'BND',
  /** Bolivian Boliviano */
  Bob = 'BOB',
  /** Brazilian Real */
  Brl = 'BRL',
  /** Bahamian Dollar */
  Bsd = 'BSD',
  /** Bitcoin */
  Btc = 'BTC',
  /** Bhutanese Ngultrum */
  Btn = 'BTN',
  /** Botswana Pula */
  Bwp = 'BWP',
  /** Belarusian Ruble */
  Byn = 'BYN',
  /** Belarusian Ruble */
  Byr = 'BYR',
  /** Belize Dollar */
  Bzd = 'BZD',
  /** Canadian Dollar */
  Cad = 'CAD',
  /** Congolese Franc */
  Cdf = 'CDF',
  /** Swiss Franc */
  Chf = 'CHF',
  /** Unidad de Fomento */
  Clf = 'CLF',
  /** Chilean Peso */
  Clp = 'CLP',
  /** Chinese Renminbi Yuan Offshore */
  Cnh = 'CNH',
  /** Chinese Renminbi Yuan */
  Cny = 'CNY',
  /** Colombian Peso */
  Cop = 'COP',
  /** Costa Rican Colón */
  Crc = 'CRC',
  /** Cuban Convertible Peso */
  Cuc = 'CUC',
  /** Cuban Peso */
  Cup = 'CUP',
  /** Cape Verdean Escudo */
  Cve = 'CVE',
  /** Czech Koruna */
  Czk = 'CZK',
  /** Djiboutian Franc */
  Djf = 'DJF',
  /** Danish Krone */
  Dkk = 'DKK',
  /** Dominican Peso */
  Dop = 'DOP',
  /** Algerian Dinar */
  Dzd = 'DZD',
  /** Estonian Kroon */
  Eek = 'EEK',
  /** Egyptian Pound */
  Egp = 'EGP',
  /** Eritrean Nakfa */
  Ern = 'ERN',
  /** Ethiopian Birr */
  Etb = 'ETB',
  /** Euro */
  Eur = 'EUR',
  /** Fijian Dollar */
  Fjd = 'FJD',
  /** Falkland Pound */
  Fkp = 'FKP',
  /** British Pound */
  Gbp = 'GBP',
  /** British Penny */
  Gbx = 'GBX',
  /** Georgian Lari */
  Gel = 'GEL',
  /** Guernsey Pound */
  Ggp = 'GGP',
  /** Ghanaian Cedi */
  Ghs = 'GHS',
  /** Gibraltar Pound */
  Gip = 'GIP',
  /** Gambian Dalasi */
  Gmd = 'GMD',
  /** Guinean Franc */
  Gnf = 'GNF',
  /** Guatemalan Quetzal */
  Gtq = 'GTQ',
  /** Guyanese Dollar */
  Gyd = 'GYD',
  /** Hong Kong Dollar */
  Hkd = 'HKD',
  /** Honduran Lempira */
  Hnl = 'HNL',
  /** Croatian Kuna */
  Hrk = 'HRK',
  /** Haitian Gourde */
  Htg = 'HTG',
  /** Hungarian Forint */
  Huf = 'HUF',
  /** Indonesian Rupiah */
  Idr = 'IDR',
  /** Israeli New Sheqel */
  Ils = 'ILS',
  /** Isle of Man Pound */
  Imp = 'IMP',
  /** Indian Rupee */
  Inr = 'INR',
  /** Iraqi Dinar */
  Iqd = 'IQD',
  /** Iranian Rial */
  Irr = 'IRR',
  /** Icelandic Króna */
  Isk = 'ISK',
  /** Jersey Pound */
  Jep = 'JEP',
  /** Jamaican Dollar */
  Jmd = 'JMD',
  /** Jordanian Dinar */
  Jod = 'JOD',
  /** Japanese Yen */
  Jpy = 'JPY',
  /** Kenyan Shilling */
  Kes = 'KES',
  /** Kyrgyzstani Som */
  Kgs = 'KGS',
  /** Cambodian Riel */
  Khr = 'KHR',
  /** Comorian Franc */
  Kmf = 'KMF',
  /** North Korean Won */
  Kpw = 'KPW',
  /** South Korean Won */
  Krw = 'KRW',
  /** Kuwaiti Dinar */
  Kwd = 'KWD',
  /** Cayman Islands Dollar */
  Kyd = 'KYD',
  /** Kazakhstani Tenge */
  Kzt = 'KZT',
  /** Lao Kip */
  Lak = 'LAK',
  /** Lebanese Pound */
  Lbp = 'LBP',
  /** Sri Lankan Rupee */
  Lkr = 'LKR',
  /** Liberian Dollar */
  Lrd = 'LRD',
  /** Lesotho Loti */
  Lsl = 'LSL',
  /** Lithuanian Litas */
  Ltl = 'LTL',
  /** Latvian Lats */
  Lvl = 'LVL',
  /** Libyan Dinar */
  Lyd = 'LYD',
  /** Moroccan Dirham */
  Mad = 'MAD',
  /** Moldovan Leu */
  Mdl = 'MDL',
  /** Malagasy Ariary */
  Mga = 'MGA',
  /** Macedonian Denar */
  Mkd = 'MKD',
  /** Myanmar Kyat */
  Mmk = 'MMK',
  /** Mongolian Tögrög */
  Mnt = 'MNT',
  /** Macanese Pataca */
  Mop = 'MOP',
  /** Mauritanian Ouguiya */
  Mro = 'MRO',
  /** Mauritanian Ouguiya */
  Mru = 'MRU',
  /** Maltese Lira */
  Mtl = 'MTL',
  /** Mauritian Rupee */
  Mur = 'MUR',
  /** Maldivian Rufiyaa */
  Mvr = 'MVR',
  /** Malawian Kwacha */
  Mwk = 'MWK',
  /** Mexican Peso */
  Mxn = 'MXN',
  /** Malaysian Ringgit */
  Myr = 'MYR',
  /** Mozambican Metical */
  Mzn = 'MZN',
  /** Namibian Dollar */
  Nad = 'NAD',
  /** Nigerian Naira */
  Ngn = 'NGN',
  /** Nicaraguan Córdoba */
  Nio = 'NIO',
  /** Norwegian Krone */
  Nok = 'NOK',
  /** Nepalese Rupee */
  Npr = 'NPR',
  /** New Zealand Dollar */
  Nzd = 'NZD',
  /** Omani Rial */
  Omr = 'OMR',
  /** Panamanian Balboa */
  Pab = 'PAB',
  /** Peruvian Sol */
  Pen = 'PEN',
  /** Papua New Guinean Kina */
  Pgk = 'PGK',
  /** Philippine Peso */
  Php = 'PHP',
  /** Pakistani Rupee */
  Pkr = 'PKR',
  /** Polish Złoty */
  Pln = 'PLN',
  /** Paraguayan Guaraní */
  Pyg = 'PYG',
  /** Qatari Riyal */
  Qar = 'QAR',
  /** Romanian Leu */
  Ron = 'RON',
  /** Serbian Dinar */
  Rsd = 'RSD',
  /** Russian Ruble */
  Rub = 'RUB',
  /** Rwandan Franc */
  Rwf = 'RWF',
  /** Saudi Riyal */
  Sar = 'SAR',
  /** Solomon Islands Dollar */
  Sbd = 'SBD',
  /** Seychellois Rupee */
  Scr = 'SCR',
  /** Sudanese Pound */
  Sdg = 'SDG',
  /** Swedish Krona */
  Sek = 'SEK',
  /** Singapore Dollar */
  Sgd = 'SGD',
  /** Saint Helenian Pound */
  Shp = 'SHP',
  /** Slovak Koruna */
  Skk = 'SKK',
  /** Sierra Leonean Leone */
  Sll = 'SLL',
  /** Somali Shilling */
  Sos = 'SOS',
  /** Surinamese Dollar */
  Srd = 'SRD',
  /** South Sudanese Pound */
  Ssp = 'SSP',
  /** São Tomé and Príncipe Dobra */
  Std = 'STD',
  /** Salvadoran Colón */
  Svc = 'SVC',
  /** Syrian Pound */
  Syp = 'SYP',
  /** Swazi Lilangeni */
  Szl = 'SZL',
  /** Thai Baht */
  Thb = 'THB',
  /** Tajikistani Somoni */
  Tjs = 'TJS',
  /** Turkmenistani Manat */
  Tmm = 'TMM',
  /** Turkmenistani Manat */
  Tmt = 'TMT',
  /** Tunisian Dinar */
  Tnd = 'TND',
  /** Tongan Paʻanga */
  Top = 'TOP',
  /** Turkish Lira */
  Try = 'TRY',
  /** Trinidad and Tobago Dollar */
  Ttd = 'TTD',
  /** New Taiwan Dollar */
  Twd = 'TWD',
  /** Tanzanian Shilling */
  Tzs = 'TZS',
  /** Ukrainian Hryvnia */
  Uah = 'UAH',
  /** Ugandan Shilling */
  Ugx = 'UGX',
  /** United States Dollar */
  Usd = 'USD',
  /** Uruguayan Peso */
  Uyu = 'UYU',
  /** Uzbekistan Som */
  Uzs = 'UZS',
  /** Venezuelan Bolívar */
  Vef = 'VEF',
  /** Venezuelan Bolívar Soberano */
  Ves = 'VES',
  /** Vietnamese Đồng */
  Vnd = 'VND',
  /** Vanuatu Vatu */
  Vuv = 'VUV',
  /** Samoan Tala */
  Wst = 'WST',
  /** Central African Cfa Franc */
  Xaf = 'XAF',
  /** Silver (Troy Ounce) */
  Xag = 'XAG',
  /** Gold (Troy Ounce) */
  Xau = 'XAU',
  /** European Composite Unit */
  Xba = 'XBA',
  /** European Monetary Unit */
  Xbb = 'XBB',
  /** European Unit of Account 9 */
  Xbc = 'XBC',
  /** European Unit of Account 17 */
  Xbd = 'XBD',
  /** East Caribbean Dollar */
  Xcd = 'XCD',
  /** Special Drawing Rights */
  Xdr = 'XDR',
  /** UIC Franc */
  Xfu = 'XFU',
  /** West African Cfa Franc */
  Xof = 'XOF',
  /** Palladium */
  Xpd = 'XPD',
  /** Cfp Franc */
  Xpf = 'XPF',
  /** Platinum */
  Xpt = 'XPT',
  /** Codes specifically reserved for testing purposes */
  Xts = 'XTS',
  /** Yemeni Rial */
  Yer = 'YER',
  /** South African Rand */
  Zar = 'ZAR',
  /** Zambian Kwacha */
  Zmk = 'ZMK',
  /** Zambian Kwacha */
  Zmw = 'ZMW',
  /** Zimbabwean Dollar */
  Zwd = 'ZWD',
  /** Zimbabwean Dollar */
  Zwl = 'ZWL',
  /** Zimbabwean Dollar */
  Zwn = 'ZWN',
  /** Zimbabwean Dollar */
  Zwr = 'ZWR',
}

export enum DisableAutoBidAutoBidError {
  NotAutoBidOwner = 'not_auto_bid_owner',
}

/** Error type of DisableAutoBid */
export type DisableAutoBidError = {
  __typename: 'DisableAutoBidError'
  autoBid: Maybe<Array<DisableAutoBidAutoBidError>>
  message: Maybe<BaseErrorMessage>
}

/** Autogenerated input type of DisableAutoBid */
export type DisableAutoBidInput = {
  autoBid: AutoBidType
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
}

/** Autogenerated return type of DisableAutoBid */
export type DisableAutoBidPayload = {
  __typename: 'DisableAutoBidPayload'
  autoBid: Maybe<AutoBid>
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>
  error: Maybe<DisableAutoBidError>
}

export type FiDeadTreeLayer = {
  __typename: 'FIDeadTreeLayer'
  deadTreeType: Scalars['Int']
  externalId: Scalars['Int']
  meanDiameter: Maybe<Scalars['Int']>
  treeSpecies: Maybe<Scalars['String']>
  volume: Maybe<Scalars['Int']>
}

export type FiTaxonomicIdentifier = {
  __typename: 'FITaxonomicIdentifier'
  type: Scalars['Int']
  value: Scalars['String']
}

/** Finnish taxonomic plot */
export type FiTaxonomicPlot = TaxonomicPlotInterface & {
  __typename: 'FITaxonomicPlot'
  accessibility: Maybe<Scalars['String']>
  area: Maybe<Scalars['Float']>
  areaDecrease: Scalars['Float']
  boundary: Maybe<Scalars['String']>
  completeState: Scalars['Int']
  cuttingRestriction: Maybe<Scalars['Int']>
  dataSource: Scalars['Int']
  developmentClass: Maybe<Scalars['String']>
  ditchingYear: Maybe<Scalars['Int']>
  drainageState: Maybe<Scalars['String']>
  externalId: Scalars['Int']
  fertilityClass: Maybe<Scalars['String']>
  growthPlaceDataSource: Maybe<Scalars['Int']>
  id: Scalars['ID']
  identifiers: Array<FiTaxonomicIdentifier>
  latestSurvey: FiTaxonomicSurvey
  mainGroup: Maybe<Scalars['String']>
  mainTreeSpecies: Maybe<Scalars['String']>
  silvicultureRestriction: Maybe<Scalars['Int']>
  soilType: Maybe<Scalars['String']>
  specialFeatures: Array<FiTaxonomicSpecialFeature>
  standBasicDataDate: Scalars['String']
  standNumber: Scalars['String']
  standQuality: Maybe<Scalars['String']>
  subGroup: Maybe<Scalars['String']>
}

/** Finnish taxonomic plot */
export type FiTaxonomicPlotAreaArgs = {
  cadastralPlotId?: InputMaybe<Scalars['ID']>
}

export type FiTaxonomicSpecialFeature = {
  __typename: 'FITaxonomicSpecialFeature'
  externalId: Scalars['Int']
  featureAdditionalCode: Maybe<Scalars['String']>
  featureCode: Maybe<Scalars['String']>
  mainFeature: Maybe<Scalars['String']>
}

export type FiTaxonomicSurvey = {
  __typename: 'FITaxonomicSurvey'
  deadTreeLayers: Array<FiDeadTreeLayer>
  summary: Maybe<FiTaxonomicSurveySummary>
  surveyDate: Scalars['String']
  surveyType: Scalars['Int']
  treeLayers: Array<FiTreeLayer>
}

export type FiTaxonomicSurveySummary = {
  __typename: 'FITaxonomicSurveySummary'
  basalArea: Scalars['Float']
  branchBiomass: Maybe<Scalars['Float']>
  developmentClass: Maybe<Scalars['String']>
  externalId: Scalars['Int']
  leafBiomass: Maybe<Scalars['Float']>
  mainTreeSpecies: Maybe<Scalars['String']>
  meanAge: Scalars['Int']
  meanDiameter: Maybe<Scalars['Float']>
  meanHeight: Scalars['Float']
  pulpWoodVolume: Scalars['Float']
  sawLogVolume: Scalars['Float']
  stemBiomass: Maybe<Scalars['Float']>
  stemCount: Scalars['Int']
  stumpBiomass: Maybe<Scalars['Float']>
  volume: Maybe<Scalars['Float']>
  volumeGrowth: Scalars['Float']
}

export type FiTreeLayer = {
  __typename: 'FITreeLayer'
  age: Scalars['Int']
  basalArea: Maybe<Scalars['Float']>
  branchBiomass: Maybe<Scalars['Float']>
  dataSource: Maybe<Scalars['Int']>
  externalId: Scalars['Int']
  leafBiomass: Maybe<Scalars['Float']>
  meanDiameter: Maybe<Scalars['Float']>
  meanHeight: Scalars['Float']
  pulpWoodVolume: Maybe<Scalars['Float']>
  sawLogPercent: Maybe<Scalars['Float']>
  sawLogVolume: Maybe<Scalars['Float']>
  stemBiomass: Maybe<Scalars['Float']>
  stemCount: Maybe<Scalars['Int']>
  storey: Maybe<Scalars['String']>
  stratumNumber: Scalars['Int']
  stumpBiomass: Maybe<Scalars['Float']>
  treeSpecies: Maybe<Scalars['String']>
  volume: Maybe<Scalars['Float']>
  volumeGrowth: Maybe<Scalars['Float']>
}

export type FileObject = {
  __typename: 'FileObject'
  filename: Scalars['String']
  id: Scalars['String']
  url: Scalars['String']
}

/** Error type of FollowAuction */
export type FollowAuctionError = {
  __typename: 'FollowAuctionError'
  message: Maybe<BaseErrorMessage>
}

/** Autogenerated input type of FollowAuction */
export type FollowAuctionInput = {
  auction: AuctionType
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
}

/** Autogenerated return type of FollowAuction */
export type FollowAuctionPayload = {
  __typename: 'FollowAuctionPayload'
  auction: Maybe<Auction>
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>
  error: Maybe<FollowAuctionError>
}

/** ForestGroup */
export type ForestGroup = {
  __typename: 'ForestGroup'
  boundary: Scalars['String']
  id: Scalars['ID']
  title: ForestGroupTitle
}

export enum ForestGroupTitle {
  /** reserved */
  I = 'I',
  /** unspecified special utility */
  Ii = 'II',
  /** ecosystem protection */
  Iia = 'IIA',
  /** recreational */
  Iib = 'IIB',
  /** protected */
  Iii = 'III',
  /** economic */
  Iv = 'IV',
}

/** ForestHabitat */
export type ForestHabitat = {
  __typename: 'ForestHabitat'
  area: Maybe<Scalars['Float']>
  boundary: Maybe<Scalars['String']>
  category: Maybe<ForestHabitatCategory>
  codes: Array<Scalars['String']>
  id: Scalars['ID']
  kind: ForestHabitatKind
}

export enum ForestHabitatCategory {
  Forest = 'forest',
  Meadow = 'meadow',
  Sand = 'sand',
  Swamp = 'swamp',
  Water = 'water',
}

export enum ForestHabitatKind {
  EcImportance = 'ec_importance',
  KeyForest = 'key_forest',
}

export enum ForestType {
  /** forest containing coniferous trees */
  Coniferous = 'coniferous',
  /** forest containing deciduous trees */
  Deciduous = 'deciduous',
  /** mixed forest containing both deciduous and coniferous trees */
  Mixed = 'mixed',
}

export enum ForestUtilizationPurpose {
  Agricultural = 'agricultural',
  Conservation = 'conservation',
  Forest = 'forest',
  Other = 'other',
  WaterManagement = 'water_management',
}

export type ImageObject = {
  __typename: 'ImageObject'
  filename: Scalars['String']
  height: Scalars['Int']
  id: Scalars['String']
  originalUrl: Scalars['String']
  thumbUrl: Scalars['String']
  width: Scalars['Int']
}

/** Error type of IncrementTimesViewed */
export type IncrementTimesViewedError = {
  __typename: 'IncrementTimesViewedError'
  message: Maybe<BaseErrorMessage>
}

/** Autogenerated input type of IncrementTimesViewed */
export type IncrementTimesViewedInput = {
  auctionId: Scalars['ID']
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
}

/** Autogenerated return type of IncrementTimesViewed */
export type IncrementTimesViewedPayload = {
  __typename: 'IncrementTimesViewedPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>
  error: Maybe<IncrementTimesViewedError>
}

export type MoneyObject = {
  __typename: 'MoneyObject'
  amount: Scalars['MoneyScalar']
  currency: Currency
}

export type Mutation = {
  __typename: 'Mutation'
  assignCadastralPlots: Maybe<AssignCadastralPlotsPayload>
  createAuction: Maybe<CreateAuctionPayload>
  createAutoBid: Maybe<CreateAutoBidPayload>
  createBid: Maybe<CreateBidPayload>
  createCompany: Maybe<CreateCompanyPayload>
  createLeadUser: Maybe<CreateLeadUserPayload>
  createOpenIdConnectUser: Maybe<CreateOpenIdConnectUserPayload>
  createRoughPriceInquiry: Maybe<CreateRoughPriceInquiryPayload>
  createStartAuctionInquiry: Maybe<StartAuctionInquiryPayload>
  createUser: Maybe<CreateUserPayload>
  disableAutoBid: Maybe<DisableAutoBidPayload>
  followAuction: Maybe<FollowAuctionPayload>
  incrementTimesViewed: Maybe<IncrementTimesViewedPayload>
  orderService: Maybe<OrderServicePayload>
  requestAuctionPermission: Maybe<RequestAuctionPermissionPayload>
  sendPasswordReset: Maybe<SendPasswordResetPayload>
  signInWithEmail: Maybe<SignInWithEmailPayload>
  signInWithOpenIdConnect: Maybe<SignInWithOpenIdConnectPayload>
  signUpWithEmail: Maybe<SignUpWithEmailPayload>
  unfollowAuction: Maybe<UnfollowAuctionPayload>
  updateCompanyDetails: Maybe<UpdateCompanyDetailsPayload>
  updateForgottenPassword: Maybe<UpdateForgottenPasswordPayload>
  updateNotificationSettings: Maybe<UpdateNotificationSettingsPayload>
  updatePersonDetails: Maybe<UpdatePersonDetailsPayload>
  updateRoughPriceInquiryCadastralNumber: Maybe<UpdateRoughPriceInquiryCadastralNumberPayload>
  updateRoughPriceInquiryContacts: Maybe<UpdateRoughPriceInquiryContactsPayload>
}

export type MutationAssignCadastralPlotsArgs = {
  input: AssignCadastralPlotsInput
}

export type MutationCreateAuctionArgs = {
  input: CreateAuctionInput
}

export type MutationCreateAutoBidArgs = {
  input: CreateAutoBidInput
}

export type MutationCreateBidArgs = {
  input: CreateBidInput
}

export type MutationCreateCompanyArgs = {
  input: CreateCompanyInput
}

export type MutationCreateLeadUserArgs = {
  input: CreateLeadUserInput
}

export type MutationCreateOpenIdConnectUserArgs = {
  input: CreateOpenIdConnectUserInput
}

export type MutationCreateRoughPriceInquiryArgs = {
  input: CreateRoughPriceInquiryInput
}

export type MutationCreateStartAuctionInquiryArgs = {
  input: StartAuctionInquiryInput
}

export type MutationCreateUserArgs = {
  input: CreateUserInput
}

export type MutationDisableAutoBidArgs = {
  input: DisableAutoBidInput
}

export type MutationFollowAuctionArgs = {
  input: FollowAuctionInput
}

export type MutationIncrementTimesViewedArgs = {
  input: IncrementTimesViewedInput
}

export type MutationOrderServiceArgs = {
  input: OrderServiceInput
}

export type MutationRequestAuctionPermissionArgs = {
  input: RequestAuctionPermissionInput
}

export type MutationSendPasswordResetArgs = {
  input: SendPasswordResetInput
}

export type MutationSignInWithEmailArgs = {
  input: SignInWithEmailInput
}

export type MutationSignInWithOpenIdConnectArgs = {
  input: SignInWithOpenIdConnectInput
}

export type MutationSignUpWithEmailArgs = {
  input: SignUpWithEmailInput
}

export type MutationUnfollowAuctionArgs = {
  input: UnfollowAuctionInput
}

export type MutationUpdateCompanyDetailsArgs = {
  input: UpdateCompanyDetailsInput
}

export type MutationUpdateForgottenPasswordArgs = {
  input: UpdateForgottenPasswordInput
}

export type MutationUpdateNotificationSettingsArgs = {
  input: UpdateNotificationSettingsInput
}

export type MutationUpdatePersonDetailsArgs = {
  input: UpdatePersonDetailsInput
}

export type MutationUpdateRoughPriceInquiryCadastralNumberArgs = {
  input: UpdateRoughPriceInquiryCadastralNumberInput
}

export type MutationUpdateRoughPriceInquiryContactsArgs = {
  input: UpdateRoughPriceInquiryContactsInput
}

export enum OpenIdConnectIdentityMethod {
  SmartId = 'smart_id',
}

export enum OrderDirection {
  /** ascending */
  Asc = 'asc',
  /** descending */
  Desc = 'desc',
}

/** Error type of OrderService */
export type OrderServiceError = {
  __typename: 'OrderServiceError'
  message: Maybe<BaseErrorMessage>
  status: Maybe<Array<OrderServiceStatusError>>
}

/** Autogenerated input type of OrderService */
export type OrderServiceInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
}

/** Autogenerated return type of OrderService */
export type OrderServicePayload = {
  __typename: 'OrderServicePayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>
  error: Maybe<OrderServiceError>
  service: Maybe<Service>
}

export enum OrderServiceStatusError {
  AgreementRequired = 'agreement_required',
  AlreadyOrdered = 'already_ordered',
}

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename: 'PageInfo'
  /** When paginating forwards, the cursor to continue. */
  endCursor: Maybe<Scalars['String']>
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']
  /** When paginating backwards, the cursor to continue. */
  startCursor: Maybe<Scalars['String']>
}

/** A human being with a name, picture, and contact details */
export type Person = {
  email: Maybe<Scalars['String']>
  firstName: Maybe<Scalars['String']>
  lastName: Maybe<Scalars['String']>
  phoneNumber: Maybe<Scalars['String']>
  photoUrl: Maybe<Scalars['String']>
}

/** Foros schema query root */
export type Query = {
  __typename: 'Query'
  auction: Maybe<Auction>
  auctions: AuctionConnection
  cadastralPlot: Maybe<CadastralPlot>
  contentPage: Maybe<ContentPage>
  contentPages: ContentPageConnection
  countries: Array<Country>
  countryRepresentatives: Array<CountryRepresentatives>
  currentUser: Maybe<User>
  followedAuctions: AuctionConnection
  forestGroup: Maybe<ForestGroup>
  forestHabitat: Maybe<ForestHabitat>
  internationalTaxonomicPlot: Maybe<TaxonomicPlotInterface>
  isPasswordSecure: Maybe<Scalars['Boolean']>
  marketAuctions: AuctionConnection
  openIdConnectAuthorizationUrl: Scalars['String']
  ownedAuctions: AuctionConnection
  participatingAuctions: AuctionConnection
  pressMentions: ContentPageConnection
  roughPriceInquiry: Maybe<RoughPriceInquiry>
  service: Maybe<Service>
  services: Maybe<ServiceConnection>
  taxonomicPlot: Maybe<TaxonomicPlot>
  userAuthenticationProviders: Array<AuthenticationProvider>
  userRegistrationType: Maybe<SignUpType>
  wonAuctions: AuctionConnection
}

/** Foros schema query root */
export type QueryAuctionArgs = {
  id: Scalars['ID']
}

/** Foros schema query root */
export type QueryAuctionsArgs = {
  after?: InputMaybe<Scalars['String']>
  areaGteq?: InputMaybe<Scalars['Float']>
  areaLteq?: InputMaybe<Scalars['Float']>
  auctionOpen?: InputMaybe<Scalars['Boolean']>
  before?: InputMaybe<Scalars['String']>
  countryCodeIn?: InputMaybe<Array<AuctionCountryCode>>
  first?: InputMaybe<Scalars['Int']>
  forestTypeIn?: InputMaybe<Array<ForestType>>
  last?: InputMaybe<Scalars['Int']>
  maturityIn?: InputMaybe<Array<TaxonomicPlotMaturityCondition>>
  order?: InputMaybe<AuctionOrder>
  ownerIdEq?: InputMaybe<Scalars['ID']>
  priceCentsGteq?: InputMaybe<Scalars['Int']>
  priceCentsLteq?: InputMaybe<Scalars['Int']>
  salePurposeIn?: InputMaybe<Array<AuctionSalePurpose>>
  titleCont?: InputMaybe<Scalars['String']>
  volumeGteq?: InputMaybe<Scalars['Int']>
  volumeLteq?: InputMaybe<Scalars['Int']>
}

/** Foros schema query root */
export type QueryCadastralPlotArgs = {
  id: Scalars['ID']
}

/** Foros schema query root */
export type QueryContentPageArgs = {
  slug: Scalars['String']
}

/** Foros schema query root */
export type QueryContentPagesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

/** Foros schema query root */
export type QueryCountryRepresentativesArgs = {
  order?: InputMaybe<RepresentativeOrder>
  visibleInAboutUsEq?: InputMaybe<Scalars['Boolean']>
  visibleInContactsEq?: InputMaybe<Scalars['Boolean']>
}

/** Foros schema query root */
export type QueryFollowedAuctionsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

/** Foros schema query root */
export type QueryForestGroupArgs = {
  id: Scalars['ID']
}

/** Foros schema query root */
export type QueryForestHabitatArgs = {
  id: Scalars['ID']
}

/** Foros schema query root */
export type QueryInternationalTaxonomicPlotArgs = {
  id: Scalars['ID']
}

/** Foros schema query root */
export type QueryIsPasswordSecureArgs = {
  password: Scalars['String']
}

/** Foros schema query root */
export type QueryMarketAuctionsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

/** Foros schema query root */
export type QueryOpenIdConnectAuthorizationUrlArgs = {
  identityMethod: OpenIdConnectIdentityMethod
  state: Scalars['String']
}

/** Foros schema query root */
export type QueryOwnedAuctionsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

/** Foros schema query root */
export type QueryParticipatingAuctionsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

/** Foros schema query root */
export type QueryPressMentionsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

/** Foros schema query root */
export type QueryRoughPriceInquiryArgs = {
  id: Scalars['ID']
}

/** Foros schema query root */
export type QueryServiceArgs = {
  id: Scalars['ID']
}

/** Foros schema query root */
export type QueryServicesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  id: Scalars['ID']
  last?: InputMaybe<Scalars['Int']>
}

/** Foros schema query root */
export type QueryTaxonomicPlotArgs = {
  id: Scalars['ID']
}

/** Foros schema query root */
export type QueryUserAuthenticationProvidersArgs = {
  email: Scalars['EmailScalar']
}

/** Foros schema query root */
export type QueryUserRegistrationTypeArgs = {
  email: Scalars['EmailScalar']
}

/** Foros schema query root */
export type QueryWonAuctionsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

/** Representative */
export type Representative = Person & {
  __typename: 'Representative'
  countryCode: CountryCode
  description: Maybe<Scalars['String']>
  email: Maybe<Scalars['String']>
  firstName: Maybe<Scalars['String']>
  id: Scalars['ID']
  lastName: Maybe<Scalars['String']>
  linkedinUrl: Maybe<Scalars['String']>
  phoneNumber: Maybe<Scalars['String']>
  photoUrl: Maybe<Scalars['String']>
  title: RepresentativeTitle
  visibleInAboutUs: Scalars['Boolean']
  visibleInContacts: Scalars['Boolean']
}

export type RepresentativeOrder = {
  positionInAboutUs?: InputMaybe<OrderDirection>
  positionInContacts?: InputMaybe<OrderDirection>
}

export enum RepresentativeTitle {
  AccountManagerFemale = 'account_manager_female',
  AccountManagerMale = 'account_manager_male',
  CeoFemale = 'ceo_female',
  CeoMale = 'ceo_male',
  CfoFemale = 'cfo_female',
  CfoMale = 'cfo_male',
  CpoFemale = 'cpo_female',
  CpoMale = 'cpo_male',
  CsoFemale = 'cso_female',
  CsoMale = 'cso_male',
  CtoFemale = 'cto_female',
  CtoMale = 'cto_male',
  CustomerServiceManagerFemale = 'customer_service_manager_female',
  CustomerServiceManagerMale = 'customer_service_manager_male',
  DevelopmentDirectorFemale = 'development_director_female',
  DevelopmentDirectorMale = 'development_director_male',
  FinlandCcoFemale = 'finland_cco_female',
  FinlandCcoMale = 'finland_cco_male',
  FinlandGrowthDirectorFemale = 'finland_growth_director_female',
  FinlandGrowthDirectorMale = 'finland_growth_director_male',
  ForestManagementConsultantFemale = 'forest_management_consultant_female',
  ForestManagementConsultantMale = 'forest_management_consultant_male',
  ForestManagementExpertFemale = 'forest_management_expert_female',
  ForestManagementExpertMale = 'forest_management_expert_male',
  ForestryManagerFemale = 'forestry_manager_female',
  ForestryManagerMale = 'forestry_manager_male',
  ForestrySalesSpecialistFemale = 'forestry_sales_specialist_female',
  ForestrySalesSpecialistMale = 'forestry_sales_specialist_male',
  HeadOfPartnershipsFemale = 'head_of_partnerships_female',
  HeadOfPartnershipsMale = 'head_of_partnerships_male',
  LeadProductDesignerFemale = 'lead_product_designer_female',
  LeadProductDesignerMale = 'lead_product_designer_male',
  OfficeAdministratorFemale = 'office_administrator_female',
  OfficeAdministratorMale = 'office_administrator_male',
  PoFemale = 'po_female',
  PoMale = 'po_male',
  SeniorSoftwareEngineerFemale = 'senior_software_engineer_female',
  SeniorSoftwareEngineerMale = 'senior_software_engineer_male',
  SoftwareEngineerFemale = 'software_engineer_female',
  SoftwareEngineerMale = 'software_engineer_male',
}

/** Error type of RequestAuctionPermission */
export type RequestAuctionPermissionError = {
  __typename: 'RequestAuctionPermissionError'
  message: Maybe<BaseErrorMessage>
}

/** Autogenerated input type of RequestAuctionPermission */
export type RequestAuctionPermissionInput = {
  auctionId?: InputMaybe<Scalars['ID']>
  cadastralPlotId?: InputMaybe<Scalars['ID']>
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  intent: AuctionPermissionIntent
}

/** Autogenerated return type of RequestAuctionPermission */
export type RequestAuctionPermissionPayload = {
  __typename: 'RequestAuctionPermissionPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>
  error: Maybe<RequestAuctionPermissionError>
  user: User
}

export enum RoughPriceCalculationIntent {
  IncomeWithoutSelling = 'income_without_selling',
  WantConsultation = 'want_consultation',
  WantToSell = 'want_to_sell',
}

export type RoughPriceInquiry = {
  __typename: 'RoughPriceInquiry'
  id: Scalars['ID']
  maxPrice: MoneyObject
  minPrice: MoneyObject
}

/** Error type of SendPasswordReset */
export type SendPasswordResetError = {
  __typename: 'SendPasswordResetError'
  message: Maybe<BaseErrorMessage>
}

/** Autogenerated input type of SendPasswordReset */
export type SendPasswordResetInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  email: Scalars['EmailScalar']
}

/** Autogenerated return type of SendPasswordReset */
export type SendPasswordResetPayload = {
  __typename: 'SendPasswordResetPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>
  error: Maybe<SendPasswordResetError>
}

/** Service */
export type Service = {
  __typename: 'Service'
  description: Scalars['String']
  id: Scalars['ID']
  status: ServiceStatus
  title: Scalars['String']
}

/** The connection type for Service. */
export type ServiceConnection = {
  __typename: 'ServiceConnection'
  /** A list of edges. */
  edges: Array<ServiceEdge>
  /** A list of nodes. */
  nodes: Array<Service>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

/** An edge in a connection. */
export type ServiceEdge = {
  __typename: 'ServiceEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']
  /** The item at the end of the edge. */
  node: Maybe<Service>
}

export enum ServiceStatus {
  Done = 'done',
  InProgress = 'in_progress',
  Offered = 'offered',
  Ordered = 'ordered',
}

/** Error type of SignInWithEmail */
export type SignInWithEmailError = {
  __typename: 'SignInWithEmailError'
  message: Maybe<BaseErrorMessage>
  password: Maybe<Array<SignInWithEmailPasswordError>>
}

/** Autogenerated input type of SignInWithEmail */
export type SignInWithEmailInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  email: Scalars['EmailScalar']
  password: Scalars['StrippedStringScalar']
}

export enum SignInWithEmailPasswordError {
  PasswordInvalid = 'password_invalid',
}

/** Autogenerated return type of SignInWithEmail */
export type SignInWithEmailPayload = {
  __typename: 'SignInWithEmailPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>
  error: Maybe<SignInWithEmailError>
  token: Maybe<Scalars['String']>
}

export enum SignInWithOpenIdConnectAuthorizationCodeError {
  Invalid = 'invalid',
}

/** Error type of SignInWithOpenIDConnect */
export type SignInWithOpenIdConnectError = {
  __typename: 'SignInWithOpenIDConnectError'
  authorizationCode: Maybe<Array<SignInWithOpenIdConnectAuthorizationCodeError>>
  message: Maybe<BaseErrorMessage>
}

/** Autogenerated input type of SignInWithOpenIDConnect */
export type SignInWithOpenIdConnectInput = {
  authorizationCode: Scalars['String']
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  email: Scalars['EmailScalar']
}

/** Autogenerated return type of SignInWithOpenIDConnect */
export type SignInWithOpenIdConnectPayload = {
  __typename: 'SignInWithOpenIDConnectPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>
  error: Maybe<SignInWithOpenIdConnectError>
  token: Maybe<Scalars['String']>
}

export enum SignUpType {
  Authenticated = 'authenticated',
  Lead = 'lead',
}

export enum SignUpWithEmailEmailError {
  EmailInvalid = 'email_invalid',
  FormatInvalid = 'format_invalid',
  Taken = 'taken',
}

/** Error type of SignUpWithEmail */
export type SignUpWithEmailError = {
  __typename: 'SignUpWithEmailError'
  email: Maybe<Array<SignUpWithEmailEmailError>>
  message: Maybe<BaseErrorMessage>
  password: Maybe<Array<SignUpWithEmailPasswordError>>
  phoneNumber: Maybe<Array<SignUpWithEmailPhoneNumberError>>
  termsAndConditionsAccepted: Maybe<Array<SignUpWithEmailTermsAndConditionsAcceptedError>>
}

/** Autogenerated input type of SignUpWithEmail */
export type SignUpWithEmailInput = {
  cadastralNumber?: InputMaybe<Scalars['StrippedStringScalar']>
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  email: Scalars['EmailScalar']
  marketingConditionsAccepted: Scalars['Boolean']
  password: Scalars['StrippedStringScalar']
  phoneNumber?: InputMaybe<Scalars['StrippedStringScalar']>
  recaptchaToken?: InputMaybe<Scalars['String']>
  termsAndConditionsAccepted: Scalars['Boolean']
}

export enum SignUpWithEmailPasswordError {
  LengthInvalid = 'length_invalid',
}

/** Autogenerated return type of SignUpWithEmail */
export type SignUpWithEmailPayload = {
  __typename: 'SignUpWithEmailPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>
  error: Maybe<SignUpWithEmailError>
  token: Maybe<Scalars['String']>
}

export enum SignUpWithEmailPhoneNumberError {
  FormatInvalid = 'format_invalid',
}

export enum SignUpWithEmailTermsAndConditionsAcceptedError {
  MustBeTrue = 'must_be_true',
}

export type StartAuctionInquiry = {
  __typename: 'StartAuctionInquiry'
  id: Scalars['ID']
}

export enum StartAuctionInquiryEmailError {
  EmailInvalid = 'email_invalid',
  FormatInvalid = 'format_invalid',
  Taken = 'taken',
}

/** Error type of StartAuctionInquiry */
export type StartAuctionInquiryError = {
  __typename: 'StartAuctionInquiryError'
  email: Maybe<Array<StartAuctionInquiryEmailError>>
  message: Maybe<BaseErrorMessage>
  phoneNumber: Maybe<Array<StartAuctionInquiryPhoneNumberError>>
}

/** Autogenerated input type of StartAuctionInquiry */
export type StartAuctionInquiryInput = {
  cadastralNumber?: InputMaybe<Scalars['StrippedStringScalar']>
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  email: Scalars['EmailScalar']
  intent: StartAuctionIntent
  phoneNumber: Scalars['StrippedStringScalar']
}

/** Autogenerated return type of StartAuctionInquiry */
export type StartAuctionInquiryPayload = {
  __typename: 'StartAuctionInquiryPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>
  error: Maybe<StartAuctionInquiryError>
  startAuctionInquiry: Maybe<StartAuctionInquiry>
}

export enum StartAuctionInquiryPhoneNumberError {
  FormatInvalid = 'format_invalid',
}

export enum StartAuctionIntent {
  ForCutting = 'for_cutting',
  WithLand = 'with_land',
}

export type Subscribtion = {
  __typename: 'Subscribtion'
  auctionChanged: Auction
  cadastralPlotChanged: CadastralPlot
}

export type SubscribtionAuctionChangedArgs = {
  id: Scalars['ID']
}

export type SubscribtionCadastralPlotChangedArgs = {
  id: Scalars['ID']
}

/** TaxonomicPlot */
export type TaxonomicPlot = TaxonomicPlotInterface & {
  __typename: 'TaxonomicPlot'
  age: Maybe<Scalars['Int']>
  area: Maybe<Scalars['Float']>
  boundary: Maybe<Scalars['String']>
  crownDensity: Maybe<Scalars['Float']>
  forestGroups: Maybe<Array<ForestGroupTitle>>
  forestHabitatPresence: Maybe<Scalars['Boolean']>
  growingPlace: Maybe<Scalars['String']>
  id: Scalars['ID']
  intersectionBoundary: Maybe<Scalars['String']>
  /** @deprecated changed to standNumber */
  mapIdentifier: Scalars['String']
  marketValue: Maybe<MoneyObject>
  speciesComposition: Maybe<Scalars['String']>
  standNumber: Scalars['String']
  totalValue: Maybe<MoneyObject>
  volume: Maybe<Scalars['Float']>
  volumePerHa: Maybe<Scalars['Float']>
}

/** TaxonomicPlot */
export type TaxonomicPlotAreaArgs = {
  cadastralPlotId?: InputMaybe<Scalars['ID']>
}

/** TaxonomicPlot */
export type TaxonomicPlotForestGroupsArgs = {
  cadastralPlotId?: InputMaybe<Scalars['ID']>
}

/** TaxonomicPlot */
export type TaxonomicPlotForestHabitatPresenceArgs = {
  cadastralPlotId?: InputMaybe<Scalars['ID']>
}

/** TaxonomicPlot */
export type TaxonomicPlotMarketValueArgs = {
  cadastralPlotId?: InputMaybe<Scalars['ID']>
}

/** TaxonomicPlot */
export type TaxonomicPlotTotalValueArgs = {
  cadastralPlotId?: InputMaybe<Scalars['ID']>
}

/** TaxonomicPlot */
export type TaxonomicPlotVolumeArgs = {
  cadastralPlotId?: InputMaybe<Scalars['ID']>
}

/** A shared taxonomic plot interface */
export type TaxonomicPlotInterface = {
  area: Maybe<Scalars['Float']>
  boundary: Maybe<Scalars['String']>
  id: Scalars['ID']
  standNumber: Scalars['String']
}

/** A shared taxonomic plot interface */
export type TaxonomicPlotInterfaceAreaArgs = {
  cadastralPlotId?: InputMaybe<Scalars['ID']>
}

export enum TaxonomicPlotMaturityCondition {
  /** matured or overmatured forest */
  Mature = 'mature',
  /** a forest on its way to becoming mature */
  Maturing = 'maturing',
  Mixed = 'mixed',
  /** very young forest */
  Young = 'young',
}

/** Error type of UnfollowAuction */
export type UnfollowAuctionError = {
  __typename: 'UnfollowAuctionError'
  message: Maybe<BaseErrorMessage>
}

/** Autogenerated input type of UnfollowAuction */
export type UnfollowAuctionInput = {
  auction: AuctionType
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
}

/** Autogenerated return type of UnfollowAuction */
export type UnfollowAuctionPayload = {
  __typename: 'UnfollowAuctionPayload'
  auction: Maybe<Auction>
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>
  error: Maybe<UnfollowAuctionError>
}

/** Error type of UpdateCompanyDetails */
export type UpdateCompanyDetailsError = {
  __typename: 'UpdateCompanyDetailsError'
  message: Maybe<BaseErrorMessage>
}

/** Autogenerated input type of UpdateCompanyDetails */
export type UpdateCompanyDetailsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  companyName?: InputMaybe<Scalars['StrippedStringScalar']>
  countryCode?: InputMaybe<Scalars['StrippedStringScalar']>
  firstName?: InputMaybe<Scalars['StrippedStringScalar']>
  id?: InputMaybe<Scalars['ID']>
  lastName?: InputMaybe<Scalars['StrippedStringScalar']>
  legalCode?: InputMaybe<Scalars['StrippedStringScalar']>
  streetAddress?: InputMaybe<Scalars['StrippedStringScalar']>
  vatCode?: InputMaybe<Scalars['StrippedStringScalar']>
}

/** Autogenerated return type of UpdateCompanyDetails */
export type UpdateCompanyDetailsPayload = {
  __typename: 'UpdateCompanyDetailsPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>
  company: Maybe<Company>
  error: Maybe<UpdateCompanyDetailsError>
}

/** Error type of UpdateForgottenPassword */
export type UpdateForgottenPasswordError = {
  __typename: 'UpdateForgottenPasswordError'
  message: Maybe<BaseErrorMessage>
  password: Maybe<Array<UpdateForgottenPasswordPasswordError>>
  passwordConfirmation: Maybe<Array<UpdateForgottenPasswordPasswordConfirmationError>>
  passwordResetToken: Maybe<Array<UpdateForgottenPasswordPasswordResetTokenError>>
}

/** Autogenerated input type of UpdateForgottenPassword */
export type UpdateForgottenPasswordInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  password: Scalars['String']
  passwordConfirmation: Scalars['String']
  passwordResetToken: Scalars['String']
}

export enum UpdateForgottenPasswordPasswordConfirmationError {
  DoesNotMatch = 'does_not_match',
}

export enum UpdateForgottenPasswordPasswordError {
  LengthInvalid = 'length_invalid',
}

export enum UpdateForgottenPasswordPasswordResetTokenError {
  Invalid = 'invalid',
}

/** Autogenerated return type of UpdateForgottenPassword */
export type UpdateForgottenPasswordPayload = {
  __typename: 'UpdateForgottenPasswordPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>
  error: Maybe<UpdateForgottenPasswordError>
  token: Maybe<Scalars['String']>
}

/** Error type of UpdateNotificationSettings */
export type UpdateNotificationSettingsError = {
  __typename: 'UpdateNotificationSettingsError'
  message: Maybe<BaseErrorMessage>
}

/** Autogenerated input type of UpdateNotificationSettings */
export type UpdateNotificationSettingsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  notificationSettings: UserNotificationSettingsInput
}

/** Autogenerated return type of UpdateNotificationSettings */
export type UpdateNotificationSettingsPayload = {
  __typename: 'UpdateNotificationSettingsPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>
  error: Maybe<UpdateNotificationSettingsError>
  notificationSettings: Maybe<UserNotificationSettings>
}

/** Error type of UpdatePersonDetails */
export type UpdatePersonDetailsError = {
  __typename: 'UpdatePersonDetailsError'
  message: Maybe<BaseErrorMessage>
  password: Maybe<Array<UpdatePersonDetailsPasswordError>>
  passwordConfirmation: Maybe<Array<UpdatePersonDetailsPasswordConfirmationError>>
  phoneNumber: Maybe<Array<UpdatePersonDetailsPhoneNumberError>>
}

/** Autogenerated input type of UpdatePersonDetails */
export type UpdatePersonDetailsInput = {
  address?: InputMaybe<Scalars['StrippedStringScalar']>
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  countryCode?: InputMaybe<CountryCode>
  dateOfBirth?: InputMaybe<Scalars['StrippedStringScalar']>
  firstName?: InputMaybe<Scalars['StrippedStringScalar']>
  lastName?: InputMaybe<Scalars['StrippedStringScalar']>
  password?: InputMaybe<Scalars['String']>
  passwordConfirmation?: InputMaybe<Scalars['String']>
  phoneNumber?: InputMaybe<Scalars['StrippedStringScalar']>
}

export enum UpdatePersonDetailsPasswordConfirmationError {
  DoesNotMatch = 'does_not_match',
}

export enum UpdatePersonDetailsPasswordError {
  LengthInvalid = 'length_invalid',
}

/** Autogenerated return type of UpdatePersonDetails */
export type UpdatePersonDetailsPayload = {
  __typename: 'UpdatePersonDetailsPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>
  error: Maybe<UpdatePersonDetailsError>
  user: Maybe<User>
}

export enum UpdatePersonDetailsPhoneNumberError {
  FormatInvalid = 'format_invalid',
}

/** Error type of UpdateRoughPriceInquiryCadastralNumber */
export type UpdateRoughPriceInquiryCadastralNumberError = {
  __typename: 'UpdateRoughPriceInquiryCadastralNumberError'
  message: Maybe<BaseErrorMessage>
}

/** Autogenerated input type of UpdateRoughPriceInquiryCadastralNumber */
export type UpdateRoughPriceInquiryCadastralNumberInput = {
  cadastralNumber: Scalars['StrippedStringScalar']
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
}

/** Autogenerated return type of UpdateRoughPriceInquiryCadastralNumber */
export type UpdateRoughPriceInquiryCadastralNumberPayload = {
  __typename: 'UpdateRoughPriceInquiryCadastralNumberPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>
  error: Maybe<UpdateRoughPriceInquiryCadastralNumberError>
  roughPriceInquiry: Maybe<RoughPriceInquiry>
}

export enum UpdateRoughPriceInquiryContactsEmailError {
  EmailInvalid = 'email_invalid',
  FormatInvalid = 'format_invalid',
  Taken = 'taken',
}

/** Error type of UpdateRoughPriceInquiryContacts */
export type UpdateRoughPriceInquiryContactsError = {
  __typename: 'UpdateRoughPriceInquiryContactsError'
  email: Maybe<Array<UpdateRoughPriceInquiryContactsEmailError>>
  message: Maybe<BaseErrorMessage>
  phoneNumber: Maybe<Array<UpdateRoughPriceInquiryContactsPhoneNumberError>>
}

/** Autogenerated input type of UpdateRoughPriceInquiryContacts */
export type UpdateRoughPriceInquiryContactsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  email: Scalars['EmailScalar']
  id: Scalars['ID']
  phoneNumber: Scalars['StrippedStringScalar']
}

/** Autogenerated return type of UpdateRoughPriceInquiryContacts */
export type UpdateRoughPriceInquiryContactsPayload = {
  __typename: 'UpdateRoughPriceInquiryContactsPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>
  error: Maybe<UpdateRoughPriceInquiryContactsError>
  roughPriceInquiry: Maybe<RoughPriceInquiry>
}

export enum UpdateRoughPriceInquiryContactsPhoneNumberError {
  FormatInvalid = 'format_invalid',
}

/** User */
export type User = Person & {
  __typename: 'User'
  address: Maybe<Scalars['String']>
  auctionAgreementSent: Maybe<Scalars['Boolean']>
  biddingAgreementSent: Maybe<Scalars['Boolean']>
  cadastralPlots: CadastralPlotConnection
  canDownloadForestBook: Maybe<Scalars['Boolean']>
  company: Maybe<Company>
  countryCode: Maybe<CountryCode>
  dateOfBirth: Maybe<Scalars['ISO8601Date']>
  email: Maybe<Scalars['String']>
  firstName: Maybe<Scalars['String']>
  id: Scalars['ID']
  isAllowedToBid: Maybe<Scalars['Boolean']>
  isAllowedToOrderService: Scalars['Boolean']
  isAllowedToStartAnAuction: Scalars['Boolean']
  isEmployee: Maybe<Scalars['Boolean']>
  isManager: Maybe<Scalars['Boolean']>
  lastName: Maybe<Scalars['String']>
  missingData: Maybe<Scalars['Boolean']>
  notificationSettings: Maybe<UserNotificationSettings>
  phoneNumber: Maybe<Scalars['String']>
  photoUrl: Maybe<Scalars['String']>
  remainingForestMoney: Maybe<MoneyObject>
}

/** User */
export type UserCadastralPlotsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

/** UserNotificationSettings */
export type UserNotificationSettings = {
  __typename: 'UserNotificationSettings'
  followedAuctionBidsBrowser: Scalars['Boolean']
  followedAuctionBidsEmail: Scalars['Boolean']
  followedAuctionCloseEmail: Scalars['Boolean']
  id: Scalars['ID']
  newsEmail: Scalars['Boolean']
  overbidEmail: Scalars['Boolean']
  overbidSms: Scalars['Boolean']
  yourBidsEmail: Scalars['Boolean']
}

export type UserNotificationSettingsInput = {
  followedAuctionBidsBrowser?: InputMaybe<Scalars['Boolean']>
  followedAuctionBidsEmail?: InputMaybe<Scalars['Boolean']>
  followedAuctionCloseEmail?: InputMaybe<Scalars['Boolean']>
  newsEmail?: InputMaybe<Scalars['Boolean']>
  overbidEmail?: InputMaybe<Scalars['Boolean']>
  overbidSms?: InputMaybe<Scalars['Boolean']>
  yourBidsEmail?: InputMaybe<Scalars['Boolean']>
}

export type BaseAuctionFragment = {
  __typename: 'Auction'
  id: string
  humanId: string
  title: string
  endTime: string
  address: string
  forestInspected: boolean
  startingBid: { __typename: 'MoneyObject' } & MoneyFragment
  highestBidObject: ({ __typename: 'Bid' } & BaseBidFragment) | null
  cadastralPlots: Array<{ __typename: 'CadastralPlot' } & BaseCadastralPlotFragment>
}

export type ListAuctionFragment = {
  __typename: 'Auction'
  followed: boolean
  forestType: ForestType | null
  salePurpose: string
  owner: { __typename: 'User'; id: string } | null
} & BaseAuctionFragment

export type MapAuctionFragment = {
  __typename: 'Auction'
  id: string
  startingBid: { __typename: 'MoneyObject' } & MoneyFragment
  highestBidObject: ({ __typename: 'Bid' } & BaseBidFragment) | null
  cadastralPlots: Array<{ __typename: 'CadastralPlot' } & MapCadastralPlotFragment>
}

export type BidAuctionFragment = {
  __typename: 'Auction'
  id: string
  bidIncrement: { __typename: 'MoneyObject' } & MoneyFragment
  nextMinimumAutoBidPrice: { __typename: 'MoneyObject' } & MoneyFragment
  nextMinimumBidPrice: { __typename: 'MoneyObject' } & MoneyFragment
}

export type FullAuctionFragment = {
  __typename: 'Auction'
  description: string
  auctionTaxRate: number
  bidCount: number
  followerCount: number
  minTax: { __typename: 'MoneyObject' } & MoneyFragment
  currentUserAutoBid: ({ __typename: 'AutoBid' } & AutoBidFragment) | null
  bids: { __typename: 'BidConnection'; nodes: Array<{ __typename: 'Bid' } & BidFragment> }
  manager: ({ __typename: 'Representative' } & RepresentativeFragment) | null
  cadastralPlots: Array<{ __typename: 'CadastralPlot' } & FullCadastralPlotFragment>
} & ListAuctionFragment &
  BidAuctionFragment

export type ParticipatingAuctionFragment = {
  __typename: 'Auction'
  bids: { __typename: 'BidConnection'; nodes: Array<{ __typename: 'Bid' } & BidFragment> }
} & BaseAuctionFragment &
  BidAuctionFragment

export type FollowedAuctionFragment = { __typename: 'Auction'; id: string; followed: boolean }

export type OwnedAuctionFragment = {
  __typename: 'Auction'
  bids: { __typename: 'BidConnection'; nodes: Array<{ __typename: 'Bid' } & BidFragment> }
} & BaseAuctionFragment

export type BaseBidFragment = {
  __typename: 'Bid'
  id: string
  price: { __typename: 'MoneyObject' } & MoneyFragment
}

export type BidFragment = {
  __typename: 'Bid'
  id: string
  bidderIndex: string
  createdAt: string
  bidder: { __typename: 'User'; id: string }
  price: { __typename: 'MoneyObject' } & MoneyFragment
  autoBid: ({ __typename: 'AutoBid' } & AutoBidFragment) | null
}

export type AutoBidFragment = {
  __typename: 'AutoBid'
  id: string
  active: boolean
  price: { __typename: 'MoneyObject' } & MoneyFragment
}

export type BaseCadastralPlotFragment = {
  __typename: 'CadastralPlot'
  id: string
  title: string
  humanId: string
  boundary: string | null
  latitude: number | null
  longitude: number | null
  area: number | null
}

export type MapCadastralPlotFragment = {
  __typename: 'CadastralPlot'
  id: string
  boundary: string | null
  latitude: number | null
  longitude: number | null
}

export type PopupCadastralPlotFragment = {
  __typename: 'CadastralPlot'
  id: string
  address: string
  area: number | null
  cadastralNumber: string
  forestUtilizationPurpose: ForestUtilizationPurpose | null
  volume: number
  boundary: string | null
  latitude: number | null
  longitude: number | null
  oldestMainLayerAge: number | null
  auctions: Array<{
    __typename: 'Auction'
    id: string
    title: string
    endTime: string
    startingBid: { __typename: 'MoneyObject' } & MoneyFragment
    highestBidObject: ({ __typename: 'Bid' } & BaseBidFragment) | null
  }>
}

export type ModalCadastralPlotFragment = {
  __typename: 'CadastralPlot'
  forestArea: number | null
  forestType: ForestType | null
  totalValue: ({ __typename: 'MoneyObject' } & MoneyFragment) | null
  marketValue: ({ __typename: 'MoneyObject' } & MoneyFragment) | null
} & PopupCadastralPlotFragment

export type OwnedCadastralPlotFragment = {
  __typename: 'CadastralPlot'
  address: string
  maturity: TaxonomicPlotMaturityCondition | null
  forestType: ForestType | null
  cadastralNumber: string
  forestUtilizationPurpose: ForestUtilizationPurpose | null
  forestGroups: Array<ForestGroupTitle>
  shouldWaitForUpdates: boolean | null
  marketValue: ({ __typename: 'MoneyObject' } & MoneyFragment) | null
  totalValue: ({ __typename: 'MoneyObject' } & MoneyFragment) | null
  services: {
    __typename: 'ServiceConnection'
    nodes: Array<{ __typename: 'Service' } & ServiceFragment>
  }
} & BaseCadastralPlotFragment

export type FullCadastralPlotFragment = {
  __typename: 'CadastralPlot'
  managementPlan: CadastralPlotManagementPlan
  carbonAbsorptionPerYear: number
  inspected: boolean
  manager: ({ __typename: 'Representative' } & RepresentativeFragment) | null
  images: Array<{ __typename: 'ImageObject' } & ImageFragment>
  files: Array<{ __typename: 'FileObject' } & FileFragment>
  owners: Array<{ __typename: 'User' } & BaseUserFragment>
} & MapCadastralPlotFragment &
  OwnedCadastralPlotFragment

export type CadastralPlotSumsFragment = {
  __typename: 'CadastralPlotSums'
  area: number
  volume: number
  marketValue: { __typename: 'MoneyObject' } & MoneyFragment
  totalValue: { __typename: 'MoneyObject' } & MoneyFragment
}

export type ServiceFragment = {
  __typename: 'Service'
  id: string
  description: string
  status: ServiceStatus
  title: string
}

export type CompanyFragment = {
  __typename: 'Company'
  id: string
  companyName: string
  legalCode: string
  vatCode: string | null
  firstName: string
  lastName: string
  streetAddress: string | null
}

export type BaseContentPageFragment = {
  __typename: 'ContentPage'
  id: string
  link: string
  title: string
  createdAt: string
  image: ({ __typename: 'ImageObject' } & ImageFragment) | null
}

export type FullContentPageFragment = {
  __typename: 'ContentPage'
  content: string
  description: string
  styleUrl: string
} & BaseContentPageFragment

export type RepresentativeCountryFragment = {
  __typename: 'CountryRepresentatives'
  countryCode: string
  flagEmoji: string
  representatives: Array<{ __typename: 'Representative' } & RepresentativeFragment>
}

export type CountryFragment = { __typename: 'Country'; isoCode: string; name: string }

export type EmployeeCountryFragment = {
  __typename: 'CountryRepresentatives'
  representatives: Array<{ __typename: 'Representative' } & EmployeeFragment>
}

export type FileFragment = { __typename: 'FileObject'; filename: string; id: string; url: string }

export type ImageFragment = {
  __typename: 'ImageObject'
  filename: string
  id: string
  originalUrl: string
  thumbUrl: string
  width: number
  height: number
}

export type ForestGroupFragment = { __typename: 'ForestGroup'; id: string; title: ForestGroupTitle }

export type ForestHabitatFragment = {
  __typename: 'ForestHabitat'
  id: string
  area: number | null
  kind: ForestHabitatKind
  category: ForestHabitatCategory | null
  codes: Array<string>
}

export type RoughPriceInquiryFragment = {
  __typename: 'RoughPriceInquiry'
  id: string
  maxPrice: { __typename: 'MoneyObject' } & MoneyFragment
  minPrice: { __typename: 'MoneyObject' } & MoneyFragment
}

export type MoneyFragment = {
  __typename: 'MoneyObject'
  amount: string
  currency: { __typename: 'Currency'; isoCode: CurrencyCode }
}

export type PageInfoFragment = {
  __typename: 'PageInfo'
  endCursor: string | null
  hasNextPage: boolean
  hasPreviousPage: boolean
  startCursor: string | null
}

export type RepresentativeFragment = {
  __typename: 'Representative'
  id: string
  email: string | null
  firstName: string | null
  lastName: string | null
  phoneNumber: string | null
  photoUrl: string | null
  title: RepresentativeTitle
}

export type EmployeeFragment = {
  __typename: 'Representative'
  id: string
  firstName: string | null
  lastName: string | null
  photoUrl: string | null
  title: RepresentativeTitle
  description: string | null
  linkedinUrl: string | null
}

export type UserNotificationSettingsFragment = {
  __typename: 'UserNotificationSettings'
  followedAuctionBidsBrowser: boolean
  followedAuctionBidsEmail: boolean
  followedAuctionCloseEmail: boolean
  newsEmail: boolean
  overbidEmail: boolean
  overbidSms: boolean
  yourBidsEmail: boolean
  id: string
}

export type PopupTaxonomicPlotFragment = {
  __typename: 'TaxonomicPlot'
  id: string
  mapIdentifier: string
  area: number | null
  speciesComposition: string | null
  age: number | null
}

export type ModalTaxonomicPlotFragment = {
  __typename: 'TaxonomicPlot'
  crownDensity: number | null
  forestGroups: Array<ForestGroupTitle> | null
  growingPlace: string | null
  volume: number | null
  volumePerHa: number | null
  forestHabitatPresence: boolean | null
  marketValue: ({ __typename: 'MoneyObject' } & MoneyFragment) | null
  totalValue: ({ __typename: 'MoneyObject' } & MoneyFragment) | null
} & PopupTaxonomicPlotFragment

export type BaseUserFragment = {
  __typename: 'User'
  id: string
  email: string | null
  firstName: string | null
  lastName: string | null
  isEmployee: boolean | null
}

export type PlatformUserFragment = {
  __typename: 'User'
  isAllowedToBid: boolean | null
  isAllowedToStartAnAuction: boolean
  isAllowedToOrderService: boolean
  biddingAgreementSent: boolean | null
  auctionAgreementSent: boolean | null
  canDownloadForestBook: boolean | null
} & BaseUserFragment

export type FullUserFragment = {
  __typename: 'User'
  phoneNumber: string | null
  photoUrl: string | null
  dateOfBirth: string | null
  address: string | null
  countryCode: CountryCode | null
  company: ({ __typename: 'Company' } & CompanyFragment) | null
} & PlatformUserFragment

export type LeadUserFragment = { __typename: 'User'; id: string; email: string | null }

export type CreateAuctionMutationVariables = Exact<{
  input: CreateAuctionInput
}>

export type CreateAuctionMutation = {
  __typename: 'Mutation'
  createAuction: {
    __typename: 'CreateAuctionPayload'
    error: { __typename: 'CreateAuctionError'; message: BaseErrorMessage | null } | null
  } | null
}

export type RequestAuctionPermissionMutationVariables = Exact<{
  input: RequestAuctionPermissionInput
}>

export type RequestAuctionPermissionMutation = {
  __typename: 'Mutation'
  requestAuctionPermission: {
    __typename: 'RequestAuctionPermissionPayload'
    error: { __typename: 'RequestAuctionPermissionError'; message: BaseErrorMessage | null } | null
    user: { __typename: 'User' } & PlatformUserFragment
  } | null
}

export type FollowAuctionMutationVariables = Exact<{
  input: FollowAuctionInput
}>

export type FollowAuctionMutation = {
  __typename: 'Mutation'
  followAuction: {
    __typename: 'FollowAuctionPayload'
    error: { __typename: 'FollowAuctionError'; message: BaseErrorMessage | null } | null
    auction: ({ __typename: 'Auction' } & FollowedAuctionFragment) | null
  } | null
}

export type UnfollowAuctionMutationVariables = Exact<{
  input: UnfollowAuctionInput
}>

export type UnfollowAuctionMutation = {
  __typename: 'Mutation'
  unfollowAuction: {
    __typename: 'UnfollowAuctionPayload'
    error: { __typename: 'UnfollowAuctionError'; message: BaseErrorMessage | null } | null
    auction: ({ __typename: 'Auction' } & FollowedAuctionFragment) | null
  } | null
}

export type SignInMutationVariables = Exact<{
  input: SignInWithEmailInput
}>

export type SignInMutation = {
  __typename: 'Mutation'
  signInWithEmail: {
    __typename: 'SignInWithEmailPayload'
    token: string | null
    error: {
      __typename: 'SignInWithEmailError'
      message: BaseErrorMessage | null
      password: Array<SignInWithEmailPasswordError> | null
    } | null
  } | null
}

export type CreateUserMutationVariables = Exact<{
  input: CreateUserInput
}>

export type CreateUserMutation = {
  __typename: 'Mutation'
  createUser: {
    __typename: 'CreateUserPayload'
    token: string | null
    error: {
      __typename: 'CreateUserError'
      password: Array<CreateUserPasswordError> | null
      phoneNumber: Array<CreateUserPhoneNumberError> | null
    } | null
  } | null
}

export type CreateLeadUserMutationVariables = Exact<{
  input: CreateLeadUserInput
}>

export type CreateLeadUserMutation = {
  __typename: 'Mutation'
  createLeadUser: {
    __typename: 'CreateLeadUserPayload'
    error: {
      __typename: 'CreateLeadUserError'
      email: Array<CreateLeadUserEmailError> | null
      termsAndConditionsAccepted: Array<CreateLeadUserTermsAndConditionsAcceptedError> | null
    } | null
    leadUser: ({ __typename: 'User' } & LeadUserFragment) | null
  } | null
}

export type ForgotPasswordMutationVariables = Exact<{
  input: SendPasswordResetInput
}>

export type ForgotPasswordMutation = {
  __typename: 'Mutation'
  sendPasswordReset: {
    __typename: 'SendPasswordResetPayload'
    error: { __typename: 'SendPasswordResetError'; message: BaseErrorMessage | null } | null
  } | null
}

export type UpdateForgottenPasswordMutationVariables = Exact<{
  input: UpdateForgottenPasswordInput
}>

export type UpdateForgottenPasswordMutation = {
  __typename: 'Mutation'
  updateForgottenPassword: {
    __typename: 'UpdateForgottenPasswordPayload'
    token: string | null
    error: {
      __typename: 'UpdateForgottenPasswordError'
      message: BaseErrorMessage | null
      password: Array<UpdateForgottenPasswordPasswordError> | null
      passwordResetToken: Array<UpdateForgottenPasswordPasswordResetTokenError> | null
    } | null
  } | null
}

export type CreateOpenIdUserMutationVariables = Exact<{
  input: CreateOpenIdConnectUserInput
}>

export type CreateOpenIdUserMutation = {
  __typename: 'Mutation'
  createOpenIdConnectUser: {
    __typename: 'CreateOpenIDConnectUserPayload'
    token: string | null
    error: {
      __typename: 'CreateOpenIDConnectUserError'
      message: BaseErrorMessage | null
      leadUser: Array<CreateOpenIdConnectUserLeadUserError> | null
    } | null
  } | null
}

export type SignInOpenIdUserMutationVariables = Exact<{
  input: SignInWithOpenIdConnectInput
}>

export type SignInOpenIdUserMutation = {
  __typename: 'Mutation'
  signInWithOpenIdConnect: {
    __typename: 'SignInWithOpenIDConnectPayload'
    token: string | null
    error: {
      __typename: 'SignInWithOpenIDConnectError'
      message: BaseErrorMessage | null
      authorizationCode: Array<SignInWithOpenIdConnectAuthorizationCodeError> | null
    } | null
  } | null
}

export type CreateBidMutationVariables = Exact<{
  input: CreateBidInput
}>

export type CreateBidMutation = {
  __typename: 'Mutation'
  createBid: {
    __typename: 'CreateBidPayload'
    error: {
      __typename: 'CreateBidError'
      message: BaseErrorMessage | null
      price: Array<CreateBidPriceError> | null
    } | null
    bid: ({ __typename: 'Bid' } & BidFragment) | null
  } | null
}

export type CreateAutoBidMutationVariables = Exact<{
  input: CreateAutoBidInput
}>

export type CreateAutoBidMutation = {
  __typename: 'Mutation'
  createAutoBid: {
    __typename: 'CreateAutoBidPayload'
    error: {
      __typename: 'CreateAutoBidError'
      message: BaseErrorMessage | null
      price: Array<CreateAutoBidPriceError> | null
    } | null
    auction: {
      __typename: 'Auction'
      id: string
      currentUserAutoBid: ({ __typename: 'AutoBid' } & AutoBidFragment) | null
    } | null
  } | null
}

export type AddCadastralPlotsMutationVariables = Exact<{
  input: AssignCadastralPlotsInput
}>

export type AddCadastralPlotsMutation = {
  __typename: 'Mutation'
  assignCadastralPlots: {
    __typename: 'AssignCadastralPlotsPayload'
    error: {
      __typename: 'AssignCadastralPlotsError'
      message: BaseErrorMessage | null
      cadastralPlotsErrors: Array<{
        __typename: 'CadastralPlotsErrors'
        index: number
        messages: Array<CadastralPlotErrorEnum>
      }> | null
    } | null
    cadastralPlots: Array<{ __typename: 'CadastralPlot' } & BaseCadastralPlotFragment> | null
  } | null
}

export type OrderServiceMutationVariables = Exact<{
  input: OrderServiceInput
}>

export type OrderServiceMutation = {
  __typename: 'Mutation'
  orderService: {
    __typename: 'OrderServicePayload'
    error: {
      __typename: 'OrderServiceError'
      message: BaseErrorMessage | null
      status: Array<OrderServiceStatusError> | null
    } | null
    service: ({ __typename: 'Service' } & ServiceFragment) | null
  } | null
}

export type CreateRoughPriceInquiryMutationVariables = Exact<{
  input: CreateRoughPriceInquiryInput
}>

export type CreateRoughPriceInquiryMutation = {
  __typename: 'Mutation'
  createRoughPriceInquiry: {
    __typename: 'CreateRoughPriceInquiryPayload'
    error: {
      __typename: 'CreateRoughPriceInquiryError'
      message: BaseErrorMessage | null
      intents: Array<CreateRoughPriceInquiryIntentsError> | null
    } | null
    roughPriceInquiry: ({ __typename: 'RoughPriceInquiry' } & RoughPriceInquiryFragment) | null
  } | null
}

export type UpdateRoughPriceInquiryContactsMutationVariables = Exact<{
  input: UpdateRoughPriceInquiryContactsInput
}>

export type UpdateRoughPriceInquiryContactsMutation = {
  __typename: 'Mutation'
  updateRoughPriceInquiryContacts: {
    __typename: 'UpdateRoughPriceInquiryContactsPayload'
    error: {
      __typename: 'UpdateRoughPriceInquiryContactsError'
      message: BaseErrorMessage | null
      email: Array<UpdateRoughPriceInquiryContactsEmailError> | null
      phoneNumber: Array<UpdateRoughPriceInquiryContactsPhoneNumberError> | null
    } | null
  } | null
}

export type UpdateRoughPriceInquiryCadastralNumberMutationVariables = Exact<{
  input: UpdateRoughPriceInquiryCadastralNumberInput
}>

export type UpdateRoughPriceInquiryCadastralNumberMutation = {
  __typename: 'Mutation'
  updateRoughPriceInquiryCadastralNumber: {
    __typename: 'UpdateRoughPriceInquiryCadastralNumberPayload'
    error: {
      __typename: 'UpdateRoughPriceInquiryCadastralNumberError'
      message: BaseErrorMessage | null
    } | null
  } | null
}

export type CreateStartAuctionInquiryMutationVariables = Exact<{
  input: StartAuctionInquiryInput
}>

export type CreateStartAuctionInquiryMutation = {
  __typename: 'Mutation'
  createStartAuctionInquiry: {
    __typename: 'StartAuctionInquiryPayload'
    error: {
      __typename: 'StartAuctionInquiryError'
      message: BaseErrorMessage | null
      email: Array<StartAuctionInquiryEmailError> | null
      phoneNumber: Array<StartAuctionInquiryPhoneNumberError> | null
    } | null
  } | null
}

export type CreateForestValueInquiryMutationVariables = Exact<{
  input: SignUpWithEmailInput
}>

export type CreateForestValueInquiryMutation = {
  __typename: 'Mutation'
  signUpWithEmail: {
    __typename: 'SignUpWithEmailPayload'
    token: string | null
    error: {
      __typename: 'SignUpWithEmailError'
      message: BaseErrorMessage | null
      email: Array<SignUpWithEmailEmailError> | null
      password: Array<SignUpWithEmailPasswordError> | null
      phoneNumber: Array<SignUpWithEmailPhoneNumberError> | null
      termsAndConditionsAccepted: Array<SignUpWithEmailTermsAndConditionsAcceptedError> | null
    } | null
  } | null
}

export type UpdatePersonDetailsMutationVariables = Exact<{
  input: UpdatePersonDetailsInput
}>

export type UpdatePersonDetailsMutation = {
  __typename: 'Mutation'
  updatePersonDetails: {
    __typename: 'UpdatePersonDetailsPayload'
    error: {
      __typename: 'UpdatePersonDetailsError'
      message: BaseErrorMessage | null
      password: Array<UpdatePersonDetailsPasswordError> | null
      passwordConfirmation: Array<UpdatePersonDetailsPasswordConfirmationError> | null
      phoneNumber: Array<UpdatePersonDetailsPhoneNumberError> | null
    } | null
    user: ({ __typename: 'User' } & FullUserFragment) | null
  } | null
}

export type UpdateCompanyMutationVariables = Exact<{
  input: UpdateCompanyDetailsInput
}>

export type UpdateCompanyMutation = {
  __typename: 'Mutation'
  updateCompanyDetails: {
    __typename: 'UpdateCompanyDetailsPayload'
    error: { __typename: 'UpdateCompanyDetailsError'; message: BaseErrorMessage | null } | null
  } | null
}

export type CreateCompanyMutationVariables = Exact<{
  input: CreateCompanyInput
}>

export type CreateCompanyMutation = {
  __typename: 'Mutation'
  createCompany: {
    __typename: 'CreateCompanyPayload'
    error: { __typename: 'CreateCompanyError'; message: BaseErrorMessage | null } | null
  } | null
}

export type UpdateNotificationSettingsMutationVariables = Exact<{
  input: UpdateNotificationSettingsInput
}>

export type UpdateNotificationSettingsMutation = {
  __typename: 'Mutation'
  updateNotificationSettings: {
    __typename: 'UpdateNotificationSettingsPayload'
    error: {
      __typename: 'UpdateNotificationSettingsError'
      message: BaseErrorMessage | null
    } | null
    notificationSettings:
      | ({ __typename: 'UserNotificationSettings' } & UserNotificationSettingsFragment)
      | null
  } | null
}

export type BaseMarketAuctionsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
}>

export type BaseMarketAuctionsQuery = {
  __typename: 'Query'
  marketAuctions: {
    __typename: 'AuctionConnection'
    pageInfo: { __typename: 'PageInfo' } & PageInfoFragment
    nodes: Array<{ __typename: 'Auction' } & BaseAuctionFragment>
  }
}

export type ListAuctionsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']>
  areaGteq?: InputMaybe<Scalars['Float']>
  areaLteq?: InputMaybe<Scalars['Float']>
  auctionOpen?: InputMaybe<Scalars['Boolean']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  forestTypeIn?: InputMaybe<Array<ForestType> | ForestType>
  last?: InputMaybe<Scalars['Int']>
  maturityIn?: InputMaybe<Array<TaxonomicPlotMaturityCondition> | TaxonomicPlotMaturityCondition>
  priceCentsGteq?: InputMaybe<Scalars['Int']>
  priceCentsLteq?: InputMaybe<Scalars['Int']>
  salePurposeIn?: InputMaybe<Array<AuctionSalePurpose> | AuctionSalePurpose>
  titleCont?: InputMaybe<Scalars['String']>
  volumeGteq?: InputMaybe<Scalars['Int']>
  volumeLteq?: InputMaybe<Scalars['Int']>
  order?: InputMaybe<AuctionOrder>
  countryCodeIn?: InputMaybe<Array<AuctionCountryCode> | AuctionCountryCode>
}>

export type ListAuctionsQuery = {
  __typename: 'Query'
  auctions: {
    __typename: 'AuctionConnection'
    edges: Array<{
      __typename: 'AuctionEdge'
      cursor: string
      node: ({ __typename: 'Auction' } & ListAuctionFragment) | null
    }>
    pageInfo: { __typename: 'PageInfo' } & PageInfoFragment
  }
  filteredAuctions: {
    __typename: 'AuctionConnection'
    nodes: Array<{ __typename: 'Auction' } & MapAuctionFragment>
  }
}

export type AuctionQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type AuctionQuery = {
  __typename: 'Query'
  auction: ({ __typename: 'Auction' } & FullAuctionFragment) | null
}

export type OpenIdAuthorizationUrlQueryVariables = Exact<{
  identityMethod: OpenIdConnectIdentityMethod
  state: Scalars['String']
}>

export type OpenIdAuthorizationUrlQuery = {
  __typename: 'Query'
  openIdConnectAuthorizationUrl: string
}

export type RegistrationTypeQueryVariables = Exact<{
  email: Scalars['EmailScalar']
}>

export type RegistrationTypeQuery = { __typename: 'Query'; userRegistrationType: SignUpType | null }

export type AuthenticationProvidersQueryVariables = Exact<{
  email: Scalars['EmailScalar']
}>

export type AuthenticationProvidersQuery = {
  __typename: 'Query'
  userAuthenticationProviders: Array<{
    __typename: 'AuthenticationProvider'
    id: string
    identityMethod: AuthenticationProviderIdentityMethod
  }>
}

export type BlogPostQueryVariables = Exact<{
  slug: Scalars['String']
}>

export type BlogPostQuery = {
  __typename: 'Query'
  contentPage: ({ __typename: 'ContentPage' } & FullContentPageFragment) | null
}

export type CadastralPlotQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type CadastralPlotQuery = {
  __typename: 'Query'
  cadastralPlot:
    | ({
        __typename: 'CadastralPlot'
        auctions: Array<{ __typename: 'Auction' } & BaseAuctionFragment>
      } & FullCadastralPlotFragment)
    | null
}

export type PopupCadastralPlotQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type PopupCadastralPlotQuery = {
  __typename: 'Query'
  cadastralPlot: ({ __typename: 'CadastralPlot' } & PopupCadastralPlotFragment) | null
}

export type ModalCadastralPlotQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type ModalCadastralPlotQuery = {
  __typename: 'Query'
  cadastralPlot: ({ __typename: 'CadastralPlot' } & ModalCadastralPlotFragment) | null
}

export type MarketAuctionsQueryVariables = Exact<{ [key: string]: never }>

export type MarketAuctionsQuery = {
  __typename: 'Query'
  marketAuctions: {
    __typename: 'AuctionConnection'
    nodes: Array<{ __typename: 'Auction' } & BaseAuctionFragment>
  }
}

export type FollowedAuctionsQueryVariables = Exact<{ [key: string]: never }>

export type FollowedAuctionsQuery = {
  __typename: 'Query'
  followedAuctions: {
    __typename: 'AuctionConnection'
    nodes: Array<{ __typename: 'Auction' } & BaseAuctionFragment>
  }
}

export type ForestsQueryVariables = Exact<{ [key: string]: never }>

export type ForestsQuery = {
  __typename: 'Query'
  currentUser: {
    __typename: 'User'
    id: string
    cadastralPlots: {
      __typename: 'CadastralPlotConnection'
      nodes: Array<{ __typename: 'CadastralPlot' } & OwnedCadastralPlotFragment>
      sums: { __typename: 'CadastralPlotSums' } & CadastralPlotSumsFragment
    }
  } | null
}

export type WonAuctionsQueryVariables = Exact<{ [key: string]: never }>

export type WonAuctionsQuery = {
  __typename: 'Query'
  wonAuctions: {
    __typename: 'AuctionConnection'
    nodes: Array<{ __typename: 'Auction' } & BaseAuctionFragment>
  }
}

export type ParticipatingAuctionsQueryVariables = Exact<{ [key: string]: never }>

export type ParticipatingAuctionsQuery = {
  __typename: 'Query'
  participatingAuctions: {
    __typename: 'AuctionConnection'
    nodes: Array<{ __typename: 'Auction' } & ParticipatingAuctionFragment>
  }
}

export type OwnedAuctionsQueryVariables = Exact<{ [key: string]: never }>

export type OwnedAuctionsQuery = {
  __typename: 'Query'
  ownedAuctions: {
    __typename: 'AuctionConnection'
    nodes: Array<{ __typename: 'Auction' } & OwnedAuctionFragment>
  }
}

export type ContactsQueryVariables = Exact<{ [key: string]: never }>

export type ContactsQuery = {
  __typename: 'Query'
  countryRepresentatives: Array<
    { __typename: 'CountryRepresentatives' } & RepresentativeCountryFragment
  >
}

export type EmployeesQueryVariables = Exact<{ [key: string]: never }>

export type EmployeesQuery = {
  __typename: 'Query'
  countryRepresentatives: Array<{ __typename: 'CountryRepresentatives' } & EmployeeCountryFragment>
}

export type ForestGroupQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type ForestGroupQuery = {
  __typename: 'Query'
  forestGroup: ({ __typename: 'ForestGroup' } & ForestGroupFragment) | null
}

export type ForestHabitatQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type ForestHabitatQuery = {
  __typename: 'Query'
  forestHabitat: ({ __typename: 'ForestHabitat' } & ForestHabitatFragment) | null
}

export type RoughPriceInquiryQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type RoughPriceInquiryQuery = {
  __typename: 'Query'
  roughPriceInquiry: ({ __typename: 'RoughPriceInquiry' } & RoughPriceInquiryFragment) | null
}

export type PressReleasesQueryVariables = Exact<{ [key: string]: never }>

export type PressReleasesQuery = {
  __typename: 'Query'
  contentPages: {
    __typename: 'ContentPageConnection'
    nodes: Array<{ __typename: 'ContentPage' } & BaseContentPageFragment>
  }
  pressMentions: {
    __typename: 'ContentPageConnection'
    nodes: Array<{ __typename: 'ContentPage' } & BaseContentPageFragment>
  }
}

export type SettingsQueryVariables = Exact<{ [key: string]: never }>

export type SettingsQuery = {
  __typename: 'Query'
  currentUser: ({ __typename: 'User' } & FullUserFragment) | null
}

export type NotificationSettingsQueryVariables = Exact<{ [key: string]: never }>

export type NotificationSettingsQuery = {
  __typename: 'Query'
  currentUser:
    | ({
        __typename: 'User'
        notificationSettings:
          | ({ __typename: 'UserNotificationSettings' } & UserNotificationSettingsFragment)
          | null
      } & BaseUserFragment)
    | null
}

export type CountriesQueryVariables = Exact<{ [key: string]: never }>

export type CountriesQuery = {
  __typename: 'Query'
  countries: Array<{ __typename: 'Country' } & CountryFragment>
}

export type WalletQueryVariables = Exact<{ [key: string]: never }>

export type WalletQuery = {
  __typename: 'Query'
  currentUser: {
    __typename: 'User'
    id: string
    remainingForestMoney: ({ __typename: 'MoneyObject' } & MoneyFragment) | null
  } | null
}

export type PopupTaxonomicPlotQueryVariables = Exact<{
  id: Scalars['ID']
  cadastralPlotId?: InputMaybe<Scalars['ID']>
}>

export type PopupTaxonomicPlotQuery = {
  __typename: 'Query'
  taxonomicPlot: ({ __typename: 'TaxonomicPlot' } & PopupTaxonomicPlotFragment) | null
}

export type ModalTaxonomicPlotQueryVariables = Exact<{
  id: Scalars['ID']
  cadastralPlotId?: InputMaybe<Scalars['ID']>
}>

export type ModalTaxonomicPlotQuery = {
  __typename: 'Query'
  taxonomicPlot: ({ __typename: 'TaxonomicPlot' } & ModalTaxonomicPlotFragment) | null
}

export type CurrentUserQueryVariables = Exact<{ [key: string]: never }>

export type CurrentUserQuery = {
  __typename: 'Query'
  currentUser: ({ __typename: 'User' } & BaseUserFragment) | null
}

export type CurrentPlatformUserQueryVariables = Exact<{ [key: string]: never }>

export type CurrentPlatformUserQuery = {
  __typename: 'Query'
  currentUser: ({ __typename: 'User' } & PlatformUserFragment) | null
}

export type BaseAuctionChangedSubscriptionVariables = Exact<{
  id: Scalars['ID']
}>

export type BaseAuctionChangedSubscription = {
  __typename: 'Subscribtion'
  auctionChanged: { __typename: 'Auction' } & BaseAuctionFragment
}

export type FullAuctionChangedSubscriptionVariables = Exact<{
  id: Scalars['ID']
}>

export type FullAuctionChangedSubscription = {
  __typename: 'Subscribtion'
  auctionChanged: { __typename: 'Auction' } & FullAuctionFragment
}

export type OwnedAuctionChangedSubscriptionVariables = Exact<{
  id: Scalars['ID']
}>

export type OwnedAuctionChangedSubscription = {
  __typename: 'Subscribtion'
  auctionChanged: { __typename: 'Auction' } & OwnedAuctionFragment
}

export type ParticipatingAuctionChangedSubscriptionVariables = Exact<{
  id: Scalars['ID']
}>

export type ParticipatingAuctionChangedSubscription = {
  __typename: 'Subscribtion'
  auctionChanged: { __typename: 'Auction' } & ParticipatingAuctionFragment
}

export type OwnedForestChangedSubscriptionVariables = Exact<{
  id: Scalars['ID']
}>

export type OwnedForestChangedSubscription = {
  __typename: 'Subscribtion'
  cadastralPlotChanged: { __typename: 'CadastralPlot' } & OwnedCadastralPlotFragment
}

export type FullForestChangedSubscriptionVariables = Exact<{
  id: Scalars['ID']
}>

export type FullForestChangedSubscription = {
  __typename: 'Subscribtion'
  cadastralPlotChanged: { __typename: 'CadastralPlot' } & FullCadastralPlotFragment
}

export const MoneyFragmentDoc = gql`
  fragment Money on MoneyObject {
    amount
    currency {
      isoCode
    }
  }
`
export const BaseBidFragmentDoc = gql`
  fragment BaseBid on Bid {
    id
    price {
      ...Money
    }
  }
  ${MoneyFragmentDoc}
`
export const MapCadastralPlotFragmentDoc = gql`
  fragment MapCadastralPlot on CadastralPlot {
    id
    boundary
    latitude
    longitude
  }
`
export const MapAuctionFragmentDoc = gql`
  fragment MapAuction on Auction {
    id
    startingBid {
      ...Money
    }
    highestBidObject {
      ...BaseBid
    }
    cadastralPlots {
      ...MapCadastralPlot
    }
  }
  ${MoneyFragmentDoc}
  ${BaseBidFragmentDoc}
  ${MapCadastralPlotFragmentDoc}
`
export const BaseCadastralPlotFragmentDoc = gql`
  fragment BaseCadastralPlot on CadastralPlot {
    id
    title
    humanId
    boundary
    latitude
    longitude
    area
  }
`
export const BaseAuctionFragmentDoc = gql`
  fragment BaseAuction on Auction {
    id
    humanId
    title
    endTime
    address
    forestInspected
    startingBid {
      ...Money
    }
    highestBidObject {
      ...BaseBid
    }
    cadastralPlots {
      ...BaseCadastralPlot
    }
  }
  ${MoneyFragmentDoc}
  ${BaseBidFragmentDoc}
  ${BaseCadastralPlotFragmentDoc}
`
export const ListAuctionFragmentDoc = gql`
  fragment ListAuction on Auction {
    ...BaseAuction
    followed
    forestType
    salePurpose
    owner {
      id
    }
  }
  ${BaseAuctionFragmentDoc}
`
export const BidAuctionFragmentDoc = gql`
  fragment BidAuction on Auction {
    id
    bidIncrement {
      ...Money
    }
    nextMinimumAutoBidPrice {
      ...Money
    }
    nextMinimumBidPrice {
      ...Money
    }
  }
  ${MoneyFragmentDoc}
`
export const AutoBidFragmentDoc = gql`
  fragment AutoBid on AutoBid {
    id
    active
    price {
      ...Money
    }
  }
  ${MoneyFragmentDoc}
`
export const BidFragmentDoc = gql`
  fragment Bid on Bid {
    id
    bidderIndex
    bidder {
      id
    }
    createdAt
    price {
      ...Money
    }
    autoBid {
      ...AutoBid
    }
  }
  ${MoneyFragmentDoc}
  ${AutoBidFragmentDoc}
`
export const RepresentativeFragmentDoc = gql`
  fragment Representative on Representative {
    id
    email
    firstName
    lastName
    phoneNumber
    photoUrl
    title
  }
`
export const ServiceFragmentDoc = gql`
  fragment Service on Service {
    id
    description
    status
    title
  }
`
export const OwnedCadastralPlotFragmentDoc = gql`
  fragment OwnedCadastralPlot on CadastralPlot {
    ...BaseCadastralPlot
    address
    maturity
    forestType
    cadastralNumber
    forestUtilizationPurpose
    forestGroups
    shouldWaitForUpdates
    marketValue {
      ...Money
    }
    totalValue {
      ...Money
    }
    services {
      nodes {
        ...Service
      }
    }
  }
  ${BaseCadastralPlotFragmentDoc}
  ${MoneyFragmentDoc}
  ${ServiceFragmentDoc}
`
export const ImageFragmentDoc = gql`
  fragment Image on ImageObject {
    filename
    id
    originalUrl
    thumbUrl
    width
    height
  }
`
export const FileFragmentDoc = gql`
  fragment File on FileObject {
    filename
    id
    url
  }
`
export const BaseUserFragmentDoc = gql`
  fragment BaseUser on User {
    id
    email
    firstName
    lastName
    isEmployee
  }
`
export const FullCadastralPlotFragmentDoc = gql`
  fragment FullCadastralPlot on CadastralPlot {
    ...MapCadastralPlot
    ...OwnedCadastralPlot
    managementPlan
    manager {
      ...Representative
    }
    images {
      ...Image
    }
    files {
      ...File
    }
    owners {
      ...BaseUser
    }
    carbonAbsorptionPerYear
    inspected
  }
  ${MapCadastralPlotFragmentDoc}
  ${OwnedCadastralPlotFragmentDoc}
  ${RepresentativeFragmentDoc}
  ${ImageFragmentDoc}
  ${FileFragmentDoc}
  ${BaseUserFragmentDoc}
`
export const FullAuctionFragmentDoc = gql`
  fragment FullAuction on Auction {
    ...ListAuction
    ...BidAuction
    description
    auctionTaxRate
    bidCount
    minTax {
      ...Money
    }
    currentUserAutoBid {
      ...AutoBid
    }
    bids {
      nodes {
        ...Bid
      }
    }
    followerCount
    manager {
      ...Representative
    }
    cadastralPlots {
      ...FullCadastralPlot
    }
  }
  ${ListAuctionFragmentDoc}
  ${BidAuctionFragmentDoc}
  ${MoneyFragmentDoc}
  ${AutoBidFragmentDoc}
  ${BidFragmentDoc}
  ${RepresentativeFragmentDoc}
  ${FullCadastralPlotFragmentDoc}
`
export const ParticipatingAuctionFragmentDoc = gql`
  fragment ParticipatingAuction on Auction {
    ...BaseAuction
    ...BidAuction
    bids(first: 1) {
      nodes {
        ...Bid
      }
    }
  }
  ${BaseAuctionFragmentDoc}
  ${BidAuctionFragmentDoc}
  ${BidFragmentDoc}
`
export const FollowedAuctionFragmentDoc = gql`
  fragment FollowedAuction on Auction {
    id
    followed
  }
`
export const OwnedAuctionFragmentDoc = gql`
  fragment OwnedAuction on Auction {
    ...BaseAuction
    bids(first: 4) {
      nodes {
        ...Bid
      }
    }
  }
  ${BaseAuctionFragmentDoc}
  ${BidFragmentDoc}
`
export const PopupCadastralPlotFragmentDoc = gql`
  fragment PopupCadastralPlot on CadastralPlot {
    id
    address
    area
    cadastralNumber
    forestUtilizationPurpose
    volume
    boundary
    latitude
    longitude
    oldestMainLayerAge
    auctions {
      id
      title
      endTime
      startingBid {
        ...Money
      }
      highestBidObject {
        ...BaseBid
      }
    }
  }
  ${MoneyFragmentDoc}
  ${BaseBidFragmentDoc}
`
export const ModalCadastralPlotFragmentDoc = gql`
  fragment ModalCadastralPlot on CadastralPlot {
    ...PopupCadastralPlot
    forestArea
    totalValue {
      ...Money
    }
    marketValue {
      ...Money
    }
    forestType
  }
  ${PopupCadastralPlotFragmentDoc}
  ${MoneyFragmentDoc}
`
export const CadastralPlotSumsFragmentDoc = gql`
  fragment CadastralPlotSums on CadastralPlotSums {
    area
    marketValue {
      ...Money
    }
    totalValue {
      ...Money
    }
    volume
  }
  ${MoneyFragmentDoc}
`
export const BaseContentPageFragmentDoc = gql`
  fragment BaseContentPage on ContentPage {
    id
    link
    title
    image {
      ...Image
    }
    createdAt
  }
  ${ImageFragmentDoc}
`
export const FullContentPageFragmentDoc = gql`
  fragment FullContentPage on ContentPage {
    ...BaseContentPage
    content
    description
    styleUrl
  }
  ${BaseContentPageFragmentDoc}
`
export const RepresentativeCountryFragmentDoc = gql`
  fragment RepresentativeCountry on CountryRepresentatives {
    countryCode
    flagEmoji
    representatives {
      ...Representative
    }
  }
  ${RepresentativeFragmentDoc}
`
export const CountryFragmentDoc = gql`
  fragment Country on Country {
    isoCode
    name
  }
`
export const EmployeeFragmentDoc = gql`
  fragment Employee on Representative {
    id
    firstName
    lastName
    photoUrl
    title
    description
    linkedinUrl
  }
`
export const EmployeeCountryFragmentDoc = gql`
  fragment EmployeeCountry on CountryRepresentatives {
    representatives {
      ...Employee
    }
  }
  ${EmployeeFragmentDoc}
`
export const ForestGroupFragmentDoc = gql`
  fragment ForestGroup on ForestGroup {
    id
    title
  }
`
export const ForestHabitatFragmentDoc = gql`
  fragment ForestHabitat on ForestHabitat {
    id
    area
    kind
    category
    codes
  }
`
export const RoughPriceInquiryFragmentDoc = gql`
  fragment RoughPriceInquiry on RoughPriceInquiry {
    id
    maxPrice {
      ...Money
    }
    minPrice {
      ...Money
    }
  }
  ${MoneyFragmentDoc}
`
export const PageInfoFragmentDoc = gql`
  fragment PageInfo on PageInfo {
    endCursor
    hasNextPage
    hasPreviousPage
    startCursor
  }
`
export const UserNotificationSettingsFragmentDoc = gql`
  fragment UserNotificationSettings on UserNotificationSettings {
    followedAuctionBidsBrowser
    followedAuctionBidsEmail
    followedAuctionCloseEmail
    newsEmail
    overbidEmail
    overbidSms
    yourBidsEmail
    id
  }
`
export const PopupTaxonomicPlotFragmentDoc = gql`
  fragment PopupTaxonomicPlot on TaxonomicPlot {
    id
    mapIdentifier
    area(cadastralPlotId: $cadastralPlotId)
    speciesComposition
    age
  }
`
export const ModalTaxonomicPlotFragmentDoc = gql`
  fragment ModalTaxonomicPlot on TaxonomicPlot {
    ...PopupTaxonomicPlot
    crownDensity
    forestGroups(cadastralPlotId: $cadastralPlotId)
    growingPlace
    marketValue(cadastralPlotId: $cadastralPlotId) {
      ...Money
    }
    totalValue(cadastralPlotId: $cadastralPlotId) {
      ...Money
    }
    volume(cadastralPlotId: $cadastralPlotId)
    volumePerHa
    forestHabitatPresence(cadastralPlotId: $cadastralPlotId)
  }
  ${PopupTaxonomicPlotFragmentDoc}
  ${MoneyFragmentDoc}
`
export const PlatformUserFragmentDoc = gql`
  fragment PlatformUser on User {
    ...BaseUser
    isAllowedToBid
    isAllowedToStartAnAuction
    isAllowedToOrderService
    biddingAgreementSent
    auctionAgreementSent
    canDownloadForestBook
  }
  ${BaseUserFragmentDoc}
`
export const CompanyFragmentDoc = gql`
  fragment Company on Company {
    id
    companyName
    legalCode
    vatCode
    firstName
    lastName
    streetAddress
  }
`
export const FullUserFragmentDoc = gql`
  fragment FullUser on User {
    ...PlatformUser
    phoneNumber
    photoUrl
    dateOfBirth
    address
    countryCode
    company {
      ...Company
    }
  }
  ${PlatformUserFragmentDoc}
  ${CompanyFragmentDoc}
`
export const LeadUserFragmentDoc = gql`
  fragment LeadUser on User {
    id
    email
  }
`
export const CreateAuctionDocument = gql`
  mutation CreateAuction($input: CreateAuctionInput!) {
    createAuction(input: $input) {
      error {
        message
      }
    }
  }
`
export type CreateAuctionMutationFn = Apollo.MutationFunction<
  CreateAuctionMutation,
  CreateAuctionMutationVariables
>

/**
 * __useCreateAuctionMutation__
 *
 * To run a mutation, you first call `useCreateAuctionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAuctionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAuctionMutation, { data, loading, error }] = useCreateAuctionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAuctionMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateAuctionMutation, CreateAuctionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateAuctionMutation, CreateAuctionMutationVariables>(
    CreateAuctionDocument,
    options
  )
}
export type CreateAuctionMutationHookResult = ReturnType<typeof useCreateAuctionMutation>
export type CreateAuctionMutationResult = Apollo.MutationResult<CreateAuctionMutation>
export type CreateAuctionMutationOptions = Apollo.BaseMutationOptions<
  CreateAuctionMutation,
  CreateAuctionMutationVariables
>
export const RequestAuctionPermissionDocument = gql`
  mutation RequestAuctionPermission($input: RequestAuctionPermissionInput!) {
    requestAuctionPermission(input: $input) {
      error {
        message
      }
      user {
        ...PlatformUser
      }
    }
  }
  ${PlatformUserFragmentDoc}
`
export type RequestAuctionPermissionMutationFn = Apollo.MutationFunction<
  RequestAuctionPermissionMutation,
  RequestAuctionPermissionMutationVariables
>

/**
 * __useRequestAuctionPermissionMutation__
 *
 * To run a mutation, you first call `useRequestAuctionPermissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestAuctionPermissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestAuctionPermissionMutation, { data, loading, error }] = useRequestAuctionPermissionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestAuctionPermissionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestAuctionPermissionMutation,
    RequestAuctionPermissionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    RequestAuctionPermissionMutation,
    RequestAuctionPermissionMutationVariables
  >(RequestAuctionPermissionDocument, options)
}
export type RequestAuctionPermissionMutationHookResult = ReturnType<
  typeof useRequestAuctionPermissionMutation
>
export type RequestAuctionPermissionMutationResult =
  Apollo.MutationResult<RequestAuctionPermissionMutation>
export type RequestAuctionPermissionMutationOptions = Apollo.BaseMutationOptions<
  RequestAuctionPermissionMutation,
  RequestAuctionPermissionMutationVariables
>
export const FollowAuctionDocument = gql`
  mutation FollowAuction($input: FollowAuctionInput!) {
    followAuction(input: $input) {
      error {
        message
      }
      auction {
        ...FollowedAuction
      }
    }
  }
  ${FollowedAuctionFragmentDoc}
`
export type FollowAuctionMutationFn = Apollo.MutationFunction<
  FollowAuctionMutation,
  FollowAuctionMutationVariables
>

/**
 * __useFollowAuctionMutation__
 *
 * To run a mutation, you first call `useFollowAuctionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFollowAuctionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [followAuctionMutation, { data, loading, error }] = useFollowAuctionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFollowAuctionMutation(
  baseOptions?: Apollo.MutationHookOptions<FollowAuctionMutation, FollowAuctionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<FollowAuctionMutation, FollowAuctionMutationVariables>(
    FollowAuctionDocument,
    options
  )
}
export type FollowAuctionMutationHookResult = ReturnType<typeof useFollowAuctionMutation>
export type FollowAuctionMutationResult = Apollo.MutationResult<FollowAuctionMutation>
export type FollowAuctionMutationOptions = Apollo.BaseMutationOptions<
  FollowAuctionMutation,
  FollowAuctionMutationVariables
>
export const UnfollowAuctionDocument = gql`
  mutation UnfollowAuction($input: UnfollowAuctionInput!) {
    unfollowAuction(input: $input) {
      error {
        message
      }
      auction {
        ...FollowedAuction
      }
    }
  }
  ${FollowedAuctionFragmentDoc}
`
export type UnfollowAuctionMutationFn = Apollo.MutationFunction<
  UnfollowAuctionMutation,
  UnfollowAuctionMutationVariables
>

/**
 * __useUnfollowAuctionMutation__
 *
 * To run a mutation, you first call `useUnfollowAuctionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnfollowAuctionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unfollowAuctionMutation, { data, loading, error }] = useUnfollowAuctionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnfollowAuctionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnfollowAuctionMutation,
    UnfollowAuctionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UnfollowAuctionMutation, UnfollowAuctionMutationVariables>(
    UnfollowAuctionDocument,
    options
  )
}
export type UnfollowAuctionMutationHookResult = ReturnType<typeof useUnfollowAuctionMutation>
export type UnfollowAuctionMutationResult = Apollo.MutationResult<UnfollowAuctionMutation>
export type UnfollowAuctionMutationOptions = Apollo.BaseMutationOptions<
  UnfollowAuctionMutation,
  UnfollowAuctionMutationVariables
>
export const SignInDocument = gql`
  mutation SignIn($input: SignInWithEmailInput!) {
    signInWithEmail(input: $input) {
      error {
        message
        password
      }
      token
    }
  }
`
export type SignInMutationFn = Apollo.MutationFunction<SignInMutation, SignInMutationVariables>

/**
 * __useSignInMutation__
 *
 * To run a mutation, you first call `useSignInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signInMutation, { data, loading, error }] = useSignInMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignInMutation(
  baseOptions?: Apollo.MutationHookOptions<SignInMutation, SignInMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SignInMutation, SignInMutationVariables>(SignInDocument, options)
}
export type SignInMutationHookResult = ReturnType<typeof useSignInMutation>
export type SignInMutationResult = Apollo.MutationResult<SignInMutation>
export type SignInMutationOptions = Apollo.BaseMutationOptions<
  SignInMutation,
  SignInMutationVariables
>
export const CreateUserDocument = gql`
  mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input) {
      error {
        password
        phoneNumber
      }
      token
    }
  }
`
export type CreateUserMutationFn = Apollo.MutationFunction<
  CreateUserMutation,
  CreateUserMutationVariables
>

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(
    CreateUserDocument,
    options
  )
}
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<
  CreateUserMutation,
  CreateUserMutationVariables
>
export const CreateLeadUserDocument = gql`
  mutation CreateLeadUser($input: CreateLeadUserInput!) {
    createLeadUser(input: $input) {
      error {
        email
        termsAndConditionsAccepted
      }
      leadUser {
        ...LeadUser
      }
    }
  }
  ${LeadUserFragmentDoc}
`
export type CreateLeadUserMutationFn = Apollo.MutationFunction<
  CreateLeadUserMutation,
  CreateLeadUserMutationVariables
>

/**
 * __useCreateLeadUserMutation__
 *
 * To run a mutation, you first call `useCreateLeadUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLeadUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLeadUserMutation, { data, loading, error }] = useCreateLeadUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateLeadUserMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateLeadUserMutation, CreateLeadUserMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateLeadUserMutation, CreateLeadUserMutationVariables>(
    CreateLeadUserDocument,
    options
  )
}
export type CreateLeadUserMutationHookResult = ReturnType<typeof useCreateLeadUserMutation>
export type CreateLeadUserMutationResult = Apollo.MutationResult<CreateLeadUserMutation>
export type CreateLeadUserMutationOptions = Apollo.BaseMutationOptions<
  CreateLeadUserMutation,
  CreateLeadUserMutationVariables
>
export const ForgotPasswordDocument = gql`
  mutation ForgotPassword($input: SendPasswordResetInput!) {
    sendPasswordReset(input: $input) {
      error {
        message
      }
    }
  }
`
export type ForgotPasswordMutationFn = Apollo.MutationFunction<
  ForgotPasswordMutation,
  ForgotPasswordMutationVariables
>

/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordMutation, { data, loading, error }] = useForgotPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useForgotPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ForgotPasswordMutation, ForgotPasswordMutationVariables>(
    ForgotPasswordDocument,
    options
  )
}
export type ForgotPasswordMutationHookResult = ReturnType<typeof useForgotPasswordMutation>
export type ForgotPasswordMutationResult = Apollo.MutationResult<ForgotPasswordMutation>
export type ForgotPasswordMutationOptions = Apollo.BaseMutationOptions<
  ForgotPasswordMutation,
  ForgotPasswordMutationVariables
>
export const UpdateForgottenPasswordDocument = gql`
  mutation UpdateForgottenPassword($input: UpdateForgottenPasswordInput!) {
    updateForgottenPassword(input: $input) {
      error {
        message
        password
        passwordResetToken
      }
      token
    }
  }
`
export type UpdateForgottenPasswordMutationFn = Apollo.MutationFunction<
  UpdateForgottenPasswordMutation,
  UpdateForgottenPasswordMutationVariables
>

/**
 * __useUpdateForgottenPasswordMutation__
 *
 * To run a mutation, you first call `useUpdateForgottenPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateForgottenPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateForgottenPasswordMutation, { data, loading, error }] = useUpdateForgottenPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateForgottenPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateForgottenPasswordMutation,
    UpdateForgottenPasswordMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateForgottenPasswordMutation,
    UpdateForgottenPasswordMutationVariables
  >(UpdateForgottenPasswordDocument, options)
}
export type UpdateForgottenPasswordMutationHookResult = ReturnType<
  typeof useUpdateForgottenPasswordMutation
>
export type UpdateForgottenPasswordMutationResult =
  Apollo.MutationResult<UpdateForgottenPasswordMutation>
export type UpdateForgottenPasswordMutationOptions = Apollo.BaseMutationOptions<
  UpdateForgottenPasswordMutation,
  UpdateForgottenPasswordMutationVariables
>
export const CreateOpenIdUserDocument = gql`
  mutation CreateOpenIdUser($input: CreateOpenIDConnectUserInput!) {
    createOpenIdConnectUser(input: $input) {
      error {
        message
        leadUser
      }
      token
    }
  }
`
export type CreateOpenIdUserMutationFn = Apollo.MutationFunction<
  CreateOpenIdUserMutation,
  CreateOpenIdUserMutationVariables
>

/**
 * __useCreateOpenIdUserMutation__
 *
 * To run a mutation, you first call `useCreateOpenIdUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOpenIdUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOpenIdUserMutation, { data, loading, error }] = useCreateOpenIdUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOpenIdUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateOpenIdUserMutation,
    CreateOpenIdUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateOpenIdUserMutation, CreateOpenIdUserMutationVariables>(
    CreateOpenIdUserDocument,
    options
  )
}
export type CreateOpenIdUserMutationHookResult = ReturnType<typeof useCreateOpenIdUserMutation>
export type CreateOpenIdUserMutationResult = Apollo.MutationResult<CreateOpenIdUserMutation>
export type CreateOpenIdUserMutationOptions = Apollo.BaseMutationOptions<
  CreateOpenIdUserMutation,
  CreateOpenIdUserMutationVariables
>
export const SignInOpenIdUserDocument = gql`
  mutation SignInOpenIdUser($input: SignInWithOpenIDConnectInput!) {
    signInWithOpenIdConnect(input: $input) {
      error {
        message
        authorizationCode
      }
      token
    }
  }
`
export type SignInOpenIdUserMutationFn = Apollo.MutationFunction<
  SignInOpenIdUserMutation,
  SignInOpenIdUserMutationVariables
>

/**
 * __useSignInOpenIdUserMutation__
 *
 * To run a mutation, you first call `useSignInOpenIdUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignInOpenIdUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signInOpenIdUserMutation, { data, loading, error }] = useSignInOpenIdUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignInOpenIdUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SignInOpenIdUserMutation,
    SignInOpenIdUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SignInOpenIdUserMutation, SignInOpenIdUserMutationVariables>(
    SignInOpenIdUserDocument,
    options
  )
}
export type SignInOpenIdUserMutationHookResult = ReturnType<typeof useSignInOpenIdUserMutation>
export type SignInOpenIdUserMutationResult = Apollo.MutationResult<SignInOpenIdUserMutation>
export type SignInOpenIdUserMutationOptions = Apollo.BaseMutationOptions<
  SignInOpenIdUserMutation,
  SignInOpenIdUserMutationVariables
>
export const CreateBidDocument = gql`
  mutation CreateBid($input: CreateBidInput!) {
    createBid(input: $input) {
      error {
        message
        price
      }
      bid {
        ...Bid
      }
    }
  }
  ${BidFragmentDoc}
`
export type CreateBidMutationFn = Apollo.MutationFunction<
  CreateBidMutation,
  CreateBidMutationVariables
>

/**
 * __useCreateBidMutation__
 *
 * To run a mutation, you first call `useCreateBidMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBidMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBidMutation, { data, loading, error }] = useCreateBidMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBidMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateBidMutation, CreateBidMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateBidMutation, CreateBidMutationVariables>(
    CreateBidDocument,
    options
  )
}
export type CreateBidMutationHookResult = ReturnType<typeof useCreateBidMutation>
export type CreateBidMutationResult = Apollo.MutationResult<CreateBidMutation>
export type CreateBidMutationOptions = Apollo.BaseMutationOptions<
  CreateBidMutation,
  CreateBidMutationVariables
>
export const CreateAutoBidDocument = gql`
  mutation CreateAutoBid($input: CreateAutoBidInput!) {
    createAutoBid(input: $input) {
      error {
        message
        price
      }
      auction {
        id
        currentUserAutoBid {
          ...AutoBid
        }
      }
    }
  }
  ${AutoBidFragmentDoc}
`
export type CreateAutoBidMutationFn = Apollo.MutationFunction<
  CreateAutoBidMutation,
  CreateAutoBidMutationVariables
>

/**
 * __useCreateAutoBidMutation__
 *
 * To run a mutation, you first call `useCreateAutoBidMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAutoBidMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAutoBidMutation, { data, loading, error }] = useCreateAutoBidMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAutoBidMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateAutoBidMutation, CreateAutoBidMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateAutoBidMutation, CreateAutoBidMutationVariables>(
    CreateAutoBidDocument,
    options
  )
}
export type CreateAutoBidMutationHookResult = ReturnType<typeof useCreateAutoBidMutation>
export type CreateAutoBidMutationResult = Apollo.MutationResult<CreateAutoBidMutation>
export type CreateAutoBidMutationOptions = Apollo.BaseMutationOptions<
  CreateAutoBidMutation,
  CreateAutoBidMutationVariables
>
export const AddCadastralPlotsDocument = gql`
  mutation AddCadastralPlots($input: AssignCadastralPlotsInput!) {
    assignCadastralPlots(input: $input) {
      error {
        message
        cadastralPlotsErrors {
          index
          messages
        }
      }
      cadastralPlots {
        ...BaseCadastralPlot
      }
    }
  }
  ${BaseCadastralPlotFragmentDoc}
`
export type AddCadastralPlotsMutationFn = Apollo.MutationFunction<
  AddCadastralPlotsMutation,
  AddCadastralPlotsMutationVariables
>

/**
 * __useAddCadastralPlotsMutation__
 *
 * To run a mutation, you first call `useAddCadastralPlotsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCadastralPlotsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCadastralPlotsMutation, { data, loading, error }] = useAddCadastralPlotsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddCadastralPlotsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddCadastralPlotsMutation,
    AddCadastralPlotsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<AddCadastralPlotsMutation, AddCadastralPlotsMutationVariables>(
    AddCadastralPlotsDocument,
    options
  )
}
export type AddCadastralPlotsMutationHookResult = ReturnType<typeof useAddCadastralPlotsMutation>
export type AddCadastralPlotsMutationResult = Apollo.MutationResult<AddCadastralPlotsMutation>
export type AddCadastralPlotsMutationOptions = Apollo.BaseMutationOptions<
  AddCadastralPlotsMutation,
  AddCadastralPlotsMutationVariables
>
export const OrderServiceDocument = gql`
  mutation OrderService($input: OrderServiceInput!) {
    orderService(input: $input) {
      error {
        message
        status
      }
      service {
        ...Service
      }
    }
  }
  ${ServiceFragmentDoc}
`
export type OrderServiceMutationFn = Apollo.MutationFunction<
  OrderServiceMutation,
  OrderServiceMutationVariables
>

/**
 * __useOrderServiceMutation__
 *
 * To run a mutation, you first call `useOrderServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderServiceMutation, { data, loading, error }] = useOrderServiceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOrderServiceMutation(
  baseOptions?: Apollo.MutationHookOptions<OrderServiceMutation, OrderServiceMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<OrderServiceMutation, OrderServiceMutationVariables>(
    OrderServiceDocument,
    options
  )
}
export type OrderServiceMutationHookResult = ReturnType<typeof useOrderServiceMutation>
export type OrderServiceMutationResult = Apollo.MutationResult<OrderServiceMutation>
export type OrderServiceMutationOptions = Apollo.BaseMutationOptions<
  OrderServiceMutation,
  OrderServiceMutationVariables
>
export const CreateRoughPriceInquiryDocument = gql`
  mutation CreateRoughPriceInquiry($input: CreateRoughPriceInquiryInput!) {
    createRoughPriceInquiry(input: $input) {
      error {
        message
        intents
      }
      roughPriceInquiry {
        ...RoughPriceInquiry
      }
    }
  }
  ${RoughPriceInquiryFragmentDoc}
`
export type CreateRoughPriceInquiryMutationFn = Apollo.MutationFunction<
  CreateRoughPriceInquiryMutation,
  CreateRoughPriceInquiryMutationVariables
>

/**
 * __useCreateRoughPriceInquiryMutation__
 *
 * To run a mutation, you first call `useCreateRoughPriceInquiryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRoughPriceInquiryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRoughPriceInquiryMutation, { data, loading, error }] = useCreateRoughPriceInquiryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRoughPriceInquiryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateRoughPriceInquiryMutation,
    CreateRoughPriceInquiryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateRoughPriceInquiryMutation,
    CreateRoughPriceInquiryMutationVariables
  >(CreateRoughPriceInquiryDocument, options)
}
export type CreateRoughPriceInquiryMutationHookResult = ReturnType<
  typeof useCreateRoughPriceInquiryMutation
>
export type CreateRoughPriceInquiryMutationResult =
  Apollo.MutationResult<CreateRoughPriceInquiryMutation>
export type CreateRoughPriceInquiryMutationOptions = Apollo.BaseMutationOptions<
  CreateRoughPriceInquiryMutation,
  CreateRoughPriceInquiryMutationVariables
>
export const UpdateRoughPriceInquiryContactsDocument = gql`
  mutation UpdateRoughPriceInquiryContacts($input: UpdateRoughPriceInquiryContactsInput!) {
    updateRoughPriceInquiryContacts(input: $input) {
      error {
        message
        email
        phoneNumber
      }
    }
  }
`
export type UpdateRoughPriceInquiryContactsMutationFn = Apollo.MutationFunction<
  UpdateRoughPriceInquiryContactsMutation,
  UpdateRoughPriceInquiryContactsMutationVariables
>

/**
 * __useUpdateRoughPriceInquiryContactsMutation__
 *
 * To run a mutation, you first call `useUpdateRoughPriceInquiryContactsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRoughPriceInquiryContactsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRoughPriceInquiryContactsMutation, { data, loading, error }] = useUpdateRoughPriceInquiryContactsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRoughPriceInquiryContactsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateRoughPriceInquiryContactsMutation,
    UpdateRoughPriceInquiryContactsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateRoughPriceInquiryContactsMutation,
    UpdateRoughPriceInquiryContactsMutationVariables
  >(UpdateRoughPriceInquiryContactsDocument, options)
}
export type UpdateRoughPriceInquiryContactsMutationHookResult = ReturnType<
  typeof useUpdateRoughPriceInquiryContactsMutation
>
export type UpdateRoughPriceInquiryContactsMutationResult =
  Apollo.MutationResult<UpdateRoughPriceInquiryContactsMutation>
export type UpdateRoughPriceInquiryContactsMutationOptions = Apollo.BaseMutationOptions<
  UpdateRoughPriceInquiryContactsMutation,
  UpdateRoughPriceInquiryContactsMutationVariables
>
export const UpdateRoughPriceInquiryCadastralNumberDocument = gql`
  mutation UpdateRoughPriceInquiryCadastralNumber(
    $input: UpdateRoughPriceInquiryCadastralNumberInput!
  ) {
    updateRoughPriceInquiryCadastralNumber(input: $input) {
      error {
        message
      }
    }
  }
`
export type UpdateRoughPriceInquiryCadastralNumberMutationFn = Apollo.MutationFunction<
  UpdateRoughPriceInquiryCadastralNumberMutation,
  UpdateRoughPriceInquiryCadastralNumberMutationVariables
>

/**
 * __useUpdateRoughPriceInquiryCadastralNumberMutation__
 *
 * To run a mutation, you first call `useUpdateRoughPriceInquiryCadastralNumberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRoughPriceInquiryCadastralNumberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRoughPriceInquiryCadastralNumberMutation, { data, loading, error }] = useUpdateRoughPriceInquiryCadastralNumberMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRoughPriceInquiryCadastralNumberMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateRoughPriceInquiryCadastralNumberMutation,
    UpdateRoughPriceInquiryCadastralNumberMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateRoughPriceInquiryCadastralNumberMutation,
    UpdateRoughPriceInquiryCadastralNumberMutationVariables
  >(UpdateRoughPriceInquiryCadastralNumberDocument, options)
}
export type UpdateRoughPriceInquiryCadastralNumberMutationHookResult = ReturnType<
  typeof useUpdateRoughPriceInquiryCadastralNumberMutation
>
export type UpdateRoughPriceInquiryCadastralNumberMutationResult =
  Apollo.MutationResult<UpdateRoughPriceInquiryCadastralNumberMutation>
export type UpdateRoughPriceInquiryCadastralNumberMutationOptions = Apollo.BaseMutationOptions<
  UpdateRoughPriceInquiryCadastralNumberMutation,
  UpdateRoughPriceInquiryCadastralNumberMutationVariables
>
export const CreateStartAuctionInquiryDocument = gql`
  mutation CreateStartAuctionInquiry($input: StartAuctionInquiryInput!) {
    createStartAuctionInquiry(input: $input) {
      error {
        message
        email
        phoneNumber
      }
    }
  }
`
export type CreateStartAuctionInquiryMutationFn = Apollo.MutationFunction<
  CreateStartAuctionInquiryMutation,
  CreateStartAuctionInquiryMutationVariables
>

/**
 * __useCreateStartAuctionInquiryMutation__
 *
 * To run a mutation, you first call `useCreateStartAuctionInquiryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStartAuctionInquiryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStartAuctionInquiryMutation, { data, loading, error }] = useCreateStartAuctionInquiryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateStartAuctionInquiryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateStartAuctionInquiryMutation,
    CreateStartAuctionInquiryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateStartAuctionInquiryMutation,
    CreateStartAuctionInquiryMutationVariables
  >(CreateStartAuctionInquiryDocument, options)
}
export type CreateStartAuctionInquiryMutationHookResult = ReturnType<
  typeof useCreateStartAuctionInquiryMutation
>
export type CreateStartAuctionInquiryMutationResult =
  Apollo.MutationResult<CreateStartAuctionInquiryMutation>
export type CreateStartAuctionInquiryMutationOptions = Apollo.BaseMutationOptions<
  CreateStartAuctionInquiryMutation,
  CreateStartAuctionInquiryMutationVariables
>
export const CreateForestValueInquiryDocument = gql`
  mutation CreateForestValueInquiry($input: SignUpWithEmailInput!) {
    signUpWithEmail(input: $input) {
      error {
        message
        email
        password
        phoneNumber
        termsAndConditionsAccepted
      }
      token
    }
  }
`
export type CreateForestValueInquiryMutationFn = Apollo.MutationFunction<
  CreateForestValueInquiryMutation,
  CreateForestValueInquiryMutationVariables
>

/**
 * __useCreateForestValueInquiryMutation__
 *
 * To run a mutation, you first call `useCreateForestValueInquiryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateForestValueInquiryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createForestValueInquiryMutation, { data, loading, error }] = useCreateForestValueInquiryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateForestValueInquiryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateForestValueInquiryMutation,
    CreateForestValueInquiryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateForestValueInquiryMutation,
    CreateForestValueInquiryMutationVariables
  >(CreateForestValueInquiryDocument, options)
}
export type CreateForestValueInquiryMutationHookResult = ReturnType<
  typeof useCreateForestValueInquiryMutation
>
export type CreateForestValueInquiryMutationResult =
  Apollo.MutationResult<CreateForestValueInquiryMutation>
export type CreateForestValueInquiryMutationOptions = Apollo.BaseMutationOptions<
  CreateForestValueInquiryMutation,
  CreateForestValueInquiryMutationVariables
>
export const UpdatePersonDetailsDocument = gql`
  mutation UpdatePersonDetails($input: UpdatePersonDetailsInput!) {
    updatePersonDetails(input: $input) {
      error {
        message
        password
        passwordConfirmation
        phoneNumber
      }
      user {
        ...FullUser
      }
    }
  }
  ${FullUserFragmentDoc}
`
export type UpdatePersonDetailsMutationFn = Apollo.MutationFunction<
  UpdatePersonDetailsMutation,
  UpdatePersonDetailsMutationVariables
>

/**
 * __useUpdatePersonDetailsMutation__
 *
 * To run a mutation, you first call `useUpdatePersonDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePersonDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePersonDetailsMutation, { data, loading, error }] = useUpdatePersonDetailsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePersonDetailsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePersonDetailsMutation,
    UpdatePersonDetailsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdatePersonDetailsMutation, UpdatePersonDetailsMutationVariables>(
    UpdatePersonDetailsDocument,
    options
  )
}
export type UpdatePersonDetailsMutationHookResult = ReturnType<
  typeof useUpdatePersonDetailsMutation
>
export type UpdatePersonDetailsMutationResult = Apollo.MutationResult<UpdatePersonDetailsMutation>
export type UpdatePersonDetailsMutationOptions = Apollo.BaseMutationOptions<
  UpdatePersonDetailsMutation,
  UpdatePersonDetailsMutationVariables
>
export const UpdateCompanyDocument = gql`
  mutation UpdateCompany($input: UpdateCompanyDetailsInput!) {
    updateCompanyDetails(input: $input) {
      error {
        message
      }
    }
  }
`
export type UpdateCompanyMutationFn = Apollo.MutationFunction<
  UpdateCompanyMutation,
  UpdateCompanyMutationVariables
>

/**
 * __useUpdateCompanyMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyMutation, { data, loading, error }] = useUpdateCompanyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCompanyMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateCompanyMutation, UpdateCompanyMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateCompanyMutation, UpdateCompanyMutationVariables>(
    UpdateCompanyDocument,
    options
  )
}
export type UpdateCompanyMutationHookResult = ReturnType<typeof useUpdateCompanyMutation>
export type UpdateCompanyMutationResult = Apollo.MutationResult<UpdateCompanyMutation>
export type UpdateCompanyMutationOptions = Apollo.BaseMutationOptions<
  UpdateCompanyMutation,
  UpdateCompanyMutationVariables
>
export const CreateCompanyDocument = gql`
  mutation CreateCompany($input: CreateCompanyInput!) {
    createCompany(input: $input) {
      error {
        message
      }
    }
  }
`
export type CreateCompanyMutationFn = Apollo.MutationFunction<
  CreateCompanyMutation,
  CreateCompanyMutationVariables
>

/**
 * __useCreateCompanyMutation__
 *
 * To run a mutation, you first call `useCreateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCompanyMutation, { data, loading, error }] = useCreateCompanyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCompanyMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateCompanyMutation, CreateCompanyMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateCompanyMutation, CreateCompanyMutationVariables>(
    CreateCompanyDocument,
    options
  )
}
export type CreateCompanyMutationHookResult = ReturnType<typeof useCreateCompanyMutation>
export type CreateCompanyMutationResult = Apollo.MutationResult<CreateCompanyMutation>
export type CreateCompanyMutationOptions = Apollo.BaseMutationOptions<
  CreateCompanyMutation,
  CreateCompanyMutationVariables
>
export const UpdateNotificationSettingsDocument = gql`
  mutation UpdateNotificationSettings($input: UpdateNotificationSettingsInput!) {
    updateNotificationSettings(input: $input) {
      error {
        message
      }
      notificationSettings {
        ...UserNotificationSettings
      }
    }
  }
  ${UserNotificationSettingsFragmentDoc}
`
export type UpdateNotificationSettingsMutationFn = Apollo.MutationFunction<
  UpdateNotificationSettingsMutation,
  UpdateNotificationSettingsMutationVariables
>

/**
 * __useUpdateNotificationSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateNotificationSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNotificationSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNotificationSettingsMutation, { data, loading, error }] = useUpdateNotificationSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateNotificationSettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateNotificationSettingsMutation,
    UpdateNotificationSettingsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateNotificationSettingsMutation,
    UpdateNotificationSettingsMutationVariables
  >(UpdateNotificationSettingsDocument, options)
}
export type UpdateNotificationSettingsMutationHookResult = ReturnType<
  typeof useUpdateNotificationSettingsMutation
>
export type UpdateNotificationSettingsMutationResult =
  Apollo.MutationResult<UpdateNotificationSettingsMutation>
export type UpdateNotificationSettingsMutationOptions = Apollo.BaseMutationOptions<
  UpdateNotificationSettingsMutation,
  UpdateNotificationSettingsMutationVariables
>
export const BaseMarketAuctionsDocument = gql`
  query BaseMarketAuctions($after: String, $first: Int) {
    marketAuctions(after: $after, first: $first) {
      pageInfo {
        ...PageInfo
      }
      nodes {
        ...BaseAuction
      }
    }
  }
  ${PageInfoFragmentDoc}
  ${BaseAuctionFragmentDoc}
`

/**
 * __useBaseMarketAuctionsQuery__
 *
 * To run a query within a React component, call `useBaseMarketAuctionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBaseMarketAuctionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBaseMarketAuctionsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useBaseMarketAuctionsQuery(
  baseOptions?: Apollo.QueryHookOptions<BaseMarketAuctionsQuery, BaseMarketAuctionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<BaseMarketAuctionsQuery, BaseMarketAuctionsQueryVariables>(
    BaseMarketAuctionsDocument,
    options
  )
}
export function useBaseMarketAuctionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BaseMarketAuctionsQuery,
    BaseMarketAuctionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<BaseMarketAuctionsQuery, BaseMarketAuctionsQueryVariables>(
    BaseMarketAuctionsDocument,
    options
  )
}
export type BaseMarketAuctionsQueryHookResult = ReturnType<typeof useBaseMarketAuctionsQuery>
export type BaseMarketAuctionsLazyQueryHookResult = ReturnType<
  typeof useBaseMarketAuctionsLazyQuery
>
export type BaseMarketAuctionsQueryResult = Apollo.QueryResult<
  BaseMarketAuctionsQuery,
  BaseMarketAuctionsQueryVariables
>
export const ListAuctionsDocument = gql`
  query ListAuctions(
    $after: String
    $areaGteq: Float
    $areaLteq: Float
    $auctionOpen: Boolean
    $before: String
    $first: Int
    $forestTypeIn: [ForestType!]
    $last: Int
    $maturityIn: [TaxonomicPlotMaturityCondition!]
    $priceCentsGteq: Int
    $priceCentsLteq: Int
    $salePurposeIn: [AuctionSalePurpose!]
    $titleCont: String
    $volumeGteq: Int
    $volumeLteq: Int
    $order: AuctionOrder
    $countryCodeIn: [AuctionCountryCode!]
  ) {
    auctions: auctions(
      after: $after
      areaGteq: $areaGteq
      areaLteq: $areaLteq
      auctionOpen: $auctionOpen
      before: $before
      first: $first
      forestTypeIn: $forestTypeIn
      last: $last
      maturityIn: $maturityIn
      priceCentsGteq: $priceCentsGteq
      priceCentsLteq: $priceCentsLteq
      salePurposeIn: $salePurposeIn
      titleCont: $titleCont
      volumeGteq: $volumeGteq
      volumeLteq: $volumeLteq
      order: $order
      countryCodeIn: $countryCodeIn
    ) {
      edges {
        cursor
        node {
          ...ListAuction
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
    filteredAuctions: auctions(
      first: 50
      areaGteq: $areaGteq
      areaLteq: $areaLteq
      auctionOpen: $auctionOpen
      forestTypeIn: $forestTypeIn
      maturityIn: $maturityIn
      priceCentsGteq: $priceCentsGteq
      priceCentsLteq: $priceCentsLteq
      salePurposeIn: $salePurposeIn
      titleCont: $titleCont
      volumeGteq: $volumeGteq
      volumeLteq: $volumeLteq
      order: $order
      countryCodeIn: $countryCodeIn
    ) {
      nodes {
        ...MapAuction
      }
    }
  }
  ${ListAuctionFragmentDoc}
  ${PageInfoFragmentDoc}
  ${MapAuctionFragmentDoc}
`

/**
 * __useListAuctionsQuery__
 *
 * To run a query within a React component, call `useListAuctionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAuctionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAuctionsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      areaGteq: // value for 'areaGteq'
 *      areaLteq: // value for 'areaLteq'
 *      auctionOpen: // value for 'auctionOpen'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      forestTypeIn: // value for 'forestTypeIn'
 *      last: // value for 'last'
 *      maturityIn: // value for 'maturityIn'
 *      priceCentsGteq: // value for 'priceCentsGteq'
 *      priceCentsLteq: // value for 'priceCentsLteq'
 *      salePurposeIn: // value for 'salePurposeIn'
 *      titleCont: // value for 'titleCont'
 *      volumeGteq: // value for 'volumeGteq'
 *      volumeLteq: // value for 'volumeLteq'
 *      order: // value for 'order'
 *      countryCodeIn: // value for 'countryCodeIn'
 *   },
 * });
 */
export function useListAuctionsQuery(
  baseOptions?: Apollo.QueryHookOptions<ListAuctionsQuery, ListAuctionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ListAuctionsQuery, ListAuctionsQueryVariables>(
    ListAuctionsDocument,
    options
  )
}
export function useListAuctionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListAuctionsQuery, ListAuctionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ListAuctionsQuery, ListAuctionsQueryVariables>(
    ListAuctionsDocument,
    options
  )
}
export type ListAuctionsQueryHookResult = ReturnType<typeof useListAuctionsQuery>
export type ListAuctionsLazyQueryHookResult = ReturnType<typeof useListAuctionsLazyQuery>
export type ListAuctionsQueryResult = Apollo.QueryResult<
  ListAuctionsQuery,
  ListAuctionsQueryVariables
>
export const AuctionDocument = gql`
  query Auction($id: ID!) {
    auction(id: $id) {
      ...FullAuction
    }
  }
  ${FullAuctionFragmentDoc}
`

/**
 * __useAuctionQuery__
 *
 * To run a query within a React component, call `useAuctionQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuctionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuctionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAuctionQuery(
  baseOptions: Apollo.QueryHookOptions<AuctionQuery, AuctionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AuctionQuery, AuctionQueryVariables>(AuctionDocument, options)
}
export function useAuctionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AuctionQuery, AuctionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<AuctionQuery, AuctionQueryVariables>(AuctionDocument, options)
}
export type AuctionQueryHookResult = ReturnType<typeof useAuctionQuery>
export type AuctionLazyQueryHookResult = ReturnType<typeof useAuctionLazyQuery>
export type AuctionQueryResult = Apollo.QueryResult<AuctionQuery, AuctionQueryVariables>
export const OpenIdAuthorizationUrlDocument = gql`
  query OpenIdAuthorizationUrl($identityMethod: OpenIDConnectIdentityMethod!, $state: String!) {
    openIdConnectAuthorizationUrl(identityMethod: $identityMethod, state: $state)
  }
`

/**
 * __useOpenIdAuthorizationUrlQuery__
 *
 * To run a query within a React component, call `useOpenIdAuthorizationUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useOpenIdAuthorizationUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOpenIdAuthorizationUrlQuery({
 *   variables: {
 *      identityMethod: // value for 'identityMethod'
 *      state: // value for 'state'
 *   },
 * });
 */
export function useOpenIdAuthorizationUrlQuery(
  baseOptions: Apollo.QueryHookOptions<
    OpenIdAuthorizationUrlQuery,
    OpenIdAuthorizationUrlQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<OpenIdAuthorizationUrlQuery, OpenIdAuthorizationUrlQueryVariables>(
    OpenIdAuthorizationUrlDocument,
    options
  )
}
export function useOpenIdAuthorizationUrlLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OpenIdAuthorizationUrlQuery,
    OpenIdAuthorizationUrlQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<OpenIdAuthorizationUrlQuery, OpenIdAuthorizationUrlQueryVariables>(
    OpenIdAuthorizationUrlDocument,
    options
  )
}
export type OpenIdAuthorizationUrlQueryHookResult = ReturnType<
  typeof useOpenIdAuthorizationUrlQuery
>
export type OpenIdAuthorizationUrlLazyQueryHookResult = ReturnType<
  typeof useOpenIdAuthorizationUrlLazyQuery
>
export type OpenIdAuthorizationUrlQueryResult = Apollo.QueryResult<
  OpenIdAuthorizationUrlQuery,
  OpenIdAuthorizationUrlQueryVariables
>
export const RegistrationTypeDocument = gql`
  query RegistrationType($email: EmailScalar!) {
    userRegistrationType(email: $email)
  }
`

/**
 * __useRegistrationTypeQuery__
 *
 * To run a query within a React component, call `useRegistrationTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegistrationTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegistrationTypeQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useRegistrationTypeQuery(
  baseOptions: Apollo.QueryHookOptions<RegistrationTypeQuery, RegistrationTypeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<RegistrationTypeQuery, RegistrationTypeQueryVariables>(
    RegistrationTypeDocument,
    options
  )
}
export function useRegistrationTypeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<RegistrationTypeQuery, RegistrationTypeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<RegistrationTypeQuery, RegistrationTypeQueryVariables>(
    RegistrationTypeDocument,
    options
  )
}
export type RegistrationTypeQueryHookResult = ReturnType<typeof useRegistrationTypeQuery>
export type RegistrationTypeLazyQueryHookResult = ReturnType<typeof useRegistrationTypeLazyQuery>
export type RegistrationTypeQueryResult = Apollo.QueryResult<
  RegistrationTypeQuery,
  RegistrationTypeQueryVariables
>
export const AuthenticationProvidersDocument = gql`
  query AuthenticationProviders($email: EmailScalar!) {
    userAuthenticationProviders(email: $email) {
      id
      identityMethod
    }
  }
`

/**
 * __useAuthenticationProvidersQuery__
 *
 * To run a query within a React component, call `useAuthenticationProvidersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuthenticationProvidersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuthenticationProvidersQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useAuthenticationProvidersQuery(
  baseOptions: Apollo.QueryHookOptions<
    AuthenticationProvidersQuery,
    AuthenticationProvidersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AuthenticationProvidersQuery, AuthenticationProvidersQueryVariables>(
    AuthenticationProvidersDocument,
    options
  )
}
export function useAuthenticationProvidersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AuthenticationProvidersQuery,
    AuthenticationProvidersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<AuthenticationProvidersQuery, AuthenticationProvidersQueryVariables>(
    AuthenticationProvidersDocument,
    options
  )
}
export type AuthenticationProvidersQueryHookResult = ReturnType<
  typeof useAuthenticationProvidersQuery
>
export type AuthenticationProvidersLazyQueryHookResult = ReturnType<
  typeof useAuthenticationProvidersLazyQuery
>
export type AuthenticationProvidersQueryResult = Apollo.QueryResult<
  AuthenticationProvidersQuery,
  AuthenticationProvidersQueryVariables
>
export const BlogPostDocument = gql`
  query BlogPost($slug: String!) {
    contentPage(slug: $slug) {
      ...FullContentPage
    }
  }
  ${FullContentPageFragmentDoc}
`

/**
 * __useBlogPostQuery__
 *
 * To run a query within a React component, call `useBlogPostQuery` and pass it any options that fit your needs.
 * When your component renders, `useBlogPostQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBlogPostQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useBlogPostQuery(
  baseOptions: Apollo.QueryHookOptions<BlogPostQuery, BlogPostQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<BlogPostQuery, BlogPostQueryVariables>(BlogPostDocument, options)
}
export function useBlogPostLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BlogPostQuery, BlogPostQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<BlogPostQuery, BlogPostQueryVariables>(BlogPostDocument, options)
}
export type BlogPostQueryHookResult = ReturnType<typeof useBlogPostQuery>
export type BlogPostLazyQueryHookResult = ReturnType<typeof useBlogPostLazyQuery>
export type BlogPostQueryResult = Apollo.QueryResult<BlogPostQuery, BlogPostQueryVariables>
export const CadastralPlotDocument = gql`
  query CadastralPlot($id: ID!) {
    cadastralPlot(id: $id) {
      ...FullCadastralPlot
      auctions {
        ...BaseAuction
      }
    }
  }
  ${FullCadastralPlotFragmentDoc}
  ${BaseAuctionFragmentDoc}
`

/**
 * __useCadastralPlotQuery__
 *
 * To run a query within a React component, call `useCadastralPlotQuery` and pass it any options that fit your needs.
 * When your component renders, `useCadastralPlotQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCadastralPlotQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCadastralPlotQuery(
  baseOptions: Apollo.QueryHookOptions<CadastralPlotQuery, CadastralPlotQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CadastralPlotQuery, CadastralPlotQueryVariables>(
    CadastralPlotDocument,
    options
  )
}
export function useCadastralPlotLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CadastralPlotQuery, CadastralPlotQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CadastralPlotQuery, CadastralPlotQueryVariables>(
    CadastralPlotDocument,
    options
  )
}
export type CadastralPlotQueryHookResult = ReturnType<typeof useCadastralPlotQuery>
export type CadastralPlotLazyQueryHookResult = ReturnType<typeof useCadastralPlotLazyQuery>
export type CadastralPlotQueryResult = Apollo.QueryResult<
  CadastralPlotQuery,
  CadastralPlotQueryVariables
>
export const PopupCadastralPlotDocument = gql`
  query PopupCadastralPlot($id: ID!) {
    cadastralPlot(id: $id) {
      ...PopupCadastralPlot
    }
  }
  ${PopupCadastralPlotFragmentDoc}
`

/**
 * __usePopupCadastralPlotQuery__
 *
 * To run a query within a React component, call `usePopupCadastralPlotQuery` and pass it any options that fit your needs.
 * When your component renders, `usePopupCadastralPlotQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePopupCadastralPlotQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePopupCadastralPlotQuery(
  baseOptions: Apollo.QueryHookOptions<PopupCadastralPlotQuery, PopupCadastralPlotQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<PopupCadastralPlotQuery, PopupCadastralPlotQueryVariables>(
    PopupCadastralPlotDocument,
    options
  )
}
export function usePopupCadastralPlotLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PopupCadastralPlotQuery,
    PopupCadastralPlotQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<PopupCadastralPlotQuery, PopupCadastralPlotQueryVariables>(
    PopupCadastralPlotDocument,
    options
  )
}
export type PopupCadastralPlotQueryHookResult = ReturnType<typeof usePopupCadastralPlotQuery>
export type PopupCadastralPlotLazyQueryHookResult = ReturnType<
  typeof usePopupCadastralPlotLazyQuery
>
export type PopupCadastralPlotQueryResult = Apollo.QueryResult<
  PopupCadastralPlotQuery,
  PopupCadastralPlotQueryVariables
>
export const ModalCadastralPlotDocument = gql`
  query ModalCadastralPlot($id: ID!) {
    cadastralPlot(id: $id) {
      ...ModalCadastralPlot
    }
  }
  ${ModalCadastralPlotFragmentDoc}
`

/**
 * __useModalCadastralPlotQuery__
 *
 * To run a query within a React component, call `useModalCadastralPlotQuery` and pass it any options that fit your needs.
 * When your component renders, `useModalCadastralPlotQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useModalCadastralPlotQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useModalCadastralPlotQuery(
  baseOptions: Apollo.QueryHookOptions<ModalCadastralPlotQuery, ModalCadastralPlotQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ModalCadastralPlotQuery, ModalCadastralPlotQueryVariables>(
    ModalCadastralPlotDocument,
    options
  )
}
export function useModalCadastralPlotLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ModalCadastralPlotQuery,
    ModalCadastralPlotQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ModalCadastralPlotQuery, ModalCadastralPlotQueryVariables>(
    ModalCadastralPlotDocument,
    options
  )
}
export type ModalCadastralPlotQueryHookResult = ReturnType<typeof useModalCadastralPlotQuery>
export type ModalCadastralPlotLazyQueryHookResult = ReturnType<
  typeof useModalCadastralPlotLazyQuery
>
export type ModalCadastralPlotQueryResult = Apollo.QueryResult<
  ModalCadastralPlotQuery,
  ModalCadastralPlotQueryVariables
>
export const MarketAuctionsDocument = gql`
  query MarketAuctions {
    marketAuctions(first: 3) {
      nodes {
        ...BaseAuction
      }
    }
  }
  ${BaseAuctionFragmentDoc}
`

/**
 * __useMarketAuctionsQuery__
 *
 * To run a query within a React component, call `useMarketAuctionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMarketAuctionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMarketAuctionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMarketAuctionsQuery(
  baseOptions?: Apollo.QueryHookOptions<MarketAuctionsQuery, MarketAuctionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<MarketAuctionsQuery, MarketAuctionsQueryVariables>(
    MarketAuctionsDocument,
    options
  )
}
export function useMarketAuctionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MarketAuctionsQuery, MarketAuctionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<MarketAuctionsQuery, MarketAuctionsQueryVariables>(
    MarketAuctionsDocument,
    options
  )
}
export type MarketAuctionsQueryHookResult = ReturnType<typeof useMarketAuctionsQuery>
export type MarketAuctionsLazyQueryHookResult = ReturnType<typeof useMarketAuctionsLazyQuery>
export type MarketAuctionsQueryResult = Apollo.QueryResult<
  MarketAuctionsQuery,
  MarketAuctionsQueryVariables
>
export const FollowedAuctionsDocument = gql`
  query FollowedAuctions {
    followedAuctions {
      nodes {
        ...BaseAuction
      }
    }
  }
  ${BaseAuctionFragmentDoc}
`

/**
 * __useFollowedAuctionsQuery__
 *
 * To run a query within a React component, call `useFollowedAuctionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFollowedAuctionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFollowedAuctionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFollowedAuctionsQuery(
  baseOptions?: Apollo.QueryHookOptions<FollowedAuctionsQuery, FollowedAuctionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FollowedAuctionsQuery, FollowedAuctionsQueryVariables>(
    FollowedAuctionsDocument,
    options
  )
}
export function useFollowedAuctionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FollowedAuctionsQuery, FollowedAuctionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FollowedAuctionsQuery, FollowedAuctionsQueryVariables>(
    FollowedAuctionsDocument,
    options
  )
}
export type FollowedAuctionsQueryHookResult = ReturnType<typeof useFollowedAuctionsQuery>
export type FollowedAuctionsLazyQueryHookResult = ReturnType<typeof useFollowedAuctionsLazyQuery>
export type FollowedAuctionsQueryResult = Apollo.QueryResult<
  FollowedAuctionsQuery,
  FollowedAuctionsQueryVariables
>
export const ForestsDocument = gql`
  query Forests {
    currentUser {
      id
      cadastralPlots {
        nodes {
          ...OwnedCadastralPlot
        }
        sums {
          ...CadastralPlotSums
        }
      }
    }
  }
  ${OwnedCadastralPlotFragmentDoc}
  ${CadastralPlotSumsFragmentDoc}
`

/**
 * __useForestsQuery__
 *
 * To run a query within a React component, call `useForestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useForestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useForestsQuery({
 *   variables: {
 *   },
 * });
 */
export function useForestsQuery(
  baseOptions?: Apollo.QueryHookOptions<ForestsQuery, ForestsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ForestsQuery, ForestsQueryVariables>(ForestsDocument, options)
}
export function useForestsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ForestsQuery, ForestsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ForestsQuery, ForestsQueryVariables>(ForestsDocument, options)
}
export type ForestsQueryHookResult = ReturnType<typeof useForestsQuery>
export type ForestsLazyQueryHookResult = ReturnType<typeof useForestsLazyQuery>
export type ForestsQueryResult = Apollo.QueryResult<ForestsQuery, ForestsQueryVariables>
export const WonAuctionsDocument = gql`
  query WonAuctions {
    wonAuctions {
      nodes {
        ...BaseAuction
      }
    }
  }
  ${BaseAuctionFragmentDoc}
`

/**
 * __useWonAuctionsQuery__
 *
 * To run a query within a React component, call `useWonAuctionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWonAuctionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWonAuctionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useWonAuctionsQuery(
  baseOptions?: Apollo.QueryHookOptions<WonAuctionsQuery, WonAuctionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<WonAuctionsQuery, WonAuctionsQueryVariables>(WonAuctionsDocument, options)
}
export function useWonAuctionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<WonAuctionsQuery, WonAuctionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<WonAuctionsQuery, WonAuctionsQueryVariables>(
    WonAuctionsDocument,
    options
  )
}
export type WonAuctionsQueryHookResult = ReturnType<typeof useWonAuctionsQuery>
export type WonAuctionsLazyQueryHookResult = ReturnType<typeof useWonAuctionsLazyQuery>
export type WonAuctionsQueryResult = Apollo.QueryResult<WonAuctionsQuery, WonAuctionsQueryVariables>
export const ParticipatingAuctionsDocument = gql`
  query ParticipatingAuctions {
    participatingAuctions {
      nodes {
        ...ParticipatingAuction
      }
    }
  }
  ${ParticipatingAuctionFragmentDoc}
`

/**
 * __useParticipatingAuctionsQuery__
 *
 * To run a query within a React component, call `useParticipatingAuctionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useParticipatingAuctionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useParticipatingAuctionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useParticipatingAuctionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ParticipatingAuctionsQuery,
    ParticipatingAuctionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ParticipatingAuctionsQuery, ParticipatingAuctionsQueryVariables>(
    ParticipatingAuctionsDocument,
    options
  )
}
export function useParticipatingAuctionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ParticipatingAuctionsQuery,
    ParticipatingAuctionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ParticipatingAuctionsQuery, ParticipatingAuctionsQueryVariables>(
    ParticipatingAuctionsDocument,
    options
  )
}
export type ParticipatingAuctionsQueryHookResult = ReturnType<typeof useParticipatingAuctionsQuery>
export type ParticipatingAuctionsLazyQueryHookResult = ReturnType<
  typeof useParticipatingAuctionsLazyQuery
>
export type ParticipatingAuctionsQueryResult = Apollo.QueryResult<
  ParticipatingAuctionsQuery,
  ParticipatingAuctionsQueryVariables
>
export const OwnedAuctionsDocument = gql`
  query OwnedAuctions {
    ownedAuctions {
      nodes {
        ...OwnedAuction
      }
    }
  }
  ${OwnedAuctionFragmentDoc}
`

/**
 * __useOwnedAuctionsQuery__
 *
 * To run a query within a React component, call `useOwnedAuctionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOwnedAuctionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOwnedAuctionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useOwnedAuctionsQuery(
  baseOptions?: Apollo.QueryHookOptions<OwnedAuctionsQuery, OwnedAuctionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<OwnedAuctionsQuery, OwnedAuctionsQueryVariables>(
    OwnedAuctionsDocument,
    options
  )
}
export function useOwnedAuctionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OwnedAuctionsQuery, OwnedAuctionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<OwnedAuctionsQuery, OwnedAuctionsQueryVariables>(
    OwnedAuctionsDocument,
    options
  )
}
export type OwnedAuctionsQueryHookResult = ReturnType<typeof useOwnedAuctionsQuery>
export type OwnedAuctionsLazyQueryHookResult = ReturnType<typeof useOwnedAuctionsLazyQuery>
export type OwnedAuctionsQueryResult = Apollo.QueryResult<
  OwnedAuctionsQuery,
  OwnedAuctionsQueryVariables
>
export const ContactsDocument = gql`
  query Contacts {
    countryRepresentatives(visibleInContactsEq: true, order: { positionInContacts: asc }) {
      ...RepresentativeCountry
    }
  }
  ${RepresentativeCountryFragmentDoc}
`

/**
 * __useContactsQuery__
 *
 * To run a query within a React component, call `useContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactsQuery({
 *   variables: {
 *   },
 * });
 */
export function useContactsQuery(
  baseOptions?: Apollo.QueryHookOptions<ContactsQuery, ContactsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ContactsQuery, ContactsQueryVariables>(ContactsDocument, options)
}
export function useContactsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ContactsQuery, ContactsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ContactsQuery, ContactsQueryVariables>(ContactsDocument, options)
}
export type ContactsQueryHookResult = ReturnType<typeof useContactsQuery>
export type ContactsLazyQueryHookResult = ReturnType<typeof useContactsLazyQuery>
export type ContactsQueryResult = Apollo.QueryResult<ContactsQuery, ContactsQueryVariables>
export const EmployeesDocument = gql`
  query Employees {
    countryRepresentatives(visibleInAboutUsEq: true, order: { positionInAboutUs: asc }) {
      ...EmployeeCountry
    }
  }
  ${EmployeeCountryFragmentDoc}
`

/**
 * __useEmployeesQuery__
 *
 * To run a query within a React component, call `useEmployeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeesQuery({
 *   variables: {
 *   },
 * });
 */
export function useEmployeesQuery(
  baseOptions?: Apollo.QueryHookOptions<EmployeesQuery, EmployeesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<EmployeesQuery, EmployeesQueryVariables>(EmployeesDocument, options)
}
export function useEmployeesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<EmployeesQuery, EmployeesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<EmployeesQuery, EmployeesQueryVariables>(EmployeesDocument, options)
}
export type EmployeesQueryHookResult = ReturnType<typeof useEmployeesQuery>
export type EmployeesLazyQueryHookResult = ReturnType<typeof useEmployeesLazyQuery>
export type EmployeesQueryResult = Apollo.QueryResult<EmployeesQuery, EmployeesQueryVariables>
export const ForestGroupDocument = gql`
  query ForestGroup($id: ID!) {
    forestGroup(id: $id) {
      ...ForestGroup
    }
  }
  ${ForestGroupFragmentDoc}
`

/**
 * __useForestGroupQuery__
 *
 * To run a query within a React component, call `useForestGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useForestGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useForestGroupQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useForestGroupQuery(
  baseOptions: Apollo.QueryHookOptions<ForestGroupQuery, ForestGroupQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ForestGroupQuery, ForestGroupQueryVariables>(ForestGroupDocument, options)
}
export function useForestGroupLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ForestGroupQuery, ForestGroupQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ForestGroupQuery, ForestGroupQueryVariables>(
    ForestGroupDocument,
    options
  )
}
export type ForestGroupQueryHookResult = ReturnType<typeof useForestGroupQuery>
export type ForestGroupLazyQueryHookResult = ReturnType<typeof useForestGroupLazyQuery>
export type ForestGroupQueryResult = Apollo.QueryResult<ForestGroupQuery, ForestGroupQueryVariables>
export const ForestHabitatDocument = gql`
  query ForestHabitat($id: ID!) {
    forestHabitat(id: $id) {
      ...ForestHabitat
    }
  }
  ${ForestHabitatFragmentDoc}
`

/**
 * __useForestHabitatQuery__
 *
 * To run a query within a React component, call `useForestHabitatQuery` and pass it any options that fit your needs.
 * When your component renders, `useForestHabitatQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useForestHabitatQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useForestHabitatQuery(
  baseOptions: Apollo.QueryHookOptions<ForestHabitatQuery, ForestHabitatQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ForestHabitatQuery, ForestHabitatQueryVariables>(
    ForestHabitatDocument,
    options
  )
}
export function useForestHabitatLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ForestHabitatQuery, ForestHabitatQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ForestHabitatQuery, ForestHabitatQueryVariables>(
    ForestHabitatDocument,
    options
  )
}
export type ForestHabitatQueryHookResult = ReturnType<typeof useForestHabitatQuery>
export type ForestHabitatLazyQueryHookResult = ReturnType<typeof useForestHabitatLazyQuery>
export type ForestHabitatQueryResult = Apollo.QueryResult<
  ForestHabitatQuery,
  ForestHabitatQueryVariables
>
export const RoughPriceInquiryDocument = gql`
  query RoughPriceInquiry($id: ID!) {
    roughPriceInquiry(id: $id) {
      ...RoughPriceInquiry
    }
  }
  ${RoughPriceInquiryFragmentDoc}
`

/**
 * __useRoughPriceInquiryQuery__
 *
 * To run a query within a React component, call `useRoughPriceInquiryQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoughPriceInquiryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoughPriceInquiryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRoughPriceInquiryQuery(
  baseOptions: Apollo.QueryHookOptions<RoughPriceInquiryQuery, RoughPriceInquiryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<RoughPriceInquiryQuery, RoughPriceInquiryQueryVariables>(
    RoughPriceInquiryDocument,
    options
  )
}
export function useRoughPriceInquiryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<RoughPriceInquiryQuery, RoughPriceInquiryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<RoughPriceInquiryQuery, RoughPriceInquiryQueryVariables>(
    RoughPriceInquiryDocument,
    options
  )
}
export type RoughPriceInquiryQueryHookResult = ReturnType<typeof useRoughPriceInquiryQuery>
export type RoughPriceInquiryLazyQueryHookResult = ReturnType<typeof useRoughPriceInquiryLazyQuery>
export type RoughPriceInquiryQueryResult = Apollo.QueryResult<
  RoughPriceInquiryQuery,
  RoughPriceInquiryQueryVariables
>
export const PressReleasesDocument = gql`
  query PressReleases {
    contentPages(last: 3) {
      nodes {
        ...BaseContentPage
      }
    }
    pressMentions(last: 3) {
      nodes {
        ...BaseContentPage
      }
    }
  }
  ${BaseContentPageFragmentDoc}
`

/**
 * __usePressReleasesQuery__
 *
 * To run a query within a React component, call `usePressReleasesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePressReleasesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePressReleasesQuery({
 *   variables: {
 *   },
 * });
 */
export function usePressReleasesQuery(
  baseOptions?: Apollo.QueryHookOptions<PressReleasesQuery, PressReleasesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<PressReleasesQuery, PressReleasesQueryVariables>(
    PressReleasesDocument,
    options
  )
}
export function usePressReleasesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PressReleasesQuery, PressReleasesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<PressReleasesQuery, PressReleasesQueryVariables>(
    PressReleasesDocument,
    options
  )
}
export type PressReleasesQueryHookResult = ReturnType<typeof usePressReleasesQuery>
export type PressReleasesLazyQueryHookResult = ReturnType<typeof usePressReleasesLazyQuery>
export type PressReleasesQueryResult = Apollo.QueryResult<
  PressReleasesQuery,
  PressReleasesQueryVariables
>
export const SettingsDocument = gql`
  query Settings {
    currentUser {
      ...FullUser
    }
  }
  ${FullUserFragmentDoc}
`

/**
 * __useSettingsQuery__
 *
 * To run a query within a React component, call `useSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSettingsQuery(
  baseOptions?: Apollo.QueryHookOptions<SettingsQuery, SettingsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SettingsQuery, SettingsQueryVariables>(SettingsDocument, options)
}
export function useSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SettingsQuery, SettingsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SettingsQuery, SettingsQueryVariables>(SettingsDocument, options)
}
export type SettingsQueryHookResult = ReturnType<typeof useSettingsQuery>
export type SettingsLazyQueryHookResult = ReturnType<typeof useSettingsLazyQuery>
export type SettingsQueryResult = Apollo.QueryResult<SettingsQuery, SettingsQueryVariables>
export const NotificationSettingsDocument = gql`
  query NotificationSettings {
    currentUser {
      ...BaseUser
      notificationSettings {
        ...UserNotificationSettings
      }
    }
  }
  ${BaseUserFragmentDoc}
  ${UserNotificationSettingsFragmentDoc}
`

/**
 * __useNotificationSettingsQuery__
 *
 * To run a query within a React component, call `useNotificationSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useNotificationSettingsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    NotificationSettingsQuery,
    NotificationSettingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<NotificationSettingsQuery, NotificationSettingsQueryVariables>(
    NotificationSettingsDocument,
    options
  )
}
export function useNotificationSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    NotificationSettingsQuery,
    NotificationSettingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<NotificationSettingsQuery, NotificationSettingsQueryVariables>(
    NotificationSettingsDocument,
    options
  )
}
export type NotificationSettingsQueryHookResult = ReturnType<typeof useNotificationSettingsQuery>
export type NotificationSettingsLazyQueryHookResult = ReturnType<
  typeof useNotificationSettingsLazyQuery
>
export type NotificationSettingsQueryResult = Apollo.QueryResult<
  NotificationSettingsQuery,
  NotificationSettingsQueryVariables
>
export const CountriesDocument = gql`
  query Countries {
    countries {
      ...Country
    }
  }
  ${CountryFragmentDoc}
`

/**
 * __useCountriesQuery__
 *
 * To run a query within a React component, call `useCountriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCountriesQuery(
  baseOptions?: Apollo.QueryHookOptions<CountriesQuery, CountriesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CountriesQuery, CountriesQueryVariables>(CountriesDocument, options)
}
export function useCountriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CountriesQuery, CountriesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CountriesQuery, CountriesQueryVariables>(CountriesDocument, options)
}
export type CountriesQueryHookResult = ReturnType<typeof useCountriesQuery>
export type CountriesLazyQueryHookResult = ReturnType<typeof useCountriesLazyQuery>
export type CountriesQueryResult = Apollo.QueryResult<CountriesQuery, CountriesQueryVariables>
export const WalletDocument = gql`
  query Wallet {
    currentUser {
      id
      remainingForestMoney {
        ...Money
      }
    }
  }
  ${MoneyFragmentDoc}
`

/**
 * __useWalletQuery__
 *
 * To run a query within a React component, call `useWalletQuery` and pass it any options that fit your needs.
 * When your component renders, `useWalletQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWalletQuery({
 *   variables: {
 *   },
 * });
 */
export function useWalletQuery(
  baseOptions?: Apollo.QueryHookOptions<WalletQuery, WalletQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<WalletQuery, WalletQueryVariables>(WalletDocument, options)
}
export function useWalletLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<WalletQuery, WalletQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<WalletQuery, WalletQueryVariables>(WalletDocument, options)
}
export type WalletQueryHookResult = ReturnType<typeof useWalletQuery>
export type WalletLazyQueryHookResult = ReturnType<typeof useWalletLazyQuery>
export type WalletQueryResult = Apollo.QueryResult<WalletQuery, WalletQueryVariables>
export const PopupTaxonomicPlotDocument = gql`
  query PopupTaxonomicPlot($id: ID!, $cadastralPlotId: ID) {
    taxonomicPlot(id: $id) {
      ...PopupTaxonomicPlot
    }
  }
  ${PopupTaxonomicPlotFragmentDoc}
`

/**
 * __usePopupTaxonomicPlotQuery__
 *
 * To run a query within a React component, call `usePopupTaxonomicPlotQuery` and pass it any options that fit your needs.
 * When your component renders, `usePopupTaxonomicPlotQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePopupTaxonomicPlotQuery({
 *   variables: {
 *      id: // value for 'id'
 *      cadastralPlotId: // value for 'cadastralPlotId'
 *   },
 * });
 */
export function usePopupTaxonomicPlotQuery(
  baseOptions: Apollo.QueryHookOptions<PopupTaxonomicPlotQuery, PopupTaxonomicPlotQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<PopupTaxonomicPlotQuery, PopupTaxonomicPlotQueryVariables>(
    PopupTaxonomicPlotDocument,
    options
  )
}
export function usePopupTaxonomicPlotLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PopupTaxonomicPlotQuery,
    PopupTaxonomicPlotQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<PopupTaxonomicPlotQuery, PopupTaxonomicPlotQueryVariables>(
    PopupTaxonomicPlotDocument,
    options
  )
}
export type PopupTaxonomicPlotQueryHookResult = ReturnType<typeof usePopupTaxonomicPlotQuery>
export type PopupTaxonomicPlotLazyQueryHookResult = ReturnType<
  typeof usePopupTaxonomicPlotLazyQuery
>
export type PopupTaxonomicPlotQueryResult = Apollo.QueryResult<
  PopupTaxonomicPlotQuery,
  PopupTaxonomicPlotQueryVariables
>
export const ModalTaxonomicPlotDocument = gql`
  query ModalTaxonomicPlot($id: ID!, $cadastralPlotId: ID) {
    taxonomicPlot(id: $id) {
      ...ModalTaxonomicPlot
    }
  }
  ${ModalTaxonomicPlotFragmentDoc}
`

/**
 * __useModalTaxonomicPlotQuery__
 *
 * To run a query within a React component, call `useModalTaxonomicPlotQuery` and pass it any options that fit your needs.
 * When your component renders, `useModalTaxonomicPlotQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useModalTaxonomicPlotQuery({
 *   variables: {
 *      id: // value for 'id'
 *      cadastralPlotId: // value for 'cadastralPlotId'
 *   },
 * });
 */
export function useModalTaxonomicPlotQuery(
  baseOptions: Apollo.QueryHookOptions<ModalTaxonomicPlotQuery, ModalTaxonomicPlotQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ModalTaxonomicPlotQuery, ModalTaxonomicPlotQueryVariables>(
    ModalTaxonomicPlotDocument,
    options
  )
}
export function useModalTaxonomicPlotLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ModalTaxonomicPlotQuery,
    ModalTaxonomicPlotQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ModalTaxonomicPlotQuery, ModalTaxonomicPlotQueryVariables>(
    ModalTaxonomicPlotDocument,
    options
  )
}
export type ModalTaxonomicPlotQueryHookResult = ReturnType<typeof useModalTaxonomicPlotQuery>
export type ModalTaxonomicPlotLazyQueryHookResult = ReturnType<
  typeof useModalTaxonomicPlotLazyQuery
>
export type ModalTaxonomicPlotQueryResult = Apollo.QueryResult<
  ModalTaxonomicPlotQuery,
  ModalTaxonomicPlotQueryVariables
>
export const CurrentUserDocument = gql`
  query CurrentUser {
    currentUser {
      ...BaseUser
    }
  }
  ${BaseUserFragmentDoc}
`

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQuery(
  baseOptions?: Apollo.QueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options)
}
export function useCurrentUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CurrentUserQuery, CurrentUserQueryVariables>(
    CurrentUserDocument,
    options
  )
}
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>
export type CurrentUserLazyQueryHookResult = ReturnType<typeof useCurrentUserLazyQuery>
export type CurrentUserQueryResult = Apollo.QueryResult<CurrentUserQuery, CurrentUserQueryVariables>
export const CurrentPlatformUserDocument = gql`
  query CurrentPlatformUser {
    currentUser {
      ...PlatformUser
    }
  }
  ${PlatformUserFragmentDoc}
`

/**
 * __useCurrentPlatformUserQuery__
 *
 * To run a query within a React component, call `useCurrentPlatformUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentPlatformUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentPlatformUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentPlatformUserQuery(
  baseOptions?: Apollo.QueryHookOptions<CurrentPlatformUserQuery, CurrentPlatformUserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CurrentPlatformUserQuery, CurrentPlatformUserQueryVariables>(
    CurrentPlatformUserDocument,
    options
  )
}
export function useCurrentPlatformUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentPlatformUserQuery,
    CurrentPlatformUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CurrentPlatformUserQuery, CurrentPlatformUserQueryVariables>(
    CurrentPlatformUserDocument,
    options
  )
}
export type CurrentPlatformUserQueryHookResult = ReturnType<typeof useCurrentPlatformUserQuery>
export type CurrentPlatformUserLazyQueryHookResult = ReturnType<
  typeof useCurrentPlatformUserLazyQuery
>
export type CurrentPlatformUserQueryResult = Apollo.QueryResult<
  CurrentPlatformUserQuery,
  CurrentPlatformUserQueryVariables
>
export const BaseAuctionChangedDocument = gql`
  subscription BaseAuctionChanged($id: ID!) {
    auctionChanged(id: $id) {
      ...BaseAuction
    }
  }
  ${BaseAuctionFragmentDoc}
`

/**
 * __useBaseAuctionChangedSubscription__
 *
 * To run a query within a React component, call `useBaseAuctionChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useBaseAuctionChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBaseAuctionChangedSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBaseAuctionChangedSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    BaseAuctionChangedSubscription,
    BaseAuctionChangedSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    BaseAuctionChangedSubscription,
    BaseAuctionChangedSubscriptionVariables
  >(BaseAuctionChangedDocument, options)
}
export type BaseAuctionChangedSubscriptionHookResult = ReturnType<
  typeof useBaseAuctionChangedSubscription
>
export type BaseAuctionChangedSubscriptionResult =
  Apollo.SubscriptionResult<BaseAuctionChangedSubscription>
export const FullAuctionChangedDocument = gql`
  subscription FullAuctionChanged($id: ID!) {
    auctionChanged(id: $id) {
      ...FullAuction
    }
  }
  ${FullAuctionFragmentDoc}
`

/**
 * __useFullAuctionChangedSubscription__
 *
 * To run a query within a React component, call `useFullAuctionChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useFullAuctionChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFullAuctionChangedSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFullAuctionChangedSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    FullAuctionChangedSubscription,
    FullAuctionChangedSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    FullAuctionChangedSubscription,
    FullAuctionChangedSubscriptionVariables
  >(FullAuctionChangedDocument, options)
}
export type FullAuctionChangedSubscriptionHookResult = ReturnType<
  typeof useFullAuctionChangedSubscription
>
export type FullAuctionChangedSubscriptionResult =
  Apollo.SubscriptionResult<FullAuctionChangedSubscription>
export const OwnedAuctionChangedDocument = gql`
  subscription OwnedAuctionChanged($id: ID!) {
    auctionChanged(id: $id) {
      ...OwnedAuction
    }
  }
  ${OwnedAuctionFragmentDoc}
`

/**
 * __useOwnedAuctionChangedSubscription__
 *
 * To run a query within a React component, call `useOwnedAuctionChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOwnedAuctionChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOwnedAuctionChangedSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOwnedAuctionChangedSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    OwnedAuctionChangedSubscription,
    OwnedAuctionChangedSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    OwnedAuctionChangedSubscription,
    OwnedAuctionChangedSubscriptionVariables
  >(OwnedAuctionChangedDocument, options)
}
export type OwnedAuctionChangedSubscriptionHookResult = ReturnType<
  typeof useOwnedAuctionChangedSubscription
>
export type OwnedAuctionChangedSubscriptionResult =
  Apollo.SubscriptionResult<OwnedAuctionChangedSubscription>
export const ParticipatingAuctionChangedDocument = gql`
  subscription ParticipatingAuctionChanged($id: ID!) {
    auctionChanged(id: $id) {
      ...ParticipatingAuction
    }
  }
  ${ParticipatingAuctionFragmentDoc}
`

/**
 * __useParticipatingAuctionChangedSubscription__
 *
 * To run a query within a React component, call `useParticipatingAuctionChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useParticipatingAuctionChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useParticipatingAuctionChangedSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useParticipatingAuctionChangedSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    ParticipatingAuctionChangedSubscription,
    ParticipatingAuctionChangedSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    ParticipatingAuctionChangedSubscription,
    ParticipatingAuctionChangedSubscriptionVariables
  >(ParticipatingAuctionChangedDocument, options)
}
export type ParticipatingAuctionChangedSubscriptionHookResult = ReturnType<
  typeof useParticipatingAuctionChangedSubscription
>
export type ParticipatingAuctionChangedSubscriptionResult =
  Apollo.SubscriptionResult<ParticipatingAuctionChangedSubscription>
export const OwnedForestChangedDocument = gql`
  subscription OwnedForestChanged($id: ID!) {
    cadastralPlotChanged(id: $id) {
      ...OwnedCadastralPlot
    }
  }
  ${OwnedCadastralPlotFragmentDoc}
`

/**
 * __useOwnedForestChangedSubscription__
 *
 * To run a query within a React component, call `useOwnedForestChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOwnedForestChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOwnedForestChangedSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOwnedForestChangedSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    OwnedForestChangedSubscription,
    OwnedForestChangedSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    OwnedForestChangedSubscription,
    OwnedForestChangedSubscriptionVariables
  >(OwnedForestChangedDocument, options)
}
export type OwnedForestChangedSubscriptionHookResult = ReturnType<
  typeof useOwnedForestChangedSubscription
>
export type OwnedForestChangedSubscriptionResult =
  Apollo.SubscriptionResult<OwnedForestChangedSubscription>
export const FullForestChangedDocument = gql`
  subscription FullForestChanged($id: ID!) {
    cadastralPlotChanged(id: $id) {
      ...FullCadastralPlot
    }
  }
  ${FullCadastralPlotFragmentDoc}
`

/**
 * __useFullForestChangedSubscription__
 *
 * To run a query within a React component, call `useFullForestChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useFullForestChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFullForestChangedSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFullForestChangedSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    FullForestChangedSubscription,
    FullForestChangedSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    FullForestChangedSubscription,
    FullForestChangedSubscriptionVariables
  >(FullForestChangedDocument, options)
}
export type FullForestChangedSubscriptionHookResult = ReturnType<
  typeof useFullForestChangedSubscription
>
export type FullForestChangedSubscriptionResult =
  Apollo.SubscriptionResult<FullForestChangedSubscription>

export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[]
  }
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    Person: ['Company', 'Representative', 'User'],
    TaxonomicPlotInterface: ['FITaxonomicPlot', 'TaxonomicPlot'],
  },
}
export default result
