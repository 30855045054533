import { FC, ButtonHTMLAttributes, ComponentType, SVGProps, forwardRef } from 'react'
import tw from 'tailwind-styled-components'
import { SvgIcon } from './SvgIcon'

type Props = ButtonHTMLAttributes<HTMLButtonElement> & {
  title?: string
  outline?: boolean
  secondary?: boolean
  icon?: ComponentType<SVGProps<SVGElement>>
}

const BaseButton = tw.button`
  flex
  text-sm
  lg:text-base
  items-center
  justify-center
  px-3
  lg:px-4
  h-10
  lg:h-11
  rounded-md
  transition
  duration-300
  font-semibold
  disabled:cursor-not-allowed
`
const OutlineButton = tw(BaseButton)`
  bg-transparent
  border-2
  border-secondary-500
  text-secondary-500
  hover:border-secondary-600
  hover:text-secondary-600
  active:border-secondary-700
  active:text-secondary-700
  disabled:border-gray-400
  disabled:text-gray-400
`
const PrimaryButton = tw(BaseButton)`
  text-white
  bg-secondary-500
  hover:bg-secondary-600
  active:bg-secondary-700
  disabled:bg-gray-400
`

const SecondaryButton = tw(BaseButton)`
  bg-transparent
  border-2
  text-primary-500
  border-gray-400
  hover:border-primary-300
  active:border-primary-500
  disabled:border-gray-400
  disabled:text-gray-400
`

export const Button: FC<Props> = forwardRef(
  ({ title, icon, outline = false, secondary = false, ...props }, ref) => {
    const Component = secondary ? SecondaryButton : outline ? OutlineButton : PrimaryButton

    return (
      <Component {...props} ref={ref}>
        {icon ? (
          <SvgIcon src={icon} size={24} className='mr-2' inheritColors disableTransition />
        ) : null}
        {title}
      </Component>
    )
  }
)
