import { useMemo } from 'react'
import merge from 'deepmerge'
import isEqual from 'lodash/isEqual'
import {
  ApolloClient,
  InMemoryCache,
  NormalizedCacheObject,
  QueryOptions,
  from,
  split,
} from '@apollo/client'
import { getMainDefinition, relayStylePagination } from '@apollo/client/utilities'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { BatchHttpLink } from '@apollo/client/link/batch-http'
import { HttpLink } from '@apollo/client/link/http'
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries'
import ActionCableLink from 'graphql-ruby-client/subscriptions/ActionCableLink'
import { sha256 } from 'crypto-hash'
import { authTokenKey, api, domains } from '@foros-fe/core/config'
import { introspectionResult } from '@foros-fe/graphql'

const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__'
type StaticPageProps = {
  [APOLLO_STATE_PROP_NAME]?: object
}

let apolloClient: ApolloClient<NormalizedCacheObject>

const currentUserOperations = ['CurrentUser', 'CurrentPlatformUser']

const isServer = () => typeof window === 'undefined'

function getToken() {
  if (isServer()) return

  return sessionStorage.getItem(authTokenKey) || localStorage.getItem(authTokenKey)
}

function buildHeaders(headers?: Record<string, string>) {
  headers ??= {}
  headers.Domain = domains.current.name

  const token = getToken()
  if (token) {
    headers.Authorization = `Bearer ${token}`
  }

  return headers
}

const batchHttpLink = new BatchHttpLink({ uri: api.httpURL })
const httpLink = new HttpLink({ uri: api.httpURL })
const authLink = setContext((_, { headers }) => ({ headers: buildHeaders(headers) }))
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.error('[GraphQL error]', { message, locations, path })
    )

  if (networkError) console.error('[Network error]', networkError)
})

function getWebSocketLink() {
  if (isServer()) {
    return () => null
  }

  const { adapters, createConsumer } = require('@rails/actioncable')

  adapters.WebSocket = class WebSocketWithHeaders extends WebSocket {
    constructor(url: string, protocols: string[]) {
      super(url, [...protocols, encodeURIComponent(JSON.stringify(buildHeaders()))])
    }
  }

  return new ActionCableLink({ cable: createConsumer(api.webSocketURL) })
}

function getLink() {
  const composedHttpLink = from([
    errorLink,
    authLink,
    createPersistedQueryLink({ sha256 }),
    split(
      ({ query }) => {
        const definition = getMainDefinition(query)

        return (
          definition.kind === 'OperationDefinition' &&
          currentUserOperations.includes(definition.name?.value as string)
        )
      },
      httpLink,
      batchHttpLink
    ),
  ])

  return split(
    ({ query }) => {
      const definition = getMainDefinition(query)

      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
    },
    getWebSocketLink(),
    composedHttpLink
  )
}

function aliasedCursorPagination() {
  const pagination = relayStylePagination(
    (_args, context) => context.field?.alias?.value || context.fieldName
  )

  const read: typeof pagination['read'] = (existing, options) => {
    if (existing?.pageInfo) {
      const { startCursor, endCursor } = existing.pageInfo

      if (startCursor === null && endCursor === null) {
        options.cache.evict({
          fieldName: options.storeFieldName,
        })
      }
    }

    return pagination.read?.(existing, options)
  }

  return { ...pagination, read }
}

function createApolloClient(ssrMode: boolean) {
  const client = new ApolloClient({
    name: 'foros',
    ssrMode,
    link: getLink(),
    cache: new InMemoryCache({
      ...introspectionResult,
      typePolicies: {
        Query: {
          fields: {
            auctions: aliasedCursorPagination(),
          },
        },
      },
    }),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network',
        notifyOnNetworkStatusChange: true,
      },
    },
  })

  client.onResetStore(async () => client.setLink(getLink()))

  return client
}

function initializeApollo(initialState?: object) {
  const server = isServer()
  const _apolloClient = apolloClient ?? createApolloClient(server)

  if (initialState) {
    const existingCache = _apolloClient.extract()

    const data = merge(existingCache, initialState, {
      arrayMerge: (destination, source) => [
        ...source,
        ...destination.filter((d) => source.every((s) => !isEqual(d, s))),
      ],
    })

    _apolloClient.cache.restore(data)
  }

  if (server) return _apolloClient

  apolloClient ??= _apolloClient

  return _apolloClient
}

export async function fetchSSRQuery<Query, Variables>(
  queryOptions: QueryOptions<Variables, Query>
): Promise<StaticPageProps> {
  const client = initializeApollo()

  await client.query<Query, Variables>(queryOptions)

  return { [APOLLO_STATE_PROP_NAME]: client.cache.extract() }
}

export function useApollo(pageProps: StaticPageProps) {
  const state = pageProps[APOLLO_STATE_PROP_NAME]

  return useMemo(() => initializeApollo(state), [state])
}
