import { captureMessage } from '@sentry/nextjs'

if (typeof Node === 'function' && Node.prototype) {
  const originalRemoveChild = Node.prototype.removeChild
  Node.prototype.removeChild = function <Child extends Node>(child: Child): Child {
    if (child.parentNode !== this) {
      const message = 'Cannot remove a child from a different parent'
      const context = {
        child: child,
        originalParent: this,
        newParent: child.parentNode,
      }

      captureMessage(message, { extra: stringify(context) })
      console.warn(message, context)

      return child
    }
    return originalRemoveChild.call(this, child) as Child
  }

  const originalInsertBefore = Node.prototype.insertBefore
  Node.prototype.insertBefore = function <NewNode extends Node>(
    newNode: NewNode,
    referenceNode: Node | null
  ): NewNode {
    if (referenceNode && referenceNode.parentNode !== this) {
      const message = 'Cannot insert before a reference node from a different parent'
      const context = {
        newNode: newNode,
        referenceNode: referenceNode,
        originalParent: this,
        newParent: referenceNode.parentNode,
      }

      captureMessage(message, { extra: stringify(context) })
      console.warn(message, context)

      return newNode
    }
    return originalInsertBefore.call(this, newNode, referenceNode) as NewNode
  }
}

function stringify(context: Record<string, Node | null>) {
  return Object.fromEntries(
    Object.entries(context).map(([key, node]) => [
      key,
      JSON.stringify(node, ['tagName', 'className', 'textContent']),
    ])
  )
}
