import { FC, SVGProps, HTMLAttributes, ComponentType } from 'react'
import styled from 'styled-components'

type Props = Omit<SVGProps<SVGElement>, 'onClick'> &
  Pick<HTMLAttributes<HTMLDivElement>, 'onClick'> & {
    src: ComponentType<SVGProps<SVGElement>>
    inheritColors?: boolean
    useFill?: boolean
    disableTransition?: boolean
  } & (
    | {
        width: number
        height: number
        size?: never
      }
    | {
        size: number
        width?: never
        height?: never
      }
  )
type ContainerProps = HTMLAttributes<HTMLDivElement> & {
  $width: number
  $height: number
} & TransientProps<Props, 'inheritColors' | 'useFill' | 'disableTransition'>

const Container = styled.div<ContainerProps>`
  width: ${({ $width }) => $width}px;
  height: ${({ $height }) => $height}px;
  transition: ${({ $disableTransition }) => ($disableTransition ? 'none' : 'color 0.3s ease-in')};
  box-sizing: content-box;

  ${({ $inheritColors, $useFill }) =>
    $inheritColors
      ? `
        & path, circle {
          ${$useFill ? 'fill' : 'stroke'}: currentColor;
        }
      `
      : ''}
`

export const SvgIcon: FC<Props> = ({
  src: Icon,
  width,
  height,
  size,
  className = '',
  inheritColors,
  useFill,
  onClick,
  disableTransition = false,
  ...props
}) => (
  <Container
    $width={width ?? size!}
    $height={height ?? size!}
    $inheritColors={inheritColors}
    $useFill={useFill}
    $disableTransition={disableTransition}
    onClick={onClick}
    className={className}
  >
    <Icon {...props} />
  </Container>
)
