module.exports = {
  extend: {
    boxShadow: {
      hover: '0 5px 20px rgba(0, 0, 0, 0.3)',
    },
    dropShadow: {
      marker: '0 -2px 10px rgba(0, 0, 0, 0.5)',
    },
    fontSize: {
      '5xl': ['3rem', '1.625'],
      '6xl': ['3.75rem', '1.625'],
      '7xl': ['4.5rem', '1.25'],
      '8xl': ['6rem', '1.25'],
      '9xl': ['8rem', '1.25'],
    },
  },
  colors: {
    primary: {
      100: '#CCD9DC',
      200: '#99B3BA',
      300: '#678D97',
      400: '#346775',
      500: '#014152',
      600: '#013B4A',
      700: '#013442',
      800: '#012731',
      900: '#012129',
    },
    secondary: {
      100: '#F7F0CF',
      200: '#F0E19F',
      300: '#E8D170',
      400: '#E1C240',
      500: '#D9B310',
      600: '#AE8F0D',
      700: '#826B0A',
      800: '#574806',
      900: '#2B2403',
    },
    gray: {
      200: '#F5F5F5',
      300: '#EEEEEE',
      400: '#DDDDDD',
      500: '#CCCCCC',
      600: '#999999',
      700: '#555555',
      800: '#333333',
    },
    green: {
      100: '#D1ECE7',
      200: '#A4DACF',
      300: '#76C7B7',
      400: '#49B59F',
      500: '#1BA287',
      600: '#16826C',
      700: '#106151',
      800: '#0B4136',
      900: '#05201',
    },
    red: {
      100: '#F9CCCC',
      200: '#F29999',
      300: '#EC6666',
      400: '#E53333',
      500: '#DF0000',
      600: '#B20000',
      700: '#860000',
      800: '#590000',
      900: '#2D0000',
    },
    white: '#FFFFFF',
    black: '#000000',
    transparent: 'transparent',
    'white-orange': '#F8F6F3',
  },
  screens: {
    md: '768px',
    lg: '1152px',
    xl: '1440px',
  },
  container: {
    center: true,
    padding: {
      DEFAULT: '1rem',
      md: '1.5rem',
      lg: '4rem',
      xl: '5rem',
    },
  },
  fontFamily: {
    sans: ['Inter', 'sans-serif'],
    poppins: ['Poppins', 'sans-serif'],
  },
}
