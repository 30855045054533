// run yarn generateConfigDeclaration after editing any of the following files
export { default as theme } from './theme'
export { default as api } from './api'
export { default as domains } from './domains'

export const authTokenKey = 'foros.authToken'
export const leadUserKey = 'foros.leadUser'
export const auctionsFilterKey = 'foros.auctionsFilter'
export const createAuctionRequestKey = 'foros.createAuctionRequest'
export const emailKey = 'foros.email'
export const authStates = {
  signIn: 'sign-in',
  signUp: 'sign-up',
}
