import { FC, MouseEventHandler, useCallback } from 'react'
import CookieConsent from 'react-cookie-consent'
import { useTranslation } from 'next-i18next'
import tw from 'tailwind-styled-components'
import Link from 'next/link'
import { theme } from '../config'
import { Button } from './Button'


const Anchor = tw.a`hover:underline hover:text-green-100 cursor-pointer`

const containerStyle = {
  backgroundColor: theme.colors.green[500],
  zIndex: 2147484000,
  alignItems: 'flex-start',
}

export const Cookies = () => {
  const { t } = useTranslation('common')
  const renderButton = useCallback<FC<{ onClick: MouseEventHandler }>>(
    ({ onClick }) => (
      <Button title={t('agree')} value='cookie-consent' className='m-3' onClick={onClick} />
    ),
    [t]
  )

  const consent = (<CookieConsent style={containerStyle} location='bottom' ButtonComponent={renderButton}>
    {t('cookiesConsent') + ' '}
    <span>
      <Link href='/privacy-policy' passHref>
        <Anchor>{t('nav.privacyPolicy')}</Anchor>
      </Link>
    </span>
  </CookieConsent>)

  return consent
}
