import * as Sentry from '@sentry/nextjs'

Sentry.init({
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  ignoreErrors: [
    'Non-Error promise rejection captured with value: Object Not Found Matching Id',
    'Non-Error promise rejection captured with value: Timeout',
    'Non-Error promise rejection captured with keys: [object has no keys]',
    '_avast_submit',
    'instantSearchSDKJSBridgeClearHighlight',
    'cancelAnimationFrame',
    'webkit.messageHandlers',
  ],
  ignoreUrls: [/^file:\/\//, /intercomcdn/],
  tracesSampler: ({ transactionContext }) =>
    transactionContext.name.includes('health-check') ? 0 : 0.2,
})
