const host = new URL(process.env.NEXT_PUBLIC_API_ORIGIN || 'http://localhost:9292')
  .toString()
  .replace(/\/$/, '')

module.exports = {
  host,
  httpURL: `${host}/graphql`,
  webSocketURL: `${host.replace('http', 'ws')}/cable`,
  adminURL: host.includes('sandbox') ? host : 'https://admin.foros.com/',
}
